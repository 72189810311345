import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  CSSProperties,
  memo
} from "react";
import styled, { css } from "styled-components";
import { DropdownItem } from ".";
import {
  CompetitionSubType,
  CustomerSubType,
  FirestoreChangeType,
  KundeSubType,
  Location,
  Owner,
  SettlingSubType
} from "../../types";
import { useMedia } from "react-use";
import { v4 } from "uuid";
import { useMapsContext } from "../../context/MapsContext";
import AutocompleteWrapper from "../Shared/autocomplete";
import { Category } from "../../Views/Main";
import { db } from "../../firebaseConfig";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { Material, useMaterials } from "../../context/MaterialsContext";
import chroma from "chroma-js";
import firebase from "firebase";
import { useDomainContext } from "../../context/DomainContext";
import { useFirestoreLogger } from "../../context/FirestoreLogger";

type CustomerEditFormProps = {
  itemIdToEdit: string;
  locations?: Location[];
  setChangedItemLocation: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    } | null>
  >;
  changedItemFormattedAddress: string | null;
  setChangedItemFormattedAddress: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  kundeSubTypes: {
    main: string;
    sub: KundeSubType;
  }[];
  settlingSubTypes: {
    main: string;
    sub:
      | {
          title: string;
          color: string;
        }
      | string;
  }[];
  changedItemDesc: string | null;
  setChangedItemDesc: React.Dispatch<React.SetStateAction<string | null>>;
  changedItemSubType: CustomerSubType;
  setChangedItemSubType: (
    subType:
      | CustomerSubType
      | SettlingSubType
      | CompetitionSubType
      | KundeSubType
      | null
  ) => void;
  filteredEditWithoutCustomers: Owner[];
  deleteCustomer?: (id: string) => Promise<void>;
  cancelEdit: () => void;
  saveChanges: () => Promise<void>;
  style?: CSSProperties;
};

const Wrapper = styled.div<{ createNewKundeSubType?: boolean }>`
  ${props =>
    props.createNewKundeSubType &&
    css`
      filter: brightness(0.75);
      cursor: not-allowed;
      pointer-events: none;
    `};
  label {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
  div.form-group {
    margin-bottom: 10px;
  }
`;

const CustomerEditForm = ({
  itemIdToEdit,
  locations,
  changedItemFormattedAddress,
  changedItemDesc,
  kundeSubTypes,
  changedItemSubType: _changedItemSubtype,
  setChangedItemDesc,
  setChangedItemFormattedAddress,
  setChangedItemLocation,
  setChangedItemSubType,
  deleteCustomer,
  settlingSubTypes,
  cancelEdit,
  saveChanges,
  style
}: CustomerEditFormProps) => {
  const [materialIdToShowDropdown, setMaterialIdToShowDropdown] = useState("");

  const [formStyle, setFormStyle] = useState<CSSProperties>({});
  const isSmall = useMedia("(max-height: 1100px)");
  const [kundeDescription, setKundeDescription] = useState<string | null>(null);
  const [kundeFormattedAddress, setKundeFormattedAddress] = useState<
    string | null
  >(null);
  const [kundeLocation, setKundeLocation] = useState<Pick<
    Location,
    "location"
  > | null>(null);
  const [showCreateNewKunde, setShowCreateNewKunde] = useState<{
    field: string;
    description: string;
  } | null>(null);
  const { domain } = useDomainContext();

  const [showKundeTypeDropDown, setShowKundeTypeDropDown] = useState(false);
  const [
    createNewKundeSubType,
    setCreateNewKundeSubType
  ] = useState<KundeSubType | null>(null);

  const { materials } = useMaterials();
  const subType = _changedItemSubtype as CustomerSubType;
  const { logAction } = useFirestoreLogger();

  const handleSaveAndassignKunde = async () => {
    if (
      !createNewKundeSubType ||
      !kundeLocation ||
      !kundeFormattedAddress ||
      !kundeDescription ||
      !showCreateNewKunde ||
      !domain
    )
      return;
    const item: Omit<Location, "id"> = {
      description: kundeDescription,
      domain,
      formatted_address: kundeFormattedAddress,
      location: kundeLocation.location,
      type: { main: Category.KUNDE, sub: createNewKundeSubType },
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };
    const doc = await db.collection("locations").add(item);
    logAction(FirestoreChangeType.CREATE, doc.id, Category.KUNDE);
    setChangedItemSubType({ ...subType, [showCreateNewKunde.field]: doc });
    setShowCreateNewKunde(null);
    setKundeDescription(null);
    setKundeFormattedAddress(null);
    setKundeLocation(null);
    setCreateNewKundeSubType(null);
  };

  useEffect(() => {
    if (showCreateNewKunde) {
      setCreateNewKundeSubType({
        color: "",
        materials: [],
        title: "",
        tonnage: 0,
        __typename: Category.KUNDE
      });
      setKundeDescription(showCreateNewKunde.description);
      setKundeFormattedAddress(null);
      setKundeLocation(null);
    }
  }, [showCreateNewKunde]);
  // const [
  //   populatedFirstLevel,
  //   setPopulatedFirstLevel
  // ] = useState<Location | null>(null);
  // const [
  //   populatedSecondLevel,
  //   setPopulatedSecondLevel
  // ] = useState<Location | null>(null);
  // const [populatedKunde, setPopulatedKunde] = useState<Location | null>(null);
  const [allKunden, setAllKunden] = useState<Location[]>([]);

  useEffect(() => {
    if (showCreateNewKunde) return;
    const fetchKunden = async () => {
      const kunden = await db
        .collection("locations")
        .where("type.main", "==", Category.KUNDE)
        .get();
      setAllKunden(
        kunden.docs
          .map(doc => ({ ...doc.data(), id: doc.id } as Location))
          .filter(k => !k.diactivated)
      );
    };
    fetchKunden();
  }, [showCreateNewKunde]);

  const filteredKundeSubTypes = useMemo(
    () =>
      kundeSubTypes.filter(t => {
        if (
          createNewKundeSubType &&
          typeof t.sub === "object" &&
          "title" in createNewKundeSubType &&
          "title" in t.sub
        ) {
          return t.sub.title.includes(createNewKundeSubType.title);
        }
      }),
    [kundeSubTypes, createNewKundeSubType]
  );

  const grouppedSettlings = useMemo(() => {
    if (!locations) return [];
    return settlingSubTypes.map(subType => {
      const allLocationsOfSubtype = locations?.filter(
        loc =>
          !loc.diactivated &&
          loc.type.main === Category.SETTLING &&
          (loc.type.sub as SettlingSubType).title ===
            (subType.sub as SettlingSubType).title
      );
      return {
        title: (subType.sub as SettlingSubType).title,
        locations: allLocationsOfSubtype
      };
    }, []);
  }, [locations, settlingSubTypes]);

  useEffect(() => {
    if (isSmall) {
      setFormStyle({ height: 750 });
    } else setFormStyle({});
  }, [isSmall]);

  const handleDeleteCustomer = async () => {
    if (!deleteCustomer) return;
    if (!confirm("Bist du sicher, dass du diese Baustelle löschen möchtest"))
      return;
    await deleteCustomer(itemIdToEdit);
    cancelEdit();
  };

  const handleKundePlaceSelected = useCallback((place: any) => {
    const formattedAddress = place.formatted_address;
    const location = place.geometry.location;
    setKundeFormattedAddress(formattedAddress);
    setKundeLocation({
      location: { lat: location.lat(), lng: location.lng() }
    });
  }, []);

  const handlePlaceSelected = useCallback(
    (place: any) => {
      const formattedAddress = place.formatted_address;
      const location = place.geometry.location;
      setChangedItemFormattedAddress(formattedAddress);
      setChangedItemLocation({
        lat: location.lat() as number,
        lng: location.lng() as number
      });
    },
    [setChangedItemFormattedAddress, setChangedItemLocation]
  );

  if (changedItemFormattedAddress === null || changedItemDesc === null) {
    return null;
  }

  return (
    <>
      <Wrapper
        createNewKundeSubType={!!createNewKundeSubType}
        className="position-absolute bg-light shadow-lg px-3 py-4 d-flex flex-column"
        style={{
          width: 400,
          borderRadius: 12,
          maxHeight: 850,
          overflowY: "scroll",
          ...formStyle,
          ...style
        }}
      >
        <div className="form-group">
          <label htmlFor="address">Adresse</label>
          <div className="position-relative">
            <AutocompleteWrapper
              type="text"
              className="form-control w-100 position-relative"
              style={{ fontSize: ".9rem" }}
              aria-label="Search"
              onPlaceSelected={handlePlaceSelected}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="address">Beschreibung</label>
          <input
            className="form-control w-100"
            id="description"
            value={changedItemDesc}
            onChange={e => setChangedItemDesc(e.target.value)}
            placeholder="Beschreibung eingeben"
          />
        </div>
        <div className="w-100 d-flex">
          <div className="form-group w-50 mr-3">
            <label htmlFor="obj_number">Objektnummer Kifa</label>
            <input
              readOnly
              className="form-control"
              defaultValue={subType.object_number || 0}
              id="obj_number"
              placeholder="Objektnummer"
            />
          </div>
          <div className="form-group w-50">
            <label htmlFor="address">Datum Submission</label>
            <input
              className="form-control"
              type="date"
              onClick={e => {
                e.preventDefault();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                e.nativeEvent.target?.showPicker();
              }}
              value={subType.submiss_date}
              onChange={e =>
                setChangedItemSubType({
                  ...subType,
                  submiss_date: e.target.value
                })
              }
              id="description"
              placeholder="Beschreibung eingeben"
            />
          </div>
        </div>
        <h6 className="mb-0">
          <strong>Bauzeit</strong>
        </h6>
        <div className="w-100 d-flex justify-content-between">
          <div className="form-group" style={{ width: "48%" }}>
            <label htmlFor="obj_number">Von</label>
            <input
              className="form-control"
              type="date"
              onClick={e => {
                e.preventDefault();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                e.nativeEvent.target?.showPicker();
              }}
              value={subType.construction_time.from || undefined}
              onChange={e =>
                setChangedItemSubType({
                  ...subType,
                  construction_time: {
                    ...subType.construction_time,
                    from: e.target.value
                  }
                })
              }
              id="obj_number"
            />
          </div>
          <div className="form-group" style={{ width: "48%" }}>
            <label htmlFor="address">Bis</label>
            <input
              className="form-control"
              type="date"
              onClick={e => {
                e.preventDefault();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                e.nativeEvent.target?.showPicker();
              }}
              value={subType.construction_time.to || undefined}
              onChange={e =>
                setChangedItemSubType({
                  ...subType,
                  construction_time: {
                    ...subType.construction_time,
                    to: e.target.value
                  }
                })
              }
              id="obj_number"
            />
          </div>
        </div>
        <hr className="w-100" />
        <section>
          <h6>
            <strong>Angebot von</strong>
          </h6>
          <div className="form-group">
            <label htmlFor="settling">Niederlassung</label>
            <Select
              isMulti
              options={grouppedSettlings.map(val => ({
                label: val.title,
                options: val.locations.map(v => ({
                  label: v.description,
                  value: v
                }))
              }))}
              value={subType.settling?.map(v => ({
                label: v.description,
                value: v
              }))}
              onChange={(e: any) => {
                const data = e as {
                  label: string;
                  value: Location;
                }[];
                setChangedItemSubType({
                  ...subType,
                  settling: data.map(v => v.value)
                });
              }}
            />
            {/* <div className="w-100 position-relative">
              <input
                className="w-100 form-control"
                style={{ height: 35 }}
                placeholder="Kategorie"
                type="text"
                value={subType.settling?.description || "Keine"}
                readOnly
              />
              <FontAwesomeIcon
                icon={showCustomerSettlings ? faChevronUp : faChevronDown}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer"
                }}
                onClick={() => setShowCustomerSettlings(!showCustomerSettlings)}
              />
              {showCustomerSettlings && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: 100,
                    maxHeight: 180,
                    overflowY: "scroll",
                    left: 0,
                    right: 0
                  }}
                >
                  {[
                    { title: "Keine", locations: [] },
                    ...grouppedSettlings
                  ].map((v, index) => (
                    <>
                      <DropdownItem
                        key={index + v.title}
                        className="p-1 border-bottom border-right border-left bg-light"
                        style={{
                          cursor: v.title === "Keine" ? "pointer" : "default"
                        }}
                        onClick={
                          v.title === "Keine"
                            ? () => {
                                setChangedItemSubType({
                                  ...subType,
                                  settling: null
                                });
                                setShowCustomerSettlings(false);
                              }
                            : undefined
                        }
                      >
                        {v.title}
                      </DropdownItem>
                      {v.locations.map((loc, idx) => (
                        <DropdownItem
                          key={loc.id}
                          className="p-1 border-bottom border-right border-left bg-light"
                          style={{
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            setChangedItemSubType({
                              ...subType,
                              settling: loc
                            });
                            setShowCustomerSettlings(false);
                          }}
                        >
                          {loc.description}
                        </DropdownItem>
                      ))}
                    </>
                  ))}
                </div>
              )}
            </div> */}
          </div>
        </section>
        <section>
          <h6>
            <strong>Materialen</strong>
          </h6>
          {subType.materials.map((m, key) => {
            return (
              <Fragment key={key}>
                <div className="w-100 d-flex align-items-center position-relative">
                  <div className="form-group w-50 mr-3">
                    <label htmlFor="obj_number">Tonnage</label>
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      value={m.tonnage === 0 ? undefined : m.tonnage}
                      onChange={e =>
                        setChangedItemSubType({
                          ...subType,
                          materials: subType.materials.map(mat =>
                            mat.id === m.id
                              ? { ...mat, tonnage: +e.target.value }
                              : mat
                          )
                        })
                      }
                      id="obj_number"
                      placeholder="Null"
                    />
                  </div>
                  <div className="form-group w-50">
                    <label htmlFor="address">Material</label>
                    <div className="w-100 position-relative">
                      <input
                        className="w-100 form-control"
                        style={{ height: 35 }}
                        placeholder="Kategorie"
                        type="text"
                        value={
                          materials.find(v => v.id === m.category.id)?.name ||
                          ""
                        }
                        readOnly
                      />
                      <FontAwesomeIcon
                        icon={
                          materialIdToShowDropdown === m.id
                            ? faChevronUp
                            : faChevronDown
                        }
                        style={{
                          position: "absolute",
                          right: 10,
                          top: 10,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          setMaterialIdToShowDropdown(
                            m.id === materialIdToShowDropdown ? "" : m.id
                          )
                        }
                      />
                      {materialIdToShowDropdown === m.id && (
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            zIndex: 100,
                            maxHeight: 180,
                            overflowY: "scroll",
                            left: 0,
                            right: 0
                          }}
                        >
                          {materials
                            .map(v => v.name)
                            .map((v, index) => (
                              <DropdownItem
                                key={index}
                                className="p-1 bg-white border-bottom border-right border-left"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setChangedItemSubType({
                                    ...subType,
                                    materials: subType.materials.map(mat =>
                                      mat.id === m.id
                                        ? {
                                            ...mat,
                                            category: db
                                              .collection("materials")
                                              .doc(
                                                materials.find(
                                                  f => f.name === v
                                                )?.id
                                              )
                                          }
                                        : mat
                                    )
                                  });
                                  setMaterialIdToShowDropdown("");
                                }}
                              >
                                {v}
                              </DropdownItem>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {key !== 0 && (
                    <div
                      className="position-absolute"
                      style={{
                        transform: "rotate(45deg)",
                        fontSize: "1.4rem",
                        cursor: "pointer",
                        top: -5,
                        right: 0
                      }}
                      onClick={() =>
                        setChangedItemSubType({
                          ...subType,
                          materials: subType.materials.filter(
                            mat => mat.id !== m.id
                          )
                        })
                      }
                    >
                      +
                    </div>
                  )}
                </div>
              </Fragment>
            );
          })}

          <div>
            <button
              className="btn btn-outline-primary float-right"
              style={{
                width: 30,
                height: 30,
                fontSize: "1.2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              onClick={() =>
                setChangedItemSubType({
                  ...subType,
                  materials: [
                    ...subType.materials,
                    {
                      tonnage: 0,
                      category: db
                        .collection("materials")
                        .doc("WgkKJEicLNYYeWsEhyvl"),
                      id: v4()
                    }
                  ]
                })
              }
            >
              +
            </button>
          </div>
        </section>
        <div className="form-group">
          <label>Notizen</label>
          <textarea
            className="form-control"
            placeholder="Anfrage von: ..."
            rows={4}
            value={subType.notes}
            onChange={e =>
              setChangedItemSubType({
                ...subType,
                notes: e.target.value
              })
            }
            style={{ resize: "none" }}
          />
        </div>
        <hr className="w-100" />
        <div className="form-group w-100">
          <h6>
            <strong>Submission</strong>
          </h6>
          <div className="w-100 d-flex">
            <div className="form-group w-50 mr-3">
              <label htmlFor="winner_1">Gewinner Ausschreibung</label>
              {/* <input
              className="form-control"
              type="text"
              id="winner_1"
              value={populatedFirstLevel?.description}
              onChange={e =>
                setChangedItemSubType({
                  ...subType,
                  winner_first_level: e.target.value
                })
              }
              placeholder="Gewinner 1"
            /> */}
              <div className="w-100 position-relative mb-2">
                <CreatableSelect
                  value={(() => {
                    if (!subType.winner_first_level) return null;

                    const found = allKunden.find(
                      k => k.id === subType.winner_first_level?.id
                    );
                    if (found) {
                      return {
                        value: found,
                        label: found.description
                      };
                    } else {
                      return null;
                    }
                  })()}
                  onCreateOption={(input: string) => {
                    setShowCreateNewKunde({
                      field: "winner_first_level",
                      description: input
                    });
                  }}
                  onChange={e => {
                    if (!e) return;

                    setChangedItemSubType({
                      ...subType,
                      winner_first_level: db
                        .collection("locations")
                        .doc(e.value.id)
                    });
                  }}
                  options={allKunden.map(k => ({
                    value: k,
                    label: k.description
                  }))}
                />
              </div>
            </div>
            <div className="form-group w-50">
              <label htmlFor="address">Gewinner Mischwerk</label>
              <div className="w-100 position-relative mb-2">
                <CreatableSelect
                  value={(() => {
                    if (!subType.winner_second_level) return null;

                    const found = allKunden.find(
                      k => k.id === subType.winner_second_level?.id
                    );
                    if (found) {
                      return {
                        value: found,
                        label: found.description
                      };
                    } else {
                      return null;
                    }
                  })()}
                  onCreateOption={(input: string) => {
                    setShowCreateNewKunde({
                      field: "winner_second_level",
                      description: input
                    });
                  }}
                  onChange={e => {
                    if (!e) return;
                    setChangedItemSubType({
                      ...subType,
                      winner_second_level: db
                        .collection("locations")
                        .doc(e.value.id)
                    });
                  }}
                  options={allKunden.map(k => ({
                    value: k,
                    label: k.description
                  }))}
                />
              </div>
              {/* <div className="w-100 position-relative mb-2">
              <input
                className="w-100 form-control"
                style={{ height: 35 }}
                placeholder="Gewinner 2"
                type="text"
                value={winnerSelectFilter}
                onChange={e => {
                  setWinnerSelectFilter(e.target.value);
                  if (!e.target.value.length) {
                    setShowWinnerDropdown(false);
                  } else if (!showWinnerDropdown) {
                    setShowWinnerDropdown(true);
                  }
                }}
              />
              <FontAwesomeIcon
                icon={showWinnerDropdown ? faChevronUp : faChevronDown}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer"
                }}
                onClick={() => setShowWinnerDropdown(!showWinnerDropdown)}
              />
              {showWinnerSecondDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: 100,
                    maxHeight: 180,
                    overflowY: "scroll",
                    left: 0,
                    right: 0
                  }}
                >
                  {!!allKunden.length &&
                    allKunden.map((v, index) => (
                      <DropdownItem
                        key={index}
                        className="p-1 bg-white border-bottom border-right border-left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setChangedItemSubType({
                            ...subType,
                            winner_second_level: db
                              .collection("kunden")
                              .doc(v.id)
                          });
                          setShowWinnerDropdown(false);
                        }}
                      >
                        {v.description}
                      </DropdownItem>
                    ))}
                </div>
              )}
            </div> */}
            </div>
          </div>
          <div className="w-100 d-flex">
            <div className="form-group w-50 mr-3">
              <label htmlFor="winner_1">Kunde</label>
              {/* <input
              className="form-control"
              type="text"
              id="winner_1"
              value={populatedFirstLevel?.description}
              onChange={e =>
                setChangedItemSubType({
                  ...subType,
                  winner_first_level: e.target.value
                })
              }
              placeholder="Gewinner 1"
            /> */}
              <div className="w-100 position-relative mb-2">
                <CreatableSelect
                  value={(() => {
                    if (!subType.kunde) return null;
                    const found = allKunden.find(
                      k => k.id === subType.kunde?.id
                    );
                    if (found) {
                      return {
                        value: found,
                        label: found.description
                      };
                    } else {
                      return null;
                    }
                  })()}
                  onCreateOption={(input: string) => {
                    setShowCreateNewKunde({
                      field: "kunde",
                      description: input
                    });
                  }}
                  onChange={e => {
                    if (!e) return;
                    setChangedItemSubType({
                      ...subType,
                      kunde: db.collection("locations").doc(e.value.id)
                    });
                  }}
                  options={allKunden.map(k => ({
                    value: k,
                    label: k.description
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between align-items-center mt-1">
          {deleteCustomer && (
            <button
              className="btn btn-outline-danger"
              onClick={handleDeleteCustomer}
            >
              Löschen
            </button>
          )}
          <button className="btn btn-outline-secondary" onClick={cancelEdit}>
            Abbrechen
          </button>
          <button className="btn btn-success" onClick={saveChanges}>
            Speichern
          </button>
        </div>
      </Wrapper>
      {createNewKundeSubType && (
        <Wrapper
          className="position-absolute bg-light shadow-lg px-3 py-4 d-flex flex-column"
          style={{
            width: 400,
            borderRadius: 12,
            minHeight: 450,
            maxHeight: 850,
            overflowY: "scroll",
            ...formStyle,
            ...style
          }}
        >
          <h4>Kunde anlegen</h4>
          <div className="form-group">
            <label htmlFor="address">Adresse</label>
            <div className="position-relative">
              <AutocompleteWrapper
                type="text"
                className="form-control w-100 position-relative"
                style={{ fontSize: ".9rem" }}
                aria-label="Search"
                onPlaceSelected={handleKundePlaceSelected}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="address">Beschreibung</label>
            <input
              className="form-control w-100"
              id="description"
              value={kundeDescription || ""}
              onChange={e => setKundeDescription(e.target.value)}
              placeholder="Beschreibung eingeben"
            />
          </div>
          <div className="w-100 d-flex">
            <div className="form-group w-50 mr-3">
              <label htmlFor="obj_number">Tonnage</label>
              <input
                className="form-control"
                value={createNewKundeSubType?.tonnage || 0}
                onChange={e =>
                  !isNaN(+e.target.value) &&
                  setCreateNewKundeSubType({
                    ...createNewKundeSubType,
                    tonnage: +e.target.value
                  })
                }
                id="obj_number"
                placeholder="Objektnummer"
              />
            </div>
            <div className="form-group w-50">
              <label htmlFor="obj_number">Kategorie</label>
              <div className="w-100 position-relative">
                <input
                  className="w-100 form-control"
                  style={{ height: 35 }}
                  placeholder="Kategorie"
                  type="text"
                  value={createNewKundeSubType.title}
                  onChange={e => {
                    if (!showKundeTypeDropDown && e.target.value.trim()) {
                      setShowKundeTypeDropDown(true);
                    }
                    const exists = kundeSubTypes.find(
                      it => it.sub.title === e.target.value
                    );
                    if (!exists) {
                      setCreateNewKundeSubType({
                        ...createNewKundeSubType,
                        title: e.target.value,
                        color: ""
                      });
                    } else {
                      setCreateNewKundeSubType({
                        ...createNewKundeSubType,
                        title: exists.sub.title,
                        color: exists.sub.color
                      });
                    }
                  }}
                />
                <FontAwesomeIcon
                  icon={showKundeTypeDropDown ? faChevronUp : faChevronDown}
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    setShowKundeTypeDropDown(!showKundeTypeDropDown)
                  }
                />
                {showKundeTypeDropDown && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: 100,
                      maxHeight: 300,
                      overflowY: "scroll",
                      left: 0,
                      right: 0
                    }}
                  >
                    {filteredKundeSubTypes.map((it, index) =>
                      it.main === Category.KUNDE && it.sub ? (
                        <DropdownItem
                          key={index}
                          className="p-1 bg-white border-bottom border-right border-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const item = it.sub;
                            setCreateNewKundeSubType({
                              ...createNewKundeSubType,
                              title: item.title,
                              color: item.color
                            });

                            setImmediate(() => setShowKundeTypeDropDown(false));
                          }}
                        >
                          {typeof it.sub === "string" ? it.sub : it.sub.title}
                        </DropdownItem>
                      ) : null
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-1 w-75 position-relative">
            <input
              type="text"
              className="w-100 form-control"
              value={createNewKundeSubType.color}
              readOnly={true}
              placeholder="Farbe wählen"
              style={{ height: 35 }}
            />
            <input
              type="color"
              disabled={
                !!kundeSubTypes.find(c => {
                  return c.sub.title === createNewKundeSubType.title;
                })
              }
              className="position-absolute"
              placeholder="Farbe wählen"
              value={createNewKundeSubType.color}
              style={{
                height: 35,
                width: 35,
                right: 0,
                top: 0,
                cursor: "pointer"
              }}
              onChange={e =>
                setCreateNewKundeSubType({
                  ...createNewKundeSubType,
                  title: createNewKundeSubType.title,
                  color: e.target.value
                })
              }
            />
          </div>
          <div className="form-group w-100">
            <label htmlFor="address">Material</label>
            <Select
              isMulti
              name="materials"
              options={materials.map(val => ({
                value: val,
                label: val.name
              }))}
              defaultValue={createNewKundeSubType.materials
                .map(val => materials.find(it => it.id === val.id))
                .map(val => ({
                  value: val as Material,
                  label: val?.name
                }))}
              maxMenuHeight={100}
              styles={{
                menu: styles => ({ ...styles, zIndex: 100 }),
                multiValue: (styles, { data }) => ({
                  ...styles,
                  backgroundColor: chroma(data.value.color).alpha(0.1).css()
                }),
                multiValueLabel: (styles, { data }) => ({
                  ...styles,
                  color: data.value.color
                })
              }}
              onChange={val => {
                setCreateNewKundeSubType({
                  ...createNewKundeSubType,
                  materials: val.map(it =>
                    db.collection("materials").doc(it.value.id)
                  ) as firebase.firestore.DocumentReference<Material>[]
                } as KundeSubType);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="w-100 d-flex justify-content-between align-items-center mt-1">
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                setShowCreateNewKunde(null);
                setKundeDescription(null);
                setKundeFormattedAddress(null);
                setKundeLocation(null);
                setCreateNewKundeSubType(null);
              }}
            >
              Abbrechen
            </button>
            <button
              className="btn btn-success"
              onClick={handleSaveAndassignKunde}
            >
              Speichern
            </button>
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default memo(CustomerEditForm);
