export function drawCircle(
  point: { lat: number; lng: number },
  radius: number,
  dir: number,
  maps: any
) {
  const degreesToRadians = Math.PI / 180; // degrees to radians
  const radiansToDegrees = 180 / Math.PI; // radians to degrees
  const earthsradius = 6371; // 3963 is the radius of the earth in miles

  const points = 32;

  // find the raidus in lat/lon
  const rlat = (radius / earthsradius) * radiansToDegrees;
  const rlng = rlat / Math.cos(point.lat * degreesToRadians);

  let start, end;
  if (dir === 1) {
    start = 0;
    end = points + 1; // one extra here makes sure we connect the path
  } else {
    start = points + 1;
    end = 0;
  }

  let theta, ey, ex;
  const extp = [];
  for (let i = start; dir === 1 ? i < end : i > end; i = i + dir) {
    theta = Math.PI * (i / (points / 2));
    ey = point.lng + rlng * Math.cos(theta); // center a + radius x * cos(theta)
    ex = point.lat + rlat * Math.sin(theta); // center b + radius y * sin(theta)
    extp.push(new maps.LatLng(ex, ey));
  }
  return extp;
}
