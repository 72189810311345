import mixpanel, { Callback, Mixpanel } from "mixpanel-browser";
import { createContext, useContext } from "react";

type MixPanelContext = {
  mp: MixPanel;
};

export const MixPanelContext = createContext<MixPanelContext>({
  mp: {} as MixPanel
});

export const useMixPanel = () => useContext(MixPanelContext);

type User = {
  name: string;
};

type ApiEventProperties = {
  Project_Name: string;
  Customer?: string;
  User: string;
  Api_Type: ApiType;
  Date: Date;
  Month_Year: string;
  Data_Source: "api" | "cache";
};

type ActionEventProperties = {
  Project_Name: string;
  User: string;
  Action_Type: ActionType;
  Customer: string;
  Customer_Type: string;
  Date: Date;
  Month_Year: string;
};

type kemnaEvent = "API CALL" | "ACTION";
type ApiType =
  | "google-maps"
  | "google-places"
  | "google-geocode"
  | "google-distance_matrix"
  | "firebase-locations"
  | "firebase-distances";

type ActionType = "save" | "edit" | "delete";

export class MixPanel {
  private static instance: MixPanel;
  private isInit = false;
  private _mp = mixpanel;
  private constructor(token: string, user: User) {
    this._mp.init(token, {
      api_host: "https://api-eu.mixpanel.com"
    });
    this._mp.identify(user.name);
    this._mp.people.set({
      $user: user.name
    });
    this.isInit = true;
  }
  public static getInstance(token?: string, user?: User) {
    if (!MixPanel.instance) {
      if (!token || !user)
        throw new Error("token field is missing for initializing mixpanel");
      MixPanel.instance = new MixPanel(token, user);
    }
    return MixPanel.instance;
  }
  /**
   * @description custom track function with custom typechecks for event collection
   * @param event_name
   * @param properties
   * @param callback
   */
  public track(
    event_name: kemnaEvent,
    properties: ApiEventProperties | ActionEventProperties,
    callback?: Callback
  ) {
    if (!this.isInit) {
      console.warn("Calling Track Without Initializing MixPanel!");
      return;
    }
    this._mp.track(event_name, properties, callback);
  }
  /**
   * @description this method calls the normal track method defined for mixpanel
   *
   * @param params
   */
  public _track(...params: Parameters<Mixpanel["track"]>) {
    if (!this.isInit) {
      console.warn("Calling Track Without Initializing MixPanel!");
      return;
    }
    this._mp.track.apply(null, params);
  }
}
