import { Material } from "../context/MaterialsContext";
import { Category } from "../Views/Main";
import firebase from "firebase";

export enum SettingsView {
  PERMISSIONS = "permissions",
  MATERIALS = "materials"
}

export enum Domain {
  MISCHANLAGEN = "mischanlagen",
  ROHSTOFFBETRIEBE = "rohstoffbetriebe",
  BAUBETRIEBE = "baubetriebe"
}

export enum FirestoreChangeType {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete"
}

export enum ActionSource {
  MAIN = "main",
  KIFA = "kifa"
}

export type LogAction = {
  itemId: string;
  action: FirestoreChangeType;
  timestamp: string;
  itemType: Category;
  actionSource: ActionSource;
};

export type Location = {
  switched?: boolean;
  diactivated?: boolean;
  domain: string;
  location: {
    lat: number;
    lng: number;
  };
  formatted_address: string;
  description: string;
  type: {
    main: string;
    sub: CompetitionSubType | SettlingSubType | KundeSubType | CustomerSubType;
  };
  id: string;
  created_at?: string;
  updated_at?: string;
};

export type CompetitionSubType = {
  title: string;
  color: string;
  kifaId: string | null;
  materials: firebase.firestore.DocumentReference[];
  __typename: Category.COMPETITION;
};

export type SettlingSubType = {
  title: string;
  color: string;
  type1: string;
  type2: string;
  kifaId: string | null;
  materials: firebase.firestore.DocumentReference[];
  __typename: Category.SETTLING;
};

export type CustomerSubType = {
  materials: {
    tonnage: number;
    category: firebase.firestore.DocumentReference;
    id: string;
  }[];
  settling: Location[];
  winner_first_level: firebase.firestore.DocumentReference | null;
  winner_second_level: firebase.firestore.DocumentReference | null;
  kunde: firebase.firestore.DocumentReference | null;
  object_number?: number | null;
  nearest_neighbours: {
    description: string;
    type: Category.COMPETITION | Category.SETTLING;
    location: { lat: number; lng: number };
    distance: string;
  }[];
  construction_time: {
    from: string;
    to: string;
  };
  submiss_date?: string;
  notes?: string;
  __typename: Category.CUSTOMERS;
};

export type DbCustomerSubType = {
  materials: {
    tonnage: number;
    category: firebase.firestore.DocumentReference;
    id: string;
  }[];
  settling: firebase.firestore.DocumentReference<Location>[];
  winner_first_level: firebase.firestore.DocumentReference | null;
  winner_second_level: firebase.firestore.DocumentReference | null;
  kunde: firebase.firestore.DocumentReference | null;
  object_number?: number | null;
  nearest_neighbours: {
    description: string;
    type: Category.COMPETITION | Category.SETTLING;
    location: { lat: number; lng: number };
    distance: string;
  }[];
  construction_time: {
    from: string;
    to: string;
  };
  submiss_date?: string;
  notes?: string;
  __typename: Category.CUSTOMERS;
};

export type KundeSubType = {
  title: string;
  color: string;
  tonnage: number;
  materials: firebase.firestore.DocumentReference[];
  __typename: Category.KUNDE;
};

export type Owner = {
  location: {
    lat: number;
    lng: number;
  };
  formatted_address: string;
  description: string;
  type: "competition" | "settling";
  id: string;
};

export type Distance = {
  description: string;
  type: Category.SETTLING | Category.COMPETITION;
  location: { lat: number; lng: number };
  distance: string;
};

export type RowData = {
  description: string;
  customer_id?: string;
  formatted_address: string;
  location?: { lat: number; lng: number };
  winner_first_level?: string;
  winner_second_level?: string;
  object_number?: string;
  submiss_date?: string;
  construction_time?: string;
  dist_1?: Distance;
  dist_2?: Distance;
  dist_3?: Distance;
  dist_4?: Distance;
  dist_5?: Distance;
};

export type InfoGroup = {
  group_title: {
    formatted_address: string;
    title: string;
    location: {
      lat: number;
      lng: number;
    };
  };
  distances: {
    title: { formatted_address: string; title: string };
    location: {
      lat: number;
      lng: number;
    };
    shortDisatnce?: string;
    distance: string;
    color: string;
  }[];
  id: string;
};
