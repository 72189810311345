import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import {
  Action,
  Permission,
  permissionsNew,
  UserPermission
} from "../constants/permissions";
import { db } from "../firebaseConfig";
import {
  CompetitionSubType,
  KundeSubType,
  Location,
  SettlingSubType
} from "../types";
import { Category } from "../Views/Main";
import { UserContext } from "./user";

export type UserProfile = {
  id: string;
  data: {
    access: boolean;
    email: string;
    first_name?: string;
    last_name?: string;
    permissions: UserPermission[];
  };
};

type PermissionsContextType = {
  sitePermissions: UserProfile[];
  updateSitePermission: (id: string, value: boolean) => void;
  updateLocalPermissions: (id: string, newPermissions: any[]) => void;
  getUserPermission: (scope: string, name: string, action: Action) => boolean;
  getUserPermissionsByScope: (
    scope: string,
    action: Action
  ) => {
    name: string;
    read: boolean;
    write: boolean;
  }[];
};

const PermissionsContext = createContext(
  (null as unknown) as PermissionsContextType
);

export const usePermissions = () => useContext(PermissionsContext);

const PermissionsContextProvider: React.FC = ({ children }) => {
  const [sitePermissions, setSitePermissions] = useState<UserProfile[]>([]);

  const user = useContext(UserContext);

  const getUserPermission = useCallback(
    (scope: string, name: string, action: Action) => {
      if (!user || !sitePermissions.length) return false;
      const userPersmissions = sitePermissions.find(
        u => u.id === user.firebaseId
      );
      if (!userPersmissions) return false;
      const permission = userPersmissions.data.permissions
        .find(perm => perm.scope === scope)
        ?.permissions.find(p => p.name === name);
      if (!permission) return false;
      return permission[action];
    },
    [user, sitePermissions]
  );

  const getUserPermissionsByScope = useCallback(
    (scope: string, action: Action) => {
      if (!user || !sitePermissions.length) return [];
      const userPersmissions = sitePermissions.find(
        u => u.id === user.firebaseId
      );

      if (!userPersmissions) return [];
      const permissions = userPersmissions.data.permissions
        .find(perm => perm.scope === scope)
        ?.permissions.filter(perm => perm[action]);
      if (!permissions) return [];
      return permissions;
    },
    [user, sitePermissions]
  );

  // useEffect(() => {
  //   if (!user) return;
  //   db.collection("profile")
  //     .get()
  //     .then(async sp => {
  //       for (const doc of sp.docs) {
  //         const data = doc.data() as UserProfile["data"];
  //         await doc.ref.update({
  //           permissions: data.permissions.map(p =>
  //             p.scope === "Nutzer:innen / Niederlassungsgebiet"
  //               ? { ...p, permissions: [] }
  //               : p
  //           )
  //         });
  //       }
  //     });
  //   // console.log(
  //   //   getUserPermissionsByScope(
  //   //     "Nutzer:innen / Niederlassungsgebiet",
  //   //     Action.READ
  //   //   ).map(perm => perm.name)
  //   // );
  // }, [user]);

  const fetchSitePermissions = useCallback(() => {
    db.collection("profile")
      .get()
      .then(sp => {
        const data: UserProfile[] = [];
        sp.forEach(doc =>
          data.push({
            id: doc.id,
            data: doc.data() as UserProfile["data"]
          })
        );
        setSitePermissions(data);
      });
  }, []);

  useEffect(() => {
    if (!user) return;
    fetchSitePermissions();
  }, [fetchSitePermissions, user]);

  const updateSitePermission = useCallback((id: string, value: boolean) => {
    db.collection("profile")
      .doc(id)
      .update({ access: value })
      .then(() =>
        setSitePermissions(prev =>
          prev.map(perm =>
            perm.id === id
              ? { ...perm, data: { ...perm.data, access: value } }
              : perm
          )
        )
      );
  }, []);

  const updateLocalPermissions = useCallback(
    (id: string, permissions: UserPermission[]) => {
      db.collection("profile").doc(id).update({ permissions });

      setSitePermissions(prev =>
        prev.map(user =>
          user.id === id
            ? { ...user, data: { ...user.data, permissions } }
            : user
        )
      );
    },
    []
  );

  // useEffect(() => {
  //   db.collection("profile")
  //     .get()
  //     .then(sp => {
  //       sp.forEach(async doc => {
  //         const perms = doc.data().permissions;
  //         perms[0].permissions = [
  //           ...perms[0].permissions,
  //           { name: "Kunden", read: true, write: true }
  //         ];
  //         await doc.ref.update({ ...doc.data(), permissions: perms });
  //       });
  //     });
  // }, []);

  // useEffect(() => {
  //   db.collection("profile")
  //     .get()
  //     .then(sp => {
  //       sp.forEach(async doc => {
  //         const data = doc.data() as UserProfile["data"];
  //         await doc.ref.update({
  //           permissions: permissionsNew.map((permObj, idx1) => {
  //             if (permObj.scope === "Nutzer:innen / Niederlassungsgebiet") {
  //               return data.permissions.find(v => v.scope === permObj.scope);
  //             }
  //             return {
  //               scope: permObj.scope,
  //               permissions: permObj.permissions.map((perm, idx2) => {
  //                 console.log(
  //                   perm.name,
  //                   data,
  //                   data.permissions
  //                     .find(v => v.scope === permObj.scope)
  //                     ?.permissions.find(v => v.name === perm.name)?.read
  //                 );
  //                 return {
  //                   name: perm.name,
  //                   read: data.permissions
  //                     .find(v => v.scope === permObj.scope)
  //                     ?.permissions.find(v => v.name === perm.name)
  //                     ? data.permissions
  //                         .find(v => v.scope === permObj.scope)
  //                         ?.permissions.find(v => v.name === perm.name)?.read
  //                     : perm.actions.includes(Action.READ)
  //                     ? true
  //                     : false,
  //                   write: data.permissions
  //                     .find(v => v.scope === permObj.scope)
  //                     ?.permissions.find(v => v.name === perm.name)
  //                     ? data.permissions
  //                         .find(v => v.scope === permObj.scope)
  //                         ?.permissions.find(v => v.name === perm.name)?.write
  //                     : perm.actions.includes(Action.WRITE)
  //                     ? true
  //                     : false
  //                 };
  //               })
  //             };
  //           })
  //         });
  //       });
  //     });
  // }, []);

  // const deleteUser = async (id: string) => {
  //   if (window.confirm("Wollen Sie diesen Nutzer löschen?")) {
  //     await db.collection("profile").doc(id).delete();
  //   }
  //   if (user) {
  //     await fetchSitePermissions(user);
  //   }
  //   return;
  // };

  return (
    <PermissionsContext.Provider
      value={{
        sitePermissions,

        getUserPermissionsByScope,
        updateSitePermission,
        getUserPermission,
        updateLocalPermissions
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsContextProvider;
