export const generateCSV = (groups: any[][], initial: string[][]) => {
  const rows: string[][] = [...initial, ...groups];
  const csvContent =
    "data:text/csv;charset=utf-8," + rows.map(row => row.join(";")).join("\n");
  const uri = encodeURI(csvContent);
  return uri;
};

export const exportCSV = (
  groups: any[][],
  initial: string[][],
  fileName: string
) => {
  const uri = generateCSV(groups, initial);
  const link = document.createElement("a");
  link.setAttribute("href", uri);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);

  link.click();
};
