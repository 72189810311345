import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { InfoGroup, Location } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { exportCSV } from "../../util/generateCsv";
import { withoutUmlauts } from "../../util/textFormat";
import { Category, getDistanceMatrix } from "../../Views/Main";
import { useMapsContext } from "../../context/MapsContext";
import { useDomainContext } from "../../context/DomainContext";

type DistListProps = {
  customerRadius: number;
  settlingRadius: number;
  infoGroups: InfoGroup[];
  locations: Location[];
  handleSetCoordsToShowRoute: (
    newCoords: {
      origin: {
        lat: number;
        lng: number;
      };
      destination: {
        lat: number;
        lng: number;
      };
    } | null
  ) => void;
  coordsToShowRoute: {
    origin: {
      lat: number;
      lng: number;
    };
    destination: {
      lat: number;
      lng: number;
    };
  } | null;
  setShowDistList: React.Dispatch<React.SetStateAction<boolean>>;
};

const StyledDistList = styled.div`
  min-height: 45vh;
  max-height: 400px;
  overflow-y: scroll;
  min-width: 320px;
  & > * {
    white-space: nowrap;
  }
  user-select: none;
`;

const selectOptions = [
  { value: "10", label: "10" },
  { value: "25", label: "25" },
  { value: "50", label: "50" },
  { value: "all", label: "Alle" }
];

const DistList = ({
  customerRadius,
  infoGroups,
  handleSetCoordsToShowRoute,
  coordsToShowRoute,
  locations,
  setShowDistList
}: DistListProps) => {
  const [currRowWithRoute, setCurrRowWithRoute] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<
    "10" | "25" | "50" | "all"
  >("25");
  const { maps } = useMapsContext();
  const { domain } = useDomainContext();
  const [distancesLoading, setDistancesLoading] = useState(false);

  useEffect(() => {
    if (coordsToShowRoute === null) setCurrRowWithRoute(null);
  }, [coordsToShowRoute]);

  useEffect(
    () => () => {
      handleSetCoordsToShowRoute(null);
    },
    []
  );

  const getBatchDistances = useCallback(
    async (
      origin: { lat: number; lng: number; description: string; id: string }[],
      dest: { lat: number; lng: number; description: string; id: string }
    ) => {
      const distService = new maps.DistanceMatrixService();
      try {
        console.log("in getBatchDistances");
        const chunkSize = 25;
        const originChunks = [];
        for (let i = 0; i < origin.length; i += chunkSize) {
          originChunks.push(origin.slice(i, i + chunkSize));
        }
        console.log(originChunks);
        const results: any[] = [];

        for (const chunk of originChunks) {
          await new Promise(resolve => setTimeout(resolve, 200)); // Wait for 0.2s before continuing
          const result = await getDistanceMatrix(distService, {
            origins: chunk.map(c => ({ lat: c.lat, lng: c.lng })),
            destinations: [{ lat: dest.lat, lng: dest.lng }],
            travelMode: maps.TravelMode.DRIVING,
            unitSystem: maps.UnitSystem.METRIC
          });
          console.log("next chunk");
          results.push(result);
        }

        const formatted = results.reduce(
          (acc, curr, idx) => [
            ...acc,
            ...curr.rows.map(
              (row: any, idx2: number) => ({
                distance: (row.elements[0].distance.value / 1000).toFixed(1),
                origin_id: origin[idx * 25 + idx2].id
              }),
              []
            )
          ],
          []
        );

        return formatted.map((f: any) => ({
          distance: f.distance,
          origin: origin.find(o => o.id === f.origin_id),
          dest
        }));
        // return results.reduce((acc, cur) => acc + cur).toFixed(1);
      } catch (error) {
        console.error(error);
        return origin.map(o => ({ distance: "0", origin: o, dest }));
      }
    },
    [maps]
  );

  const handleExportCsv = async () => {
    setDistancesLoading(true);
    try {
      const group = infoGroups[0];
      let distances: {
        title: {
          formatted_address: string;
          title: string;
        };
        location: {
          lat: number;
          lng: number;
        };
        shortDisatnce?: string | undefined;
        distance: string;
      }[] = [];
      switch (selectedOption) {
        case "10":
        case "25":
          distances = group.distances.slice(0, parseInt(selectedOption) + 1);
          break;
        case "50": {
          const dest = locations.find(
            c => c.id === group.id && c.type.main === Category.CUSTOMERS
          );
          if (!dest) return;
          let relevantLocations = locations.filter(
            l =>
              l.type.main === Category.COMPETITION ||
              l.type.main === Category.SETTLING
          );

          relevantLocations.sort((a, b) => {
            return (
              Math.sqrt(
                Math.pow(a.location.lat - dest.location.lat, 2) +
                  Math.pow(a.location.lng - dest.location.lng, 2)
              ) -
              Math.sqrt(
                Math.pow(b.location.lat - dest.location.lat, 2) +
                  Math.pow(b.location.lng - dest.location.lng, 2)
              )
            );
          });
          relevantLocations = relevantLocations.slice(0, 50);

          const distancesResponse = await getBatchDistances(
            relevantLocations.map(l => ({
              lat: l.location.lat,
              lng: l.location.lng,
              description: l.description,
              id: l.id
            })),
            {
              lat: dest.location.lat,
              lng: dest.location.lng,
              description: dest.description,
              id: dest.id
            }
          );

          distances = relevantLocations
            .filter(
              l => l.type.main !== Category.CUSTOMERS && l.domain === domain
            )
            .map(l => ({
              title: {
                title: l.description,
                formatted_address: [
                  l.formatted_address.split(",")[0],
                  l.formatted_address.split(",")[1]
                ].join()
              },
              location: l.location,
              distance:
                distancesResponse.find((d: any) => d.origin.id === l.id)
                  ?.distance || "N/A"
            }));
          break;
        }
        case "all":
          const relevantLocations = locations.filter(
            l =>
              l.type.main === Category.COMPETITION ||
              l.type.main === Category.SETTLING
          );

          const dest = locations.find(
            c => c.id === group.id && c.type.main === Category.CUSTOMERS
          );
          if (!dest) return;
          const distancesResponse = await getBatchDistances(
            relevantLocations.map(l => ({
              lat: l.location.lat,
              lng: l.location.lng,
              description: l.description,
              id: l.id
            })),
            {
              lat: dest.location.lat,
              lng: dest.location.lng,
              description: dest.description,
              id: dest.id
            }
          );
          distances = relevantLocations
            .filter(
              l => l.type.main !== Category.CUSTOMERS && l.domain === domain
            )
            .map(l => ({
              title: {
                title: l.description,
                formatted_address: [
                  l.formatted_address.split(",")[0],
                  l.formatted_address.split(",")[1]
                ].join()
              },
              location: l.location,
              distance: distancesResponse.find((d: any) => d.origin.id === l.id)
                .distance
            }));
          break;
      }
      distances.sort((a, b) => +a.distance - +b.distance);
      exportCSV(
        distances.map(d => [
          withoutUmlauts(d.title.title),
          withoutUmlauts(d.title.formatted_address.replaceAll(",", " ")),
          withoutUmlauts(
            d.shortDisatnce
              ? d.shortDisatnce === d.distance
                ? d.shortDisatnce
                : d.distance
              : d.distance
          )
        ]),
        [
          [
            withoutUmlauts(group.group_title.title),
            withoutUmlauts(
              group.group_title.formatted_address.split(",").join(" ")
            )
          ],
          ["Beschreibung", "Adresse", "Enfernung"]
        ],
        group.group_title.title.toUpperCase().split(" ").join("_") +
          "_Distances.csv"
      );
    } catch (e) {
      console.log(e);
    } finally {
      setDistancesLoading(false);
    }
  };

  const filteredGroups = infoGroups.map(g => ({
    ...g,
    distances: [
      ...g.distances
        .sort((a: any, b: any) => a.distance - b.distance)
        .slice(0, 10),
      ...g.distances
        .sort((a: any, b: any) => a.distance - b.distance)
        .slice(10)
        .filter(l => +l.distance <= customerRadius)
    ]
  }));

  return !!filteredGroups.length ? (
    <StyledDistList className="bg-white p-1 rounded-lg shadow">
      <div
        className="position-absolute d-flex bg-white shadow-sm"
        style={{
          top: 0,
          padding: 10,
          left: 0,
          right: 0,
          zIndex: 1000,
          gap: 10
        }}
      >
        <button
          className="d-flex align-items-center justify-content-center btn btn-outline-secondary"
          style={{ height: 25, width: 25, cursor: "pointer" }}
          disabled={distancesLoading}
          onClick={() => {
            if (selectedOption === "all") {
              const confirm = window.confirm(
                "Bitte überlegen Sie genau ob Sie alle Ergebnisse brauchen. Diese Abfrage kann das Budget und die Resourcen belasten!"
              );
              if (!confirm) return;
            }
            handleExportCsv();
          }}
        >
          <FontAwesomeIcon icon={faDownload} />
        </button>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 25, cursor: "pointer" }}
        >
          <select
            className="form-select rounded"
            aria-label="Size select"
            onChange={e => setSelectedOption(e.target.value as "25" | "all")}
          >
            {selectOptions.map((o, key) => (
              <option
                key={key}
                value={o.value}
                selected={selectedOption === o.value}
              >
                {o.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <button
        className="position-absolute d-flex align-items-center justify-content-center"
        style={{
          top: 10,
          zIndex: 1000,
          right: 10,
          outline: "none",
          cursor: "pointer",
          backgroundColor: "transparent",
          border: "none"
        }}
        onClick={() => {
          setShowDistList(false);
          handleSetCoordsToShowRoute(null);
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>

      {!!filteredGroups.length &&
        filteredGroups.map((g: any) => (
          <div key={g.id} className="p-2 pt-5">
            <div className="pl-1">
              <span>
                <strong>{g.group_title.title}</strong>
              </span>
              <br />
              <span>{g.group_title.formatted_address}</span>
            </div>
            <div className="mt-2">
              <table className="table-sm table-hover w-100 p-0">
                <thead>
                  <tr>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Beschreibung
                    </th>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Entfernung
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {g.distances.map((d: any) => (
                    <tr
                      key={d.title.title}
                      className={
                        currRowWithRoute === d.title.title
                          ? "table-primary"
                          : ""
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (currRowWithRoute !== d.title.title) {
                          setCurrRowWithRoute(d.title.title);
                          handleSetCoordsToShowRoute({
                            origin: d.location,
                            destination: g.group_title.location
                          });
                        } else {
                          setCurrRowWithRoute(null);
                          handleSetCoordsToShowRoute(null);
                        }
                      }}
                    >
                      <td
                        title={d.title.formatted_address}
                        style={{ color: d.color }}
                      >
                        {`${d.title.title} `}
                      </td>
                      <td>
                        {`${d.distance} km ${
                          d.shortDistance && d.shortDistance !== d.distance
                            ? ` (${d.shortDistance})`
                            : ""
                        }`}
                      </td>
                    </tr>
                  ))}
                  {!g.distances.length && (
                    <tr style={{ textAlign: "center", fontSize: "0.8rem" }}>
                      <td colSpan={2}>Keine Entfernungen</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ))}
    </StyledDistList>
  ) : null;
};

export default DistList;
