/* eslint-disable react/display-name */
import React, {
  useEffect,
  useMemo,
  useState,
  CSSProperties,
  useRef
} from "react";
import MaterialTable, { Column, MTableToolbar } from "@material-table/core";
import firebase from "firebase";

import parse from "date-fns/parse";

import {
  CompetitionSubType,
  CustomerSubType,
  KundeSubType,
  Location,
  RowData,
  SettlingSubType
} from "../../types";
import { Category, TableSize } from "../../Views/Main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faDownload,
  faList,
  faPen,
  faPlus,
  faRedo,
  faSearch
} from "@fortawesome/pro-solid-svg-icons";
import { exportCSV } from "../../util/generateCsv";
import { withoutUmlauts } from "../../util/textFormat";
import Select, { MultiValue } from "react-select";
import { usePermissions } from "../../context/PermissionsContext";
import { Action } from "../../constants/permissions";
import { isValid } from "date-fns";
import { PopulatedCustomerLocation } from "../MapArea";
import { useDomainContext } from "../../context/DomainContext";
import { db } from "../../firebaseConfig";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { Material, useMaterials } from "../../context/MaterialsContext";

const checkIfRouteActive = (
  coordsToShowRoute: {
    origin: { lat: number; lng: number };
    destination: { lat: number; lng: number };
  },
  currCoords: {
    origin: { lat: number; lng: number };
    destination: { lat: number; lng: number };
  }
) => {
  return (
    coordsToShowRoute.origin.lat === currCoords.origin.lat &&
    coordsToShowRoute.origin.lng === currCoords.origin.lng &&
    coordsToShowRoute.destination.lat === currCoords.destination.lat &&
    coordsToShowRoute.destination.lng === currCoords.destination.lng
  );
};

const CustomersTable = ({
  locations,
  handleStartCustomerCreation,
  handleSetCoordsToShowRoute,
  coordsToShowRoute,
  handleRowClick,
  selectedCustomerId,
  itemIdToEdit,
  setItemIdToEdit,
  setCustomersForMap,
  showDistList,
  settlingSubTypes,
  setShowDistList,
  tableView,
  searchText,
  setSearchText
}: {
  locations: Location[];
  onChange: (
    itemId: string,
    newDesc: string,
    newType: {
      main: string;
      sub:
        | CompetitionSubType
        | SettlingSubType
        | CustomerSubType
        | KundeSubType;
    },
    newLocation?: Location,
    newFormattedAddress?: string
  ) => Promise<void>;
  handleStartCustomerCreation: () => void;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  handleSetCoordsToShowRoute: (
    newCoords: {
      origin: {
        lat: number;
        lng: number;
      };
      destination: {
        lat: number;
        lng: number;
      };
    } | null
  ) => void;
  settlingSubTypes: {
    main: string;
    sub:
      | {
          title: string;
          color: string;
        }
      | string;
  }[];
  coordsToShowRoute: {
    origin: {
      lat: number;
      lng: number;
    };
    destination: {
      lat: number;
      lng: number;
    };
  } | null;
  handleRowClick: (rowData: RowData) => void;
  setCustomersForMap: (value: Location[] | null) => void;
  selectedCustomerId: string;
  itemIdToEdit: string | null;
  setItemIdToEdit: React.Dispatch<React.SetStateAction<string | null>>;
  showDistList: boolean;
  setShowDistList: React.Dispatch<React.SetStateAction<boolean>>;
  tableView: TableSize;
}) => {
  const [populatedCustomers, setPopulatedCustomers] = useState<
    PopulatedCustomerLocation[]
  >([]);
  const [filterSettlingsCategories, setFilterSettlingsCategories] = useState<
    MultiValue<{
      label: string;
      value: string;
    }>
  >([]);
  const [filterSettlings, setFilterSettlings] = useState<
    MultiValue<{
      label: string;
      value: string;
    }>
  >([]);
  const [filterMaterial, setFilterMaterial] = useState<
    MultiValue<{
      label: string;
      value: Material;
    }>
  >([]);
  const [filterSubWinner, setFilterSubWinner] = useState<
    MultiValue<{
      label: string;
      value: string;
    }>
  >([]);
  const [filterKunde, setFilterKunde] = useState<
    MultiValue<{
      label: string;
      value: string;
    }>
  >([]);
  const [filterSupplier, setFilterSupplier] = useState<
    MultiValue<{
      label: string;
      value: string;
    }>
  >([]);
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();
  const [searchTextChanged, setSearchTextChanged] = useState(false);
  const [allKunden, setAllKunden] = useState<Location[]>([]);
  const [allSubWinner, setAllSubWinner] = useState<Location[]>([]);
  const [allSupplier, setAllSupplier] = useState<Location[]>([]);
  const [openFilter, setOpenFilter] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { materials } = useMaterials();

  useEffect(() => {
    if (!inputRef.current) return;

    const cb = (e: any) => {
      console.log("input", e.target.value);
      setSearchText(e.target.value);
      setSearchTextChanged(true);
    };
    inputRef.current.addEventListener("input", cb);
    return () => {
      if (inputRef.current) inputRef.current.removeEventListener("input", cb);
    };
  }, [inputRef]);

  const customersLocations = useMemo(
    () => locations.filter(loc => loc.type.main === Category.CUSTOMERS),
    [locations]
  );

  useEffect(() => {
    const fetchKunden = async () => {
      const allKundenAssigned = customersLocations
        .flatMap(l => (l.type.sub as CustomerSubType).kunde)
        .filter(Boolean) as firebase.firestore.DocumentReference<
        firebase.firestore.DocumentData
      >[];
      const uniqueKunden = Array.from(
        new Set(allKundenAssigned.map(k => k.path))
      ).map(k => firebase.firestore().doc(k));

      const allSubWinnerAssigned = customersLocations
        .flatMap(l => (l.type.sub as CustomerSubType).winner_first_level)
        .filter(Boolean) as firebase.firestore.DocumentReference<
        firebase.firestore.DocumentData
      >[];
      const uniqueSubWinner = Array.from(
        new Set(allSubWinnerAssigned.map(k => k.path))
      ).map(k => firebase.firestore().doc(k));

      const allSupplierAssigned = customersLocations
        .flatMap(l => (l.type.sub as CustomerSubType).winner_second_level)
        .filter(Boolean) as firebase.firestore.DocumentReference<
        firebase.firestore.DocumentData
      >[];

      const uniqueSupplier = Array.from(
        new Set(allSupplierAssigned.map(k => k.path))
      ).map(k => firebase.firestore().doc(k));
      const kundenFetched = await Promise.all(
        uniqueKunden.map(async kunde => {
          const kundeDoc = await kunde.get();
          return { ...kundeDoc.data(), id: kundeDoc.id } as Location;
        })
      );
      const subWinnerFetched = await Promise.all(
        uniqueSubWinner.map(async subWinner => {
          const subWinnerDoc = await subWinner.get();
          return { ...subWinnerDoc.data(), id: subWinnerDoc.id } as Location;
        })
      );
      const supplierFetched = await Promise.all(
        uniqueSupplier.map(async supplier => {
          const supplierDoc = await supplier.get();
          return { ...supplierDoc.data(), id: supplierDoc.id } as Location;
        })
      );
      setAllSubWinner(subWinnerFetched);
      setAllSupplier(supplierFetched);
      setAllKunden(kundenFetched);
    };
    fetchKunden();
  }, [customersLocations]);

  useEffect(() => {
    const populateCustomers = async () => {
      const populatedCustomers = await Promise.all(
        customersLocations.map(async customer => {
          const sub = customer.type.sub as CustomerSubType;
          const winnerSecondLevel = sub.winner_second_level
            ? await sub.winner_second_level
                .get()
                .then((doc: any) => doc.data() as Location)
            : null;
          const winnerFirstLevel = sub.winner_first_level
            ? await sub.winner_first_level
                .get()
                .then((doc: any) => doc.data() as Location)
            : null;
          const kunde = sub.kunde
            ? await sub.kunde.get().then((doc: any) => doc.data() as Location)
            : null;
          return {
            ...customer,
            type: {
              main: Category.CUSTOMERS,
              sub: {
                ...customer.type.sub,
                winner_second_level: winnerSecondLevel,
                winner_first_level: winnerFirstLevel,
                kunde: kunde
              }
            }
          } as PopulatedCustomerLocation;
        })
      );
      setPopulatedCustomers(populatedCustomers);
    };
    populateCustomers();
  }, [customersLocations]);

  const selectSettlingCategories = useMemo(() => {
    return [
      { label: "Keine Niederlassungskategorie", value: null },
      ...settlingSubTypes.map(sub => {
        return { value: (sub.sub as any).title, label: (sub.sub as any).title };
      })
    ];
  }, [settlingSubTypes]);
  const selectSettlings = useMemo(() => {
    return [
      { label: "Keine Niederlassung", value: null },
      ...(locations
        .filter(loc => {
          const isSettling = loc.type.main === Category.SETTLING;
          if (!filterSettlingsCategories.length) return isSettling;
          return isSettling
            ? filterSettlingsCategories
                .map(val => val.value)
                .includes((loc.type.sub as SettlingSubType).title) &&
                !loc.diactivated
            : false;
        })
        .map(location => ({
          label: location.description,
          value: location.id
        })) as { label: string; value: any }[])
    ];
  }, [locations, filterSettlingsCategories]);

  const selectMaterial = useMemo(() => {
    return materials.map(val => ({
      label: val.name,
      value: val
    })) as { label: string; value: Material }[];
  }, [materials]);

  const selectKunden = useMemo(() => {
    return [
      ...allKunden
        .filter(l => !l.diactivated)
        .map(location => ({
          label: location.description,
          value: location.description
        }))
    ] as { label: string; value: any }[];
  }, [allKunden]);

  const selectSubWinner = useMemo(() => {
    return [
      ...allSubWinner
        .filter(l => !l.diactivated)
        .map(location => ({
          label: location.description,
          value: location.description
        }))
    ] as { label: string; value: any }[];
  }, [allSubWinner]);

  const selectSupplier = useMemo(() => {
    return [
      ...allSupplier
        .filter(l => !l.diactivated)
        .map(location => ({
          label: location.description,
          value: location.description
        }))
    ] as { label: string; value: any }[];
  }, [allSupplier]);
  const { getUserPermission } = usePermissions();
  const allowCreateCustomer = getUserPermission(
    "Nutzer:innen / Module",
    "Baustellen",
    Action.WRITE
  );
  const allowEditCustomer = getUserPermission(
    "Nutzer:innen / Module",
    "Baustellen",
    Action.WRITE
  );

  const columns = useMemo(() => {
    const baseColumns: Column<any>[] = [
      {
        title: "Beschreibung",
        field: "description",
        customFilterAndSearch: (term, rowData) => {
          if (Object.keys(rowData).length === 2) {
            return true;
          } else
            return rowData.description
              .toLowerCase()
              .includes(term.toLowerCase());
        }
      },
      {
        title: "Adresse",
        field: "formatted_address"
      }
      // {
      //   title: "",
      //   sorting: false,
      //   render: data =>
      //     Object.keys(data).length !== 2 && allowEditCustomer ? (
      //       <EditIcon
      //         rowData={data as RowData}
      //         showDistList={showDistList}
      //         setShowDistList={setShowDistList}
      //         itemIdToEdit={itemIdToEdit}
      //         setItemIdToEdit={setItemIdToEdit}
      //       />
      //     ) : null
      // },
      // {
      //   title: "",
      //   sorting: false,
      //   render: data =>
      //     Object.keys(data).length !== 2 && (
      //       <DistListIcon
      //         onClick={setShowDistList}
      //         selectedCustomerId={selectedCustomerId}
      //         itemIdToEdit={itemIdToEdit}
      //         setItemIdToEdit={setItemIdToEdit}
      //         data={data}
      //       />
      //     )
      // }
    ];

    if (tableView === TableSize.REDUCED) {
      baseColumns.splice(
        2,
        0,
        ...([
          {
            title: "Objekt-Nr.",
            field: "object_number"
          },
          {
            title: "Submiss",
            field: "submiss_date"
          },
          {
            title: "Bauzeit",
            field: "construction_time"
          },
          {
            title: "Tonnage",
            field: "tonnage"
          },
          {
            title: "Kategorie",
            field: "category"
          }
        ] as Column<any>[])
      );
    }

    if (tableView === TableSize.FULL) {
      baseColumns.splice(
        2,
        0,
        ...([
          {
            title: "Objekt-Nr.",
            field: "object_number"
          },
          {
            title: "Submiss",
            field: "submiss_date"
          },
          {
            title: "Bauzeit",
            field: "construction_time"
          },
          {
            title: "Niederlassung",
            field: "settling",
            render: (data: { settling: Location[] }) => {
              return data.settling?.map(v => v.description).join(", ");
            }
          },
          {
            title: "Tonnage",
            field: "tonnage"
          },
          {
            title: "Material",
            field: "category"
          },
          {
            title: "Sub-Gewinner",
            field: "winner_first_level"
          },
          {
            title: "Mischwerk",
            field: "winner_second_level"
          },
          {
            title: "Entf. 1",
            field: "dist_1",
            render: data => {
              return (
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight:
                      coordsToShowRoute && data.dist_1
                        ? checkIfRouteActive(coordsToShowRoute, {
                            origin: data.location,
                            destination: data.dist_1.location
                          })
                          ? "bold"
                          : "normal"
                        : "normal",
                    color: data.dist_1
                      ? data.dist_1.type === Category.COMPETITION
                        ? "#dc3545"
                        : data.dist_1.type === Category.SETTLING
                        ? "#0275d8"
                        : data.dist_1.type === Category.KUNDE
                        ? "#f0ad4e"
                        : ""
                      : ""
                  }}
                  title={data.dist_1?.description}
                  hidden={!data.dist_1}
                  onClick={e => {
                    e.stopPropagation();
                    if (
                      coordsToShowRoute &&
                      checkIfRouteActive(coordsToShowRoute, {
                        origin: data.location,
                        destination: data.dist_1.location
                      })
                    ) {
                      handleSetCoordsToShowRoute(null);
                    } else
                      handleSetCoordsToShowRoute({
                        origin: data.location,
                        destination: data.dist_1.location
                      });
                  }}
                >
                  {data.dist_1?.distance}
                </span>
              );
            }
          },
          {
            title: "Entf. 2",
            field: "dist_2",
            render: data => {
              return (
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight:
                      coordsToShowRoute && data.dist_2
                        ? checkIfRouteActive(coordsToShowRoute, {
                            origin: data.location,
                            destination: data.dist_2.location
                          })
                          ? "bold"
                          : "normal"
                        : "normal",
                    color: data.dist_2
                      ? data.dist_2.type === Category.COMPETITION
                        ? "#dc3545"
                        : data.dist_2.type === Category.SETTLING
                        ? "#0275d8"
                        : data.dist_2.type === Category.KUNDE
                        ? "#f0ad4e"
                        : ""
                      : ""
                  }}
                  title={data.dist_2?.description}
                  hidden={!data.dist_2}
                  onClick={e => {
                    e.stopPropagation();
                    if (
                      coordsToShowRoute &&
                      checkIfRouteActive(coordsToShowRoute, {
                        origin: data.location,
                        destination: data.dist_2.location
                      })
                    ) {
                      handleSetCoordsToShowRoute(null);
                    } else
                      handleSetCoordsToShowRoute({
                        origin: data.location,
                        destination: data.dist_2.location
                      });
                  }}
                >
                  {data.dist_2?.distance}
                </span>
              );
            }
          },
          {
            title: "Entf. 3",
            field: "dist_3",
            render: data => {
              return (
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight:
                      coordsToShowRoute && data.dist_3
                        ? checkIfRouteActive(coordsToShowRoute, {
                            origin: data.location,
                            destination: data.dist_3.location
                          })
                          ? "bold"
                          : "normal"
                        : "normal",
                    color: data.dist_3
                      ? data.dist_3.type === Category.COMPETITION
                        ? "#dc3545"
                        : data.dist_3.type === Category.SETTLING
                        ? "#0275d8"
                        : data.dist_3.type === Category.KUNDE
                        ? "#f0ad4e"
                        : ""
                      : ""
                  }}
                  title={data.dist_3?.description}
                  hidden={!data.dist_3}
                  onClick={e => {
                    e.stopPropagation();
                    if (
                      coordsToShowRoute &&
                      checkIfRouteActive(coordsToShowRoute, {
                        origin: data.location,
                        destination: data.dist_3.location
                      })
                    ) {
                      handleSetCoordsToShowRoute(null);
                    } else
                      handleSetCoordsToShowRoute({
                        origin: data.location,
                        destination: data.dist_3.location
                      });
                  }}
                >
                  {data.dist_3?.distance}
                </span>
              );
            }
          },
          {
            title: "Entf. 4",
            field: "dist_4",
            render: data => {
              return (
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight:
                      coordsToShowRoute && data.dist_4
                        ? checkIfRouteActive(coordsToShowRoute, {
                            origin: data.location,
                            destination: data.dist_4.location
                          })
                          ? "bold"
                          : "normal"
                        : "normal",
                    color: data.dist_4
                      ? data.dist_4.type === Category.COMPETITION
                        ? "#dc3545"
                        : data.dist_4.type === Category.SETTLING
                        ? "#0275d8"
                        : data.dist_4.type === Category.KUNDE
                        ? "#f0ad4e"
                        : ""
                      : ""
                  }}
                  title={data.dist_4?.description}
                  hidden={!data.dist_4}
                  onClick={e => {
                    e.stopPropagation();
                    if (
                      coordsToShowRoute &&
                      checkIfRouteActive(coordsToShowRoute, {
                        origin: data.location,
                        destination: data.dist_4.location
                      })
                    ) {
                      handleSetCoordsToShowRoute(null);
                    } else
                      handleSetCoordsToShowRoute({
                        origin: data.location,
                        destination: data.dist_4.location
                      });
                  }}
                >
                  {data.dist_4?.distance}
                </span>
              );
            }
          },
          {
            title: "Entf. 5",
            field: "dist_5",
            render: data => {
              return (
                <span
                  style={{
                    cursor: "pointer",
                    fontWeight:
                      coordsToShowRoute && data.dist_5
                        ? checkIfRouteActive(coordsToShowRoute, {
                            origin: data.location,
                            destination: data.dist_5.location
                          })
                          ? "bold"
                          : "normal"
                        : "normal",
                    color: data.dist_5
                      ? data.dist_5.type === Category.COMPETITION
                        ? "#dc3545"
                        : data.dist_5.type === Category.SETTLING
                        ? "#0275d8"
                        : data.dist_5.type === Category.KUNDE
                        ? "#f0ad4e"
                        : ""
                      : ""
                  }}
                  title={data.dist_5?.description}
                  hidden={!data.dist_5}
                  onClick={e => {
                    e.stopPropagation();
                    if (
                      coordsToShowRoute &&
                      checkIfRouteActive(coordsToShowRoute, {
                        origin: data.location,
                        destination: data.dist_5.location
                      })
                    ) {
                      handleSetCoordsToShowRoute(null);
                    } else
                      handleSetCoordsToShowRoute({
                        origin: data.location,
                        destination: data.dist_5.location
                      });
                  }}
                >
                  {data.dist_5?.distance}
                </span>
              );
            }
          }
        ] as Column<any>[])
      );
    }
    return baseColumns.map(column => ({
      ...column,
      cellStyle: {
        padding: "10px calc(0.3%)",
        textAlign: "center"
      }
    })) as Column<any>[];
  }, [coordsToShowRoute, handleSetCoordsToShowRoute, tableView]);

  const data: RowData[] = useMemo(() => {
    let locationsData = populatedCustomers.filter(
      loc => loc.type.main === Category.CUSTOMERS
    );
    if (fromDate) {
      locationsData = locationsData.filter(loc => {
        if (loc.type.sub) {
          const subType = loc.type.sub as CustomerSubType;
          if (
            subType.submiss_date &&
            isValid(parse(subType.submiss_date, "yyyy-mm-dd", new Date()))
          ) {
            return (
              new Date(subType.submiss_date).getTime() >=
              new Date(fromDate).getTime()
            );
          }
          return false;
        }
        return false;
      });
    }
    if (toDate) {
      locationsData = locationsData.filter(loc => {
        if (loc.type.sub) {
          const subType = loc.type.sub as CustomerSubType;
          if (
            subType.submiss_date &&
            isValid(parse(subType.submiss_date, "yyyy-mm-dd", new Date()))
          ) {
            return (
              new Date(subType.submiss_date).getTime() <=
              new Date(toDate).getTime()
            );
          }
          return false;
        }
        return false;
      });
    }
    const filteredData = locationsData
      .map(loc => {
        if (!loc.type.sub && typeof loc.type.sub !== "object") {
          return {
            description: loc.description,
            formatted_address: loc.formatted_address
          };
        }
        const subType = loc.type.sub;
        const obj_number = subType.object_number;
        let formatted_obj_number: string[] | string = "";
        if (obj_number) {
          formatted_obj_number = obj_number.toString().split(" ").join("");
        }
        return {
          description: loc.description,
          customer_id: loc.id,
          formatted_address: loc.formatted_address,
          location: loc.location,
          tonnage: subType.materials.map(t => t.tonnage).join(", "),
          settling: subType.settling,
          category: subType.materials
            .reduce(
              (acc: string[], curr) => [
                ...acc,
                materials.find(m => m.id === curr.category.id)?.name || ""
              ],
              []
            )
            .join(", "),
          construction_time:
            subType.construction_time.from && subType.construction_time.to
              ? subType.construction_time.from.split("-").reverse().join(".") +
                " – " +
                subType.construction_time.to.split("-").reverse().join(".")
              : "",
          kunde: subType.kunde?.description || "",
          winner_first_level: subType.winner_first_level?.description || "",
          winner_second_level: subType.winner_second_level?.description || "",
          object_number: formatted_obj_number,
          submiss_date: subType.submiss_date?.split("-").reverse().join("."),
          dist_1: subType.nearest_neighbours[0] || "",
          dist_2: subType.nearest_neighbours[1] || "",
          dist_3: subType.nearest_neighbours[2] || "",
          dist_4: subType.nearest_neighbours[3] || "",
          dist_5: subType.nearest_neighbours[4] || "",
          notes: subType.notes || ""
        };
      })
      .filter(data => {
        return Object.keys(data).some(key => {
          const val = data[key as keyof typeof data];
          if (key === "settling") {
            return (val as Location[]).some(v =>
              v.description.toLowerCase().startsWith(searchText.toLowerCase())
            );
          } else {
            return (
              val
                ?.toString()
                .toLowerCase()
                .startsWith(searchText.toLowerCase()) ||
              val?.toString().toLowerCase().includes(searchText.toLowerCase())
            );
          }
        });
      })
      .filter(data =>
        filterSettlingsCategories.length
          ? filterSettlingsCategories.find(filter =>
              (data.settling as Location[]).some(v =>
                (v.type.sub as SettlingSubType).title
                  .toLowerCase()
                  .startsWith(filter.value.toLowerCase())
              )
            )
          : true
      )
      .filter(data =>
        filterSettlings.length
          ? filterSettlings.find(filter => {
              console.log(data.settling, filter.value);
              return data.settling?.some(v => v.id === filter.value);
            })
          : true
      )
      .filter(data =>
        filterMaterial.length
          ? filterMaterial.find(filter => data.category?.includes(filter.label))
          : true
      )
      .filter(data =>
        filterSubWinner.length
          ? filterSubWinner.find(filter => {
              console.log(data.winner_first_level, filter.value);
              return data.winner_first_level?.includes(filter.value);
            })
          : true
      )
      .filter(data =>
        filterKunde.length
          ? filterKunde.find(filter => data.kunde?.includes(filter.value))
          : true
      )
      .filter(data =>
        filterSupplier.length
          ? filterSupplier.find(filter =>
              data.winner_second_level?.includes(filter.value)
            )
          : true
      );
    return filteredData;
  }, [
    populatedCustomers,
    fromDate,
    toDate,
    materials,
    searchText,
    filterSettlingsCategories,
    filterSettlings,
    filterMaterial,
    filterSubWinner,
    filterKunde,
    filterSupplier
  ]);

  useEffect(() => {
    if (!searchTextChanged) return;
    setSearchTextChanged(false);
    if (searchText || fromDate || toDate)
      setCustomersForMap(
        data.map(
          d => locations.find(loc => loc.id === d.customer_id) as Location
        )
      );
    else setCustomersForMap(null);
  }, [
    searchText,
    fromDate,
    toDate,
    setCustomersForMap,
    data,
    locations,
    searchTextChanged
  ]);

  const dataByMonth = useMemo(() => {
    let groups: any[] = [
      { title: "Sonstige", data: [{ description: "Sonstige" }] }
    ];
    for (const d of data) {
      if (!d.submiss_date) {
        groups = groups.map(g =>
          g.title === "Sonstige" ? { ...g, data: [...g.data, d] } : g
        );
      } else {
        const foundGroupIdx = groups
          .map(g => g.title)
          .indexOf(
            d.submiss_date
              .split(".")
              .filter((_, i) => i !== 0)
              .join(".")
          );
        if (foundGroupIdx !== -1) {
          groups = groups.map((g, idx) =>
            idx === foundGroupIdx ? { ...g, data: [...g.data, d] } : g
          );
        } else
          groups = [
            {
              title: d.submiss_date
                .split(".")
                .filter((_, i) => i !== 0)
                .join("."),
              data: [
                {
                  description: d.submiss_date
                    .split(".")
                    .filter((_, i) => i !== 0)
                    .join(".")
                },
                d
              ]
            },
            ...groups
          ];
      }
    }
    for (const group of groups) {
      group.data = [
        group.data[0],
        ...group.data.slice(1).sort((a: any, b: any) => {
          return a.submiss_date === b.submiss_date
            ? 0
            : a.submiss_date < b.submiss_date
            ? 1
            : -1;
        })
      ];
    }
    return groups
      .filter(g => g.data.length > 1)
      .sort((a, b) => {
        const dateStringA: string = a.title.split(".").join(".01.");
        const dateStringB: string = b.title.split(".").join(".01.");

        if (dateStringA === "Sonstige" || dateStringB === "Sonstige") return 1;
        else if (
          isValid(parse(dateStringA, "dd.mm.yyyy", new Date())) &&
          isValid(parse(dateStringB, "dd.mm.yyyy", new Date()))
        ) {
          return -parse(dateStringA, "dd.mm.yyyy", new Date())
            .toISOString()
            .localeCompare(
              parse(dateStringB, "dd.mm.yyyy", new Date()).toISOString()
            );
        } else return -1;
      })
      .reduce((acc: any[], curr: any) => [...acc, ...curr.data], []);
  }, [data]);

  const resetDateFilter = () => {
    setFromDate(undefined);
    setToDate(undefined);
  };

  const exportTableAsCsv = () =>
    exportCSV(
      dataByMonth
        .filter(d => Object.keys(d).length > 2)
        .map(d => [
          withoutUmlauts(d.description.replaceAll(",", " ")),
          withoutUmlauts(d.formatted_address.replaceAll(",", " ")),
          d.object_number.replaceAll(",", " ") || "Keine Angabe",
          d.submiss_date.replaceAll(",", " ") || "Keine Angabe",
          d.construction_time.replaceAll(",", " ") || "Keine Angabe",
          d.settling.map((v: any) => v.description).length
            ? d.settling
                .map((v: any) => v.description)
                .join(":")
                .replaceAll(",", " ")
            : "Keine Angabe",
          d.dist_1
            ? `${d.dist_1.description} - ${d.dist_1.distance}km`.replaceAll(
                ",",
                " "
              )
            : "Keine Angabe",
          d.dist_2
            ? `${d.dist_2.description} - ${d.dist_2.distance}km`.replaceAll(
                ",",
                " "
              )
            : "Keine Angabe",
          d.dist_3
            ? `${d.dist_3.description} - ${d.dist_3.distance}km`.replaceAll(
                ",",
                " "
              )
            : "Keine Angabe",
          d.dist_4
            ? `${d.dist_4.description} - ${d.dist_4.distance}km`.replaceAll(
                ",",
                " "
              )
            : "Keine Angabe",
          d.dist_5
            ? `${d.dist_5.description} - ${d.dist_5.distance}km`.replaceAll(
                ",",
                " "
              )
            : "Keine Angabe",
          d.tonnage || "Keine Angabe",
          withoutUmlauts(d.category.replaceAll(",", ";")).replaceAll(
            ",",
            " "
          ) || "Keine Angabe",
          withoutUmlauts(d.winner_first_level.replaceAll(",", " ")).replaceAll(
            ",",
            " "
          ) || "Keine Angabe",
          withoutUmlauts(d.winner_second_level.replaceAll(",", " ")).replaceAll(
            ",",
            " "
          ) || "Keine Angabe",
          d.notes ? d.notes.replaceAll("\n", " ") : "Keine Angabe"
        ]),
      [
        [
          "Beschreibung",
          "Adresse",
          "Objekt-Nr.",
          "Submiss",
          "Bauzeit",
          "Niederlassung",
          "Entfernung 1",
          "Entfernung 2",
          "Entfernung 3",
          "Entfernung 4",
          "Entfernung 5",
          "Tonnage",
          "Material",
          "Sub-Gewinner",
          "Mischwerk",
          "Notizen"
        ]
      ],
      `Kemna_Baustellen_${new Date().toISOString()}.csv`
    );

  const isTableSmall = tableView === TableSize.SMALL;

  return (
    <>
      {/* {!isTableSmall && (
        <h4
          className="position-absolute text-light"
          style={{
            top: -60,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 10000
          }}
        >
          Baustellen
        </h4>
      )} */}
      <div className="position-relative" style={{ zIndex: 500 }}>
        <MaterialTable
          columns={columns}
          data={dataByMonth}
          localization={{
            pagination: {
              labelRowsSelect: "Zeilen",
              firstTooltip: "Erste Seite",
              lastTooltip: "Letzte Seite",
              nextTooltip: "Nächste Seite",
              previousTooltip: "Vorherige Seite"
            }
          }}
          components={{
            Toolbar: props =>
              openFilter ? (
                <div
                  className="my-2"
                  style={{ height: !openFilter ? 0 : !isTableSmall ? 125 : 65 }}
                ></div>
              ) : (
                <div></div>
              )
          }}
          onRowClick={(e, data) => {
            if (itemIdToEdit) setItemIdToEdit(null);
            setShowDistList(false);
            handleRowClick(data as RowData);
          }}
          actions={[
            (rowData: RowData) => {
              const hidden =
                Object.keys(rowData).length === 2 || !allowEditCustomer;
              return {
                position: "row",
                hidden,
                onClick: () => {
                  console.log("here");

                  const isSelected = itemIdToEdit === rowData.customer_id;
                  if (isSelected) {
                    setItemIdToEdit(null);
                  } else {
                    if (showDistList) setShowDistList(false);
                    setItemIdToEdit(rowData.customer_id as string);
                  }
                },
                icon: () => {
                  const isSelected = itemIdToEdit === rowData.customer_id;
                  return (
                    <span
                      style={{
                        fontSize: "1.2rem",
                        color: isSelected ? "grey" : undefined
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  );
                }
              };
            },
            (rowData: RowData) => {
              const isSelected = selectedCustomerId === rowData.customer_id;
              const hidden = Object.keys(rowData).length === 2 || !isSelected;
              return {
                position: "row",
                hidden,
                onClick: () => {
                  if (itemIdToEdit) setItemIdToEdit(null);
                  setShowDistList(prev => !prev);
                },
                icon: () => {
                  return (
                    <span>
                      <FontAwesomeIcon icon={faList} />
                    </span>
                  );
                }
              };
            }
          ]}
          options={{
            paging: true,
            pageSizeOptions: [5, 10, 30, 50],
            paginationType: "normal",
            pageSize: 50,
            paginationPosition: "top",
            search: false,
            toolbar: true,

            headerStyle: {
              position: "sticky",
              top: 0,
              whiteSpace: "nowrap",
              zIndex: 1000,
              backgroundColor: "lightgrey",
              textAlign: "center",
              padding: "0.5rem"
            },
            showTitle: false,
            actionsColumnIndex: 16,
            columnResizable: false,
            draggable: false,
            sorting: false,
            columnsButton: false,
            minBodyHeight: "auto",
            maxBodyHeight: `calc(100vh - ${!openFilter ? "165px" : "310px"})`,
            rowStyle: (row: RowData) => {
              const fontStyle: CSSProperties =
                Object.keys(row).length === 2
                  ? {
                      fontSize: "1.1rem",
                      fontWeight: "bold",
                      backgroundColor: "rgba(241, 241, 241, 0.708)"
                    }
                  : { fontSize: "0.8rem", fontWeight: "normal" };
              const selectedStyle: CSSProperties = {
                backgroundColor:
                  selectedCustomerId === row.customer_id
                    ? "#007bffb8"
                    : "inherit",
                color:
                  selectedCustomerId === row.customer_id ? "white" : "black"
              };
              return {
                ...selectedStyle,
                ...fontStyle,
                textAlign: "center"
              };
            }
          }}
        />
        <div
          className="position-absolute btn btn-light btn-sm d-flex justify-content-center align-items-center"
          style={{ top: 10, right: 375, fontSize: "1.2rem", cursor: "pointer" }}
          onClick={() => setOpenFilter(prev => !prev)}
        >
          <FontAwesomeIcon
            icon={openFilter ? faChevronUp : faChevronDown}
            className="mr-2"
          />
          <FontAwesomeIcon icon={faFilter} />
        </div>
        {openFilter && (
          <div>
            <div
              className="position-absolute w-100 bg-white"
              style={{ height: isTableSmall ? 55 : 125, top: 50 }}
            >
              {!isTableSmall && (
                <div
                  className="position-absolute d-flex w-75 justify-content-end  align-items-center px-2"
                  style={{
                    zIndex: 1001,
                    left: 0,
                    gap: 7
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <label htmlFor="settlings">
                      <strong className="mr-2">
                        <small>Niederlassung:Kategorie</small>
                      </strong>
                    </label>
                    <Select
                      options={selectSettlingCategories}
                      defaultValue={filterSettlingsCategories}
                      onChange={setFilterSettlingsCategories}
                      isMulti
                      name="settlingsCategories"
                    />
                  </div>
                  <div
                    className="position-relative"
                    style={{
                      flex: 1,
                      marginRight: 10,
                      display: "inline-block"
                    }}
                  >
                    <label htmlFor="settlings">
                      <strong className="mr-2">
                        <small>Niederlassung</small>
                      </strong>
                    </label>
                    <Select
                      options={
                        selectSettlings as { label: string; value: any }[]
                      }
                      defaultValue={filterSettlings}
                      onChange={setFilterSettlings}
                      isMulti
                      styles={{
                        valueContainer: (provided, state) => ({
                          ...provided,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          flexWrap: "nowrap"
                        })
                      }}
                      name="settlings"
                    />
                  </div>
                </div>
              )}
              <div
                className="position-absolute d-flex w-100 justify-content-end  align-items-center px-2"
                style={{
                  zIndex: 1000,
                  top: !openFilter ? 0 : !isTableSmall ? 85 : 25,
                  left: 0,
                  gap: 7
                }}
              >
                {!isTableSmall && (
                  <>
                    <div
                      style={{
                        flex: 1,
                        whiteSpace: "nowrap",
                        marginTop: -20
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          margin: 0,
                          padding: 0
                        }}
                      >
                        {tableView === TableSize.FULL && (
                          <>
                            <div
                              className="position-relative"
                              style={{
                                width: 175,
                                marginRight: 10,
                                display: "inline-block"
                              }}
                            >
                              <label htmlFor="settlings">
                                <strong className="mr-2">
                                  <small>Material</small>
                                </strong>
                              </label>
                              <Select
                                options={
                                  selectMaterial as {
                                    label: string;
                                    value: Material;
                                  }[]
                                }
                                defaultValue={filterMaterial}
                                onChange={setFilterMaterial}
                                isMulti
                                styles={{
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    flexWrap: "nowrap"
                                  })
                                }}
                                name="materials"
                              />
                            </div>
                            <div
                              className="position-relative"
                              style={{
                                width: 175,
                                marginRight: 10,
                                display: "inline-block"
                              }}
                            >
                              <label htmlFor="settlings">
                                <strong className="mr-2">
                                  <small>Sub-Gewinner</small>
                                </strong>
                              </label>
                              <Select
                                options={
                                  selectSubWinner as {
                                    label: string;
                                    value: any;
                                  }[]
                                }
                                defaultValue={filterSubWinner}
                                onChange={setFilterSubWinner}
                                isMulti
                                styles={{
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    flexWrap: "nowrap"
                                  })
                                }}
                                name="kunden"
                              />
                            </div>
                            <div
                              className="position-relative"
                              style={{
                                width: 175,
                                marginRight: 10,
                                display: "inline-block"
                              }}
                            >
                              <label htmlFor="settlings">
                                <strong className="mr-2">
                                  <small>Kunde</small>
                                </strong>
                              </label>
                              <Select
                                options={
                                  selectKunden as {
                                    label: string;
                                    value: any;
                                  }[]
                                }
                                defaultValue={filterKunde}
                                onChange={setFilterKunde}
                                isMulti
                                styles={{
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    flexWrap: "nowrap"
                                  })
                                }}
                                name="kunden"
                              />
                            </div>
                            <div
                              className="position-relative"
                              style={{
                                width: 175,
                                marginRight: 10,
                                display: "inline-block"
                              }}
                            >
                              <label htmlFor="settlings">
                                <strong className="mr-2">
                                  <small>Mischwerk</small>
                                </strong>
                              </label>
                              <Select
                                options={
                                  selectSupplier as {
                                    label: string;
                                    value: any;
                                  }[]
                                }
                                defaultValue={filterSupplier}
                                onChange={setFilterSupplier}
                                isMulti
                                styles={{
                                  valueContainer: (provided, state) => ({
                                    ...provided,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    flexWrap: "nowrap"
                                  })
                                }}
                                name="supplier"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <label htmlFor="from-filter">
                        <strong className="mr-2">Von</strong>
                      </label>
                      <div>
                        <input
                          type="date"
                          onClick={e => {
                            e.preventDefault();
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            e.nativeEvent.target?.showPicker();
                          }}
                          name="from-filter"
                          className="form-control"
                          value={fromDate}
                          onChange={e => setFromDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="border-left" style={{ height: 30 }} />
                    <div className="d-flex align-items-center">
                      <label htmlFor="to-filter">
                        <strong className="mr-2">Bis</strong>
                      </label>
                      <div>
                        <input
                          type="date"
                          onClick={e => {
                            e.preventDefault();
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            e.nativeEvent.target?.showPicker();
                          }}
                          name="to-filter"
                          className="form-control"
                          value={toDate}
                          onChange={e => setToDate(e.target.value)}
                        />
                      </div>
                    </div>

                    <button
                      className="btn btn-sm btn-outline-secondary p-0"
                      style={{ width: 38, aspectRatio: "1 / 1", flexShrink: 0 }}
                      title="Zurücksetzen"
                      onClick={resetDateFilter}
                    >
                      <FontAwesomeIcon icon={faRedo} />
                    </button>
                  </>
                )}
                <div className="position-relative d-flex ">
                  <input
                    type="text"
                    value={searchText}
                    onChange={e => {
                      setSearchText(e.target.value);
                      setSearchTextChanged(true);
                    }}
                    className="form-control"
                    style={{ paddingLeft: 45 }}
                  />
                  <div
                    className="d-flex align-items-center justify-content-center border-right text-secondary"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      aspectRatio: "1 / 1",
                      fontSize: "1.1rem"
                    }}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                </div>
                <button
                  className="btn btn-sm btn-outline-secondary p-0"
                  style={{ width: 38, aspectRatio: "1 / 1", flexShrink: 0 }}
                  title="Baustellen als CSV exportieren"
                  onClick={exportTableAsCsv}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              </div>
            </div>
          </div>
        )}
        {allowCreateCustomer &&
          (tableView === TableSize.SMALL ? (
            <div
              className="btn btn-primary btn-sm p-0 d-flex justify-content-center align-items-center  ml-2  position-absolute"
              style={{
                top: 10,
                height: 30,
                width: 30,
                fontSize: "0.9rem",
                border: "none"
              }}
              title="Baustelle hinzufügen"
              onClick={handleStartCustomerCreation}
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
          ) : (
            <button
              className="btn btn-primary ml-2 btn-sm mr-2 position-absolute"
              onClick={handleStartCustomerCreation}
              style={{ top: 10 }}
            >
              Baustelle hinzufügen
            </button>
          ))}
      </div>
    </>
  );
};

export default React.memo(CustomersTable);
