import {
  faEye,
  faEyeSlash,
  faPencil,
  faPencilSlash
} from "@fortawesome/pro-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Action, permissionsNew } from "../../constants/permissions";
import { useDomainContext } from "../../context/DomainContext";
import { usePermissions } from "../../context/PermissionsContext";
import { SettlingSubType } from "../../types";

const getScopeName = (scope: string) => {
  switch (scope) {
    case "Nutzer:innen / Module":
      return "Module";
    case "Nutzer:innen / Niederlassungsgebiet":
      return "Bereich";
    case "Nutzer:innen / Domain":
      return "Sparte";
  }
};

function PermissionsTable({ close }: { close: () => void }) {
  const {
    sitePermissions,
    updateSitePermission,
    updateLocalPermissions
  } = usePermissions();
  const { getUserPermission } = usePermissions();
  const { allSettlingSubTypes } = useDomainContext();

  const allowEditPermissions = getUserPermission(
    "Nutzer:innen / Module",
    "Berechtigung",
    Action.WRITE
  );
  return (
    <div
      className="bg-light shadow-lg rounded p-2"
      style={{
        fontSize: "0.8em",
        position: "fixed",
        overflow: "scroll",
        top: 10,
        right: 10,
        left: 10,
        bottom: 10,
        zIndex: 10000,
        padding: "45px 20px 10px 20px"
      }}
    >
      <button
        className="btn btn-outline-secondary"
        style={{
          fontSize: "1.2rem",
          padding: 0,
          width: 35,
          height: 35,
          position: "sticky",
          top: 0,
          left: "calc(100% - 35px)"
        }}
        onClick={close}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="w-100">
        {permissionsNew.map(obj => {
          return (
            <Fragment key={obj.scope}>
              <h1>{getScopeName(obj.scope)}</h1>
              <table
                className="table table-bordered"
                key={`table-${obj.scope}`}
              >
                <thead>
                  <tr>
                    <th scope="col">Name/Email</th>
                    {obj.scope === "Nutzer:innen / Module" && (
                      <th scope="col">Zugang</th>
                    )}
                    {obj.scope !== "Nutzer:innen / Niederlassungsgebiet" &&
                      obj.permissions.map(p => (
                        <th
                          key={`table-${obj.scope}-column-${p.name}`}
                          scope="col"
                        >
                          {p.name}
                        </th>
                      ))}
                    {obj.scope === "Nutzer:innen / Niederlassungsgebiet" &&
                      [
                        ...allSettlingSubTypes,
                        { main: "", sub: { title: "Ohne Zuordnung" } }
                      ].map((subType, key) => {
                        return (
                          <th
                            key={`table-${obj.scope}-column-${key}`}
                            scope="col"
                            style={{
                              writingMode: "vertical-lr",
                              transform: "rotate(180deg)"
                            }}
                          >
                            {typeof subType.sub === "string"
                              ? subType.sub
                              : (subType.sub as SettlingSubType).title}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {sitePermissions.map((user, key) => {
                    const displayName = `${user.data.first_name || ""} ${
                      user.data.last_name || ""
                    }`.trim();
                    return (
                      <>
                        <tr key={`table-${obj.scope}-row-${key}`}>
                          <td>
                            <span>{displayName}</span>
                            <br />
                            <span>{user.data.email}</span>
                          </td>
                          {obj.scope === "Nutzer:innen / Module" && (
                            <td>
                              <input
                                type="checkbox"
                                name="permission-switch"
                                className="mx-auto"
                                disabled={!allowEditPermissions}
                                checked={user.data.access}
                                onChange={() =>
                                  updateSitePermission(
                                    user.id,
                                    !user.data.access
                                  )
                                }
                              />
                            </td>
                          )}
                          {obj.scope ===
                            "Nutzer:innen / Niederlassungsgebiet" &&
                            [
                              ...allSettlingSubTypes,
                              { main: "", sub: { title: "Ohne Zuordnung" } }
                            ].map((subType, idx) => {
                              const userPermission = user.data.permissions
                                .find(perm => perm.scope === obj.scope)
                                ?.permissions.find(
                                  p =>
                                    p.name ===
                                    (subType.sub as SettlingSubType).title
                                );
                              if (userPermission) {
                                return (
                                  <td
                                    key={`table-${obj.scope}-row-${key}-${idx}`}
                                  >
                                    <label style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        type="checkbox"
                                        name="permission-switch"
                                        className="mx-auto"
                                        disabled={!allowEditPermissions}
                                        checked={userPermission.read}
                                        onChange={() => {
                                          updateLocalPermissions(
                                            user.id,
                                            user.data.permissions.map(per =>
                                              per.scope === obj.scope
                                                ? {
                                                    ...per,
                                                    permissions: per.permissions.map(
                                                      p =>
                                                        p.name ===
                                                        userPermission.name
                                                          ? {
                                                              ...userPermission,
                                                              read: !userPermission.read
                                                            }
                                                          : p
                                                    )
                                                  }
                                                : per
                                            )
                                          );
                                        }}
                                      />
                                      <span className="ml-2">
                                        {userPermission.read === true ? (
                                          <FontAwesomeIcon
                                            icon={faEye}
                                            className="text-primary"
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faEyeSlash}
                                            className="text-secondary"
                                          />
                                        )}
                                      </span>
                                    </label>
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={`table-${obj.scope}-row-${
                                      (subType.sub as SettlingSubType).title
                                    }-${idx}`}
                                  >
                                    <label style={{ whiteSpace: "nowrap" }}>
                                      <input
                                        type="checkbox"
                                        name="permission-switch"
                                        disabled={!allowEditPermissions}
                                        className="mx-auto"
                                        checked={false}
                                        onChange={() => {
                                          updateLocalPermissions(
                                            user.id,
                                            user.data.permissions.map(per =>
                                              per.scope === obj.scope
                                                ? {
                                                    ...per,
                                                    permissions: [
                                                      ...per.permissions,
                                                      {
                                                        name: (subType.sub as any)
                                                          .title,
                                                        read: true
                                                      }
                                                    ]
                                                  }
                                                : per
                                            )
                                          );
                                        }}
                                      />
                                      <span className="ml-2">
                                        <FontAwesomeIcon
                                          icon={faEyeSlash}
                                          className="text-secondary"
                                        />
                                      </span>
                                    </label>
                                  </td>
                                );
                              }
                            })}
                          {obj.permissions.map(perm => {
                            const userPermission = user.data.permissions
                              .find(p => p.scope === obj.scope)
                              ?.permissions.find(p => p.name === perm.name);
                            if (!userPermission) return null;
                            return (
                              <td
                                key={`table-${obj.scope}-row-${key}-${perm.name}`}
                              >
                                {perm.actions.includes(Action.READ) && (
                                  <label style={{ whiteSpace: "nowrap" }}>
                                    <input
                                      type="checkbox"
                                      name="permission-switch"
                                      disabled={!allowEditPermissions}
                                      className="mx-auto"
                                      checked={userPermission.read}
                                      onChange={() => {
                                        updateLocalPermissions(
                                          user.id,
                                          user.data.permissions.map(per =>
                                            per.scope === obj.scope
                                              ? {
                                                  ...per,
                                                  permissions: per.permissions.map(
                                                    p =>
                                                      p.name ===
                                                      userPermission.name
                                                        ? {
                                                            ...userPermission,
                                                            read: !userPermission.read
                                                          }
                                                        : p
                                                  )
                                                }
                                              : per
                                          )
                                        );
                                      }}
                                    />
                                    <span className="ml-2">
                                      {userPermission.read === true ? (
                                        <FontAwesomeIcon
                                          icon={faEye}
                                          className="text-primary"
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faEyeSlash}
                                          className="text-secondary"
                                        />
                                      )}
                                    </span>
                                  </label>
                                )}
                                <br />
                                {perm.actions.includes(Action.WRITE) && (
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="permission-switch"
                                      disabled={!allowEditPermissions}
                                      className="mx-auto"
                                      checked={userPermission.write}
                                      onChange={() => {
                                        updateLocalPermissions(
                                          user.id,
                                          user.data.permissions.map(per =>
                                            per.scope === obj.scope
                                              ? {
                                                  ...per,
                                                  permissions: per.permissions.map(
                                                    p =>
                                                      p.name ===
                                                      userPermission.name
                                                        ? {
                                                            ...userPermission,
                                                            write: !userPermission.write
                                                          }
                                                        : p
                                                  )
                                                }
                                              : per
                                          )
                                        );
                                      }}
                                    />
                                    <span className="ml-2">
                                      {userPermission.write ? (
                                        <FontAwesomeIcon
                                          icon={faPencil}
                                          className="text-primary"
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faPencilSlash}
                                          className="text-secondary"
                                        />
                                      )}
                                    </span>
                                  </label>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default PermissionsTable;
