import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faEdit,
  faRotateLeft,
  faTimes
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useMaterials } from "../../context/MaterialsContext";

function MaterialsTable({ close }: { close: () => void }) {
  const {
    createMaterial,
    updateMaterial,
    deleteMaterial,
    materials
  } = useMaterials();
  const [newMaterialName, setNewMaterialName] = useState("");
  const [newMaterialColor, setNewMaterialColor] = useState("#000000");
  const [idToEdit, setIdToEdit] = useState("");
  const [editName, setEditName] = useState("");
  const [editColor, setEditColor] = useState("");

  return (
    <div
      className="bg-light shadow-lg rounded"
      style={{
        position: "absolute",
        overflow: "scroll",
        top: 10,
        right: 10,
        left: 10,
        bottom: 10,
        maxWidth: "25vw",
        zIndex: 10000,
        padding: "45px 20px 10px 20px"
      }}
    >
      <button
        className="btn btn-outline-secondary"
        style={{
          fontSize: "1.2rem",
          padding: 0,
          width: 35,
          height: 35,
          position: "absolute",
          top: 10,
          right: 10
        }}
        onClick={close}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="w-100">
        <h1>Materialen</h1>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Farbe</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {materials.map((mat, key) => {
              return (
                <tr key={`row-${mat.id}`}>
                  <td>
                    {idToEdit === mat.id ? (
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={editName}
                        onChange={e => setEditName(e.target.value)}
                      />
                    ) : (
                      <span>{mat.name}</span>
                    )}
                  </td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      {idToEdit === mat.id ? (
                        <input
                          type="color"
                          className="form-control form-control-sm p-1"
                          value={editColor}
                          onChange={e => setEditColor(e.target.value)}
                        />
                      ) : (
                        <span
                          style={{
                            backgroundColor: mat.color,
                            height: 20,
                            width: "100%"
                          }}
                        ></span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      {idToEdit === mat.id ? (
                        <button
                          className="btn"
                          onClick={() => {
                            updateMaterial(mat.id, editName, editColor);
                            setIdToEdit("");
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={{ fontSize: "1.2rem" }}
                            color={"#5cb85c"}
                          />
                        </button>
                      ) : (
                        <FontAwesomeIcon
                          icon={faEdit}
                          onClick={() => {
                            setIdToEdit(mat.id);
                            setEditName(mat.name);
                            setEditColor(mat.color);
                          }}
                          style={{ fontSize: "1.2rem" }}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      {!idToEdit && (
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => deleteMaterial(mat.id)}
                          style={{ fontSize: "1.2rem" }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td>
                <div className="h-100">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    value={newMaterialName}
                    onChange={e => setNewMaterialName(e.target.value)}
                  />
                </div>
              </td>
              <td>
                <input
                  type="color"
                  className="form-control form-control-sm p-1"
                  value={newMaterialColor}
                  onChange={e => setNewMaterialColor(e.target.value)}
                />
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    icon={faRotateLeft}
                    style={{ fontSize: "1.2rem" }}
                    onClick={() => {
                      setNewMaterialName("");
                      setNewMaterialColor("#000000");
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn"
                    disabled={!newMaterialName.length}
                    onClick={() => {
                      createMaterial(newMaterialName, newMaterialColor);
                      setNewMaterialName("");
                      setNewMaterialColor("#000000");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ fontSize: "1.2rem" }}
                      color={newMaterialName.length ? "#5cb85c" : "#f7f7f7"}
                    />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MaterialsTable;
