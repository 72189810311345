import { auth, googleProvider } from "../firebaseConfig";

export const signInWithGoogle = () => {
  return auth.signInWithPopup(googleProvider).catch(error => {
    console.log(error.message);
  });
};

export const signUpWithEmailAndPassword = (creds: {
  email: string;
  password: string;
  name?: string;
  surname: string;
}) => {
  const { email, password, name, surname } = creds;
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(user =>
      user.user?.updateProfile({ displayName: `${name} ${surname}` })
    )
    .catch(err => {
      throw err;
    });
};

export const signInWithEmailAndPassword = (creds: {
  email: string;
  password: string;
}) => {
  const { email, password } = creds;

  return auth
    .signInWithEmailAndPassword(email, password)
    .then(user => user)
    .catch(err => {
      throw err;
    });
};

export const logOut = () => {
  return auth
    .signOut()
    .then(() => {
      console.log("logged out");
    })
    .catch(error => {
      console.error(error.message);
    });
};
