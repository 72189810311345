import { Domain } from "../types";

export const permissions = [
  { view: "Domain", permissions: ["read", "write"] },
  { view: "Baustellen", permissions: ["read"] },
  { view: "Niederlassung", permissions: ["read"] },
  { view: "Konkurrenz", permissions: ["read"] },
  { view: "Kunden", permissions: ["read"] },
  { view: "Berechtigung", permissions: ["read", "write"] }
];

export type Permission = {
  scope: string;
  permissions: { name: string; actions: Action[] }[];
};

export type UserPermission = {
  scope: string;
  permissions: { name: string; read: boolean; write: boolean }[];
};

export enum Action {
  READ = "read",
  WRITE = "write"
}

export const permissionsNew: Permission[] = [
  {
    scope: "Nutzer:innen / Module",
    permissions: [
      {
        name: "Baustellen",
        actions: [Action.READ, Action.WRITE]
      },
      {
        name: "Niederlassung",
        actions: [Action.READ, Action.WRITE]
      },
      {
        name: "Konkurrenz",
        actions: [Action.READ, Action.WRITE]
      },
      {
        name: "Kunden",
        actions: [Action.READ, Action.WRITE]
      },
      {
        name: "Berechtigung",
        actions: [Action.READ, Action.WRITE]
      }
    ]
  },
  {
    scope: "Nutzer:innen / Niederlassungsgebiet",
    permissions: []
  },
  {
    scope: "Nutzer:innen / Domain",
    permissions: [
      { name: Domain.BAUBETRIEBE, actions: [Action.READ, Action.WRITE] },
      { name: Domain.MISCHANLAGEN, actions: [Action.READ, Action.WRITE] },
      { name: Domain.ROHSTOFFBETRIEBE, actions: [Action.READ, Action.WRITE] }
    ]
  }
];
