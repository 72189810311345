import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
  ChangeEvent,
  Profiler,
  useMemo
} from "react";
import styled from "styled-components";
import firebase from "../firebaseConfig";
import MapArea from "../components/MapArea/index";
import Sidebar from "../components/Sidebar/index";
import { UserContext } from "../context/user";
import { Redirect } from "react-router-dom";
import { v4 } from "uuid";
import { useMixPanel } from "../util/Mixpanel";
import {
  ActionSource,
  CompetitionSubType,
  CustomerSubType,
  DbCustomerSubType,
  Domain,
  FirestoreChangeType,
  InfoGroup,
  KundeSubType,
  Location,
  LogAction,
  RowData,
  SettlingSubType
} from "../types";
import { usePrevious } from "../hooks/usePrev";
import { isEqual } from "lodash";
import { db } from "../firebaseConfig";
import { useMapsContext } from "../context/MapsContext";
import { usePermissions } from "../context/PermissionsContext";
import { Action } from "../constants/permissions";
import { useDomainContext } from "../context/DomainContext";
import { updateFirestore } from "../migrate";
import { useConfirmationModal } from "../context/ConfirmationModalContext";
import { useFirestoreLogger } from "../context/FirestoreLogger";

const StyledMain = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const getDistanceMatrix = (service: any, data: any) =>
  new Promise((resolve, reject) => {
    service.getDistanceMatrix(data, (response: any, status: any) => {
      if (status === "OK") {
        console.log(status);
        resolve(response);
      } else {
        console.log(status);
        reject(new Error("Not OK"));
      }
    });
  });

export enum MarkerMode {
  DEFAULT = "default",
  MATERIALS = "materials"
}

export enum Category {
  SETTLING = "settling",
  CUSTOMERS = "customers",
  COMPETITION = "competition",
  KUNDE = "kunde"
}

export enum TableSize {
  SMALL = "small",
  REDUCED = "reduced",
  FULL = "full"
}

const projectName = process.env.REACT_APP_PROJECT_NAME || "";

function Main() {
  const [mapZoom, setMapZoom] = useState<number>(12);
  const [inputQuery, setInputQuery] = useState("");
  const [activeCategory, setActiveCategory] = useState<Category>(
    Category.CUSTOMERS
  );
  const [showCreationForm, setShowCreationForm] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState<{
    location: {
      lat: number;
      lng: number;
    };
    formatted_address: string;
  } | null>(null);
  const [allLocations, setAllLocations] = useState<Location[]>([]);
  const [groupsToShow, setGroupsToShow] = useState<Category[]>([
    Category.COMPETITION,
    Category.CUSTOMERS,
    Category.SETTLING,
    Category.KUNDE
  ]);
  const [infoGroups, setInfoGroups] = useState<InfoGroup[]>([]);
  const [distService, setDistService] = useState<any>(null);
  const [dirService, setDirService] = useState<any>(null);
  const [dirRenderer, setDirRenderer] = useState<any>(null);
  const [coordsToShowRoute, setCoordsToShowRoute] = useState<{
    origin: { lat: number; lng: number };
    destination: { lat: number; lng: number };
  } | null>(null);
  const [customerRadius, setCustomerRadius] = useState(30);
  const [settlingRadius, setSettlingRadius] = useState(30);
  const [editLocation, setEditLocation] = useState<{
    location: {
      lat: number;
      lng: number;
    };
    formatted_address: string;
  } | null>(null);
  const prevSearchedLocation = usePrevious(searchedLocation);
  const prevEditLocation = usePrevious(editLocation);
  const user = useContext(UserContext);
  const { mp } = useMixPanel();
  const [redirect, setRedirect] = useState<string | null>(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedLocationRadius, setSelectedLocationRadius] = useState<number>(
    30
  );
  const [selectedLocationRadius2, setSelectedLocationRadius2] = useState<
    number
  >(40);
  const [
    selectedLocationDateFilters,
    setSelectedLocationDateFilters
  ] = useState<{ from?: string; to?: string } | undefined>();
  const [customersForMap, setCustomersForMap] = useState<Location[] | null>(
    null
  );
  const [tableView, setTableView] = useState<TableSize>(TableSize.FULL);
  const { map, maps, geocode } = useMapsContext();
  const { getUserPermissionsByScope, sitePermissions } = usePermissions();
  const { domain } = useDomainContext();
  const { hideModal, showModal } = useConfirmationModal();

  const { logAction } = useFirestoreLogger();
  // useEffect(() => {
  //   db.collection("locations")
  //     .where("type.main", "==", Category.CUSTOMERS)
  //     .get()
  //     .then(sp =>
  //       sp.docs.forEach(doc => {
  //         const data = doc.data();
  //         doc.ref.update({
  //           ...data,
  //           type: {
  //             ...data.type,
  //             sub: {
  //               ...data.type.sub,
  //               materials: []
  //             }
  //           }
  //         });
  //       })
  //     );
  // }, []);
  // useEffect(() => {
  //   db.collection("locations")
  //     .where("type.main", "==", "settling")
  //     .get()
  //     .then(sp => {
  //       sp.docs.forEach(doc => {
  //         const data = doc.data();
  //         doc.ref.update({
  //           ...data,
  //           type: {
  //             ...data.type,
  //             sub: {
  //               ...data.type.sub,
  //               type1: data.type.sub.type1 || "",
  //               type2: data.type.sub.type2 || ""
  //             }
  //           }
  //         });
  //       });
  //     });
  // }, []);

  useEffect(() => {
    const viewsPermissions = getUserPermissionsByScope(
      "Nutzer:innen / Module",
      Action.READ
    );
    if (viewsPermissions.find(perm => perm.name === "Baustellen" && perm.read))
      return;
    else if (
      viewsPermissions.find(perm => perm.name === "Niederlassung" && perm.read)
    )
      setActiveCategory(Category.SETTLING);
    else if (
      viewsPermissions.find(perm => perm.name === "Konkurrenz" && perm.read)
    )
      setActiveCategory(Category.COMPETITION);
  }, [getUserPermissionsByScope]);

  useEffect(() => {
    if (!user) {
      setRedirect("/login");
    }
  }, [user]);

  const formatFetchedData = async (doc: any) => {
    const data = doc.data();

    if (!data) return;
    if (data.type.main === "customers") {
      const settling = data.type.sub as DbCustomerSubType;
      const settlings = await Promise.all(
        settling.settling.map(async s => {
          try {
            const doc = await s.get();
            return {
              ...doc.data(),
              id: doc.id,
              switched: true
            } as Location;
          } catch (e) {
            return null;
          }
        })
      );
      return {
        ...data,
        id: doc.id,
        switched: true,
        type: {
          ...data.type,
          sub: {
            ...data.type.sub,
            settling: settlings.filter(s => s != null)
          }
        }
      } as Location;
    } else {
      return {
        ...doc.data(),
        id: doc.id,
        switched: true
      } as Location;
    }
  };

  // useEffect(() => {
  //   const unsub = db.collection("locations").onSnapshot(async snapshot => {
  //     if (!allLocations.length) {
  //       console.log("((((");
  //       return;
  //     } else if (allLocations.length && !initiallSnapshotCallback) {
  //       console.log(")))))");
  //       setInitiallSnapshotCallback(true);
  //       return;
  //     }
  //     console.log("here");
  //     const toDay = new Date().toISOString().slice(0, 10);
  //     const logsResponse = await db.collection("logs").doc(toDay).get();
  //     const logs = (logsResponse.data()?.actions || []) as LogAction[];
  //     for (const doc of snapshot.docChanges()) {
  //       if (doc.type === "added") {
  //         const newItem = doc.doc.data();
  //         await db
  //           .collection("logs")
  //           .doc(toDay)
  //           .update({
  //             actions: [
  //               ...logs,
  //               {
  //                 itemId: doc.doc.id,
  //                 action: FirestoreChangeType.CREATE,
  //                 actionSource: ActionSource.MAIN,
  //                 itemType: newItem.type.main,
  //                 timestamp: new Date().toISOString()
  //               }
  //             ]
  //           });
  //       } else if (doc.type === "modified" && doc.oldIndex !== -1) {
  //         const newItem = doc.doc.data();
  //         await db
  //           .collection("logs")
  //           .doc(toDay)
  //           .update({
  //             actions: [
  //               ...logs,
  //               {
  //                 itemId: doc.doc.id,
  //                 action: FirestoreChangeType.UPDATE,
  //                 actionSource: ActionSource.MAIN,
  //                 itemType: newItem.type.main,
  //                 timestamp: new Date().toISOString()
  //               }
  //             ]
  //           });
  //       } else if (doc.type === "removed" && doc.oldIndex !== -1) {
  //         const newItem = doc.doc.data();
  //         await db
  //           .collection("logs")
  //           .doc(toDay)
  //           .update({
  //             actions: [
  //               ...logs,
  //               {
  //                 itemId: doc.doc.id,
  //                 action: FirestoreChangeType.DELETE,
  //                 actionSource: ActionSource.MAIN,
  //                 itemType: newItem.type.main,
  //                 timestamp: new Date().toISOString()
  //               }
  //             ]
  //           });
  //       }
  //     }
  //   });
  //   return () => {
  //     unsub();
  //   };
  // }, [allLocations.length, initiallSnapshotCallback]);

  useEffect(() => {
    if (!domain) return;
    const unsub = db
      .collection("locations")
      .where("domain", "==", domain)
      .onSnapshot(async snapshot => {
        console.log(snapshot.docs.length);
        const allData = await Promise.all(
          snapshot.docs.map(async doc => {
            return await formatFetchedData(doc);
          })
        );
        setAllLocations(allData.filter(l => l) as Location[]);
      });
    return () => {
      unsub();
    };
  }, [domain]);

  // useEffect(() => {
  //   const updateLocations = async () => {
  //     const sp = await db.collection("locations").get();
  //     const allData = [];
  //     for (const doc of sp.docs) {
  //       allData.push(doc);
  //     }
  //     //split data in chunks of 10
  //     const chunks = [];
  //     for (let i = 0; i < allData.length; i += 10) {
  //       chunks.push(allData.slice(i, i + 10));
  //     }
  //     for (const [idxChunk, chunk] of chunks.entries()) {
  //       await Promise.all(
  //         chunk.map(async (doc, idx) => {
  //           console.log(`doc ${idx} of chunk ${idxChunk}`);
  //           await doc.ref.update({ updated_at: new Date().toISOString() });
  //         })
  //       );
  //     }
  //   };
  //   updateLocations();
  // }, []);

  // useEffect(() => {
  //   db.collection("locations")
  //     .where("type.sub.title", "==", "MDB, Sennewitz")
  //     .get()
  //     .then(sp =>
  //       sp.docs.forEach(d => d.ref.update({ domain: Domain.ROHSTOFFBETRIEBE }))
  //     );
  // }, []);

  // useEffect(() => {
  //   db.collection("locations")
  //     .where("type.main", "==", Category.CUSTOMERS)
  //     .get()
  //     .then(async sp => {
  //       for (const doc of sp.docs) {
  //         const data = doc.data();
  //         if (
  //           data.type.sub.settling.length &&
  //           "id" in data.type.sub.settling[0]
  //         ) {
  //           await doc.ref.update({
  //             ...data,
  //             type: {
  //               ...data.type,
  //               sub: {
  //                 ...data.type.sub,
  //                 settling: data.type.sub.settling.map((s: any) =>
  //                   db.collection("locations").doc(s.id)
  //                 )
  //               }
  //             }
  //           });
  //         }
  //       }
  //     });
  // }, []);

  // // add domain field to locations
  // useEffect(() => {
  // db.collection("locations")
  //   .get()
  //   .then(snapshot => {
  //     snapshot.docs.forEach(doc => {
  //       const data = doc.data();
  //       doc.ref.update({
  //         ...data,
  //         domain: Domain.BAUBETRIEBE
  //       });
  //     });
  //   });
  // }, []);

  // update customers settlings
  // useEffect(() => {
  // db.collection("locations")
  //   .where("type.main", "==", "customers")
  //   .get()
  //   .then(snapshot => {
  //     snapshot.docs.forEach(doc => {
  //       const data = doc.data();
  //       console.log(data);
  //       const settling = data.type.sub.settling;
  //       const settlingId = settling?.id || "";
  //       const settlingRef = settling
  //         ? db.collection("locations").doc(settlingId)
  //         : null;
  //       doc.ref.update({
  //         ...data,
  //         type: {
  //           ...data.type,
  //           sub: {
  //             ...data.type.sub,
  //             settling: settling ? [settlingRef] : []
  //           }
  //         }
  //       });
  //     });
  //   });
  // }, []);

  // update competitions and settlings to add field "kifaId"
  // useEffect(() => {
  // db.collection("locations")
  //   .where("type.main", "!=", "customers")
  //   .get()
  //   .then(snapshot => {
  //     snapshot.docs.forEach(doc => {
  //       const data = doc.data();
  //       doc.ref.update({
  //         ...data,
  //         type: {
  //           ...data.type,
  //           sub: {
  //             ...data.type.sub,
  //             kifaId: null
  //           }
  //         }
  //       });
  //     });
  //   });
  // }, []);

  const userLocationsByDomain = useMemo(() => {
    if (!sitePermissions.length) return [];
    const allowedCategoriese = getUserPermissionsByScope(
      "Nutzer:innen / Niederlassungsgebiet",
      Action.READ
    ).map(perm => perm.name);
    return allLocations
      .filter(l => l.domain === domain)
      .filter((doc: Location) => {
        switch (doc.type.main) {
          case Category.CUSTOMERS: {
            const subType = doc.type.sub as CustomerSubType;

            const settling = subType.settling;

            if (!settling.length) return true;
            else
              return settling.every(s =>
                allowedCategoriese.includes(
                  (s.type.sub as SettlingSubType).title
                )
              );
          }
          case Category.KUNDE:
          case Category.COMPETITION:
            return true;
          case Category.SETTLING: {
            const subType = doc.type.sub as SettlingSubType;
            if (!subType.title)
              return allowedCategoriese.includes("Ohne Zuordnung");
            return allowedCategoriese.includes(subType.title);
          }
        }
      });
  }, [sitePermissions.length, getUserPermissionsByScope, allLocations, domain]);

  useEffect(() => {
    if (!maps) return;
    setDistService(new maps.DistanceMatrixService());
    setDirService(new maps.DirectionsService());
    setDirRenderer(new maps.DirectionsRenderer({ preserveViewport: true }));
  }, [maps]);

  const getShortRoute = async (request: any) => {
    const result: any = await new Promise((resolve, reject) => {
      dirService.route(request, (response: any, status: any) => {
        if (status === "OK") {
          resolve(response);
        } else reject(status);
      });
    });
    const shortestPath: string = result.routes
      .sort(
        (a: any, b: any) => a.legs[0].distance.value - b.legs[0].distance.value
      )[0]
      .legs[0].distance.text.split(" ")[0]
      .split(",")
      .join(".");
    return { result, shortestPath };
  };

  useEffect(() => {
    if (!coordsToShowRoute || !dirRenderer || !dirService || !map) return;
    dirRenderer.setMap(map);
    (async () => {
      const request = {
        origin: coordsToShowRoute.origin,
        destination: coordsToShowRoute.destination,
        travelMode: "DRIVING",
        provideRouteAlternatives: true
      };
      const { result, shortestPath } = await getShortRoute(request);
      let query = db
        .collection("distances")
        .where("origin.lat", "==", coordsToShowRoute.origin.lat);
      query = query.where("origin.lng", "==", coordsToShowRoute.origin.lng);
      query = query.where("dest.lat", "==", coordsToShowRoute.destination.lat);
      query = query.where("dest.lng", "==", coordsToShowRoute.destination.lng);
      const snapshot = await query.get();
      snapshot.forEach(async doc => {
        await doc.ref.update({ short_distance: shortestPath });
      });
      setInfoGroups(prev =>
        prev.map(g => ({
          ...g,
          distances: g.distances.map(d =>
            g.group_title.location.lat === coordsToShowRoute.destination.lat &&
            g.group_title.location.lng === coordsToShowRoute.destination.lng &&
            d.location.lat === coordsToShowRoute.origin.lat &&
            d.location.lng === coordsToShowRoute.origin.lng
              ? { ...d, shortDistance: shortestPath }
              : d
          )
        }))
      );
      if (result) {
        dirRenderer.setDirections(result);
        const bounds = new maps.LatLngBounds();
        bounds.extend(
          new maps.LatLng(
            result.request.origin.location.lat(),
            result.request.origin.location.lng()
          )
        );
        bounds.extend(
          new maps.LatLng(
            result.request.destination.location.lat(),
            result.request.destination.location.lng()
          )
        );
        map.fitBounds(bounds);
        map.setZoom(map.getZoom() - 1);
      }
    })();
    return () => {
      dirRenderer.setMap(null);
    };
  }, [coordsToShowRoute, dirService, dirRenderer, map]);

  useEffect(() => {
    if (
      !searchedLocation ||
      isEqual(searchedLocation.location, prevSearchedLocation?.location)
    )
      return;
    (async () => {
      try {
        const response = await geocode.fromLatLng(
          searchedLocation.location.lat + "",
          searchedLocation.location.lng + ""
        );
        if (response.results.length === 0) {
          alert("Could not find any location");
          return;
        }
        const primaryLocation = response.results[0];

        setInputQuery(primaryLocation.formatted_address);
        setSearchedLocation({
          ...searchedLocation,
          formatted_address: primaryLocation.formatted_address
        });
      } catch (error) {
        alert("Could not find any location");
        return;
      }
    })();
  }, [searchedLocation, geocode]);

  useEffect(() => {
    if (
      !editLocation ||
      isEqual(editLocation.location, prevEditLocation?.location)
    )
      return;
    (async () => {
      try {
        const response = await geocode.fromLatLng(
          editLocation.location.lat + "",
          editLocation.location.lng + ""
        );
        if (response.results.length === 0) {
          alert("Could not find any location");
          return;
        }
        const primaryLocation = response.results[0];
        setEditLocation({
          ...editLocation,
          formatted_address: primaryLocation.formatted_address
        });
      } catch (error) {
        alert("Could not find any location");
        return;
      }
    })();
  }, [editLocation, geocode]);

  // useEffect(() => {
  // db.collection("locations")
  //   .get()
  //   .then(snapshot => {
  //     snapshot.docs.forEach(doc => {
  //       if (
  //         doc.data().type.main === "settling" ||
  //         doc.data().type.main === "competition"
  //       ) {
  //         doc.ref.update({
  //           ...doc.data(),
  //           type: {
  //             main: doc.data().type.main,
  //             sub: { ...doc.data().type.sub, materials: [] }
  //           }
  //         });
  //       }
  //     });
  //   });
  // }, []);

  // const getDistance = async (
  //   origin: { lat: number; lng: number; description: string },
  //   dest: { lat: number; lng: number; description: string }
  // ) => {
  //   if (!user) return "0";
  //   try {
  //     if (counter.current > 5000) {
  //       console.warn("sorry, you cant make any more requests to some services");
  //     }
  //     const result: any = await getDistanceMatrix(distService, {
  //       origins: [origin],
  //       destinations: [dest],
  //       travelMode: maps.TravelMode.DRIVING,
  //       unitSystem: maps.UnitSystem.METRIC
  //     });
  //     mp.track("API CALL", {
  //       Project_Name: projectName,
  //       Customer: dest.description,
  //       User: user.email,
  //       Api_Type: "google-distance_matrix",
  //       Date: new Date(),
  //       Month_Year: new Intl.DateTimeFormat("en-GB", {
  //         month: "numeric",
  //         year: "numeric"
  //       }).format(new Date()),
  //       Data_Source: "api"
  //     });
  //     await db.collection("distances").add({
  //       origin,
  //       dest,
  //       distance: (result.rows[0].elements[0].distance.value / 1000).toFixed(1)
  //     });

  //     return (result.rows[0].elements[0].distance.value / 1000).toFixed(1);
  //   } catch (error) {
  //     console.error(error);
  //     return "0";
  //   }
  // };

  const getBatchDistances = useCallback(
    async (
      origin: { lat: number; lng: number; description: string; id: string }[],
      dest: { lat: number; lng: number; description: string; id: string }
    ) => {
      if (!user) return "0";
      try {
        console.log("in getBatchDistances");
        const chunkSize = 25;
        const originChunks = [];
        for (let i = 0; i < origin.length; i += chunkSize) {
          originChunks.push(origin.slice(i, i + chunkSize));
        }
        console.log(originChunks);
        const results: any[] = [];

        for (const chunk of originChunks) {
          await new Promise(resolve => setTimeout(resolve, 200)); // Wait for 0.2s before continuing
          const result = await getDistanceMatrix(distService, {
            origins: chunk.map(c => ({ lat: c.lat, lng: c.lng })),
            destinations: [{ lat: dest.lat, lng: dest.lng }],
            travelMode: maps.TravelMode.DRIVING,
            unitSystem: maps.UnitSystem.METRIC
          });
          console.log("next chunk");
          results.push(result);
        }

        const formatted = results.reduce(
          (acc, curr, idx) => [
            ...acc,
            ...curr.rows.map(
              (row: any, idx2: number) => ({
                distance: (row.elements[0].distance.value / 1000).toFixed(1),
                origin_id: origin[idx * 25 + idx2].id
              }),
              []
            )
          ],
          []
        );

        await Promise.all(
          formatted.map(async (f: any, idx: number) => {
            await db.collection("distances").add({
              origin: origin.find(o => o.id === f.origin_id),
              dest,
              distance: f.distance
            });
          })
        );
        return formatted;
        // return results.reduce((acc, cur) => acc + cur).toFixed(1);
      } catch (error) {
        console.error(error);
        return origin.map(o => ({ distance: "0", origin: o, dest }));
      }
    },
    [distService, maps, user]
  );

  const getShortDistance = async (
    origin: { lat: number; lng: number; description: string; id: string },
    dest: { lat: number; lng: number; description: string; id: string }
  ) => {
    let query = db.collection("distances").where("origin.id", "==", origin.id);
    query = query.where("dest.id", "==", dest.id);
    const snapshot = await query.limit(1).get();
    return snapshot.empty
      ? null
      : snapshot.docs[0].data()["short_distance"]
      ? snapshot.docs[0].data()["short_distance"]
      : null;
  };

  useEffect(() => {
    if (
      distService &&
      selectedCustomerId &&
      allLocations.length &&
      allLocations.some(
        l => l.type.main === "customers" && l.id === selectedCustomerId
      )
    ) {
      const fetchDistances = async () => {
        if (!allLocations.length) return;
        const customers = allLocations.filter(
          l => l.type.main === "customers" && l.id === selectedCustomerId
        );
        if (!customers.length) return;

        const groups: InfoGroup[] = await Promise.all(
          customers
            .filter(c => c.domain === domain)
            .map(async c => {
              const allDistancesOfCustomer = await db
                .collection("distances")
                .where("dest.id", "==", c.id)
                .get()
                .then(sp => {
                  const data: any[] = [];
                  sp.forEach(s => data.push(s.data()));
                  return data;
                });

              let locationsForDistanceNeeded = [];
              for (let i = 0; i < allLocations.length; i++) {
                const l = allLocations[i];
                if (
                  l.type.main !== Category.CUSTOMERS &&
                  l.type.main !== Category.KUNDE &&
                  l.domain === domain
                ) {
                  const location = {
                    ...l.location,
                    id: l.id,
                    description: l.description
                  };
                  if (
                    allDistancesOfCustomer.length < 25 &&
                    !allDistancesOfCustomer.find(
                      d =>
                        d.dest.lng === c.location.lng &&
                        d.dest.lat === c.location.lat &&
                        d.origin.lng === location.lng &&
                        d.origin.lat === location.lat
                    )
                  ) {
                    locationsForDistanceNeeded.push(location);
                  }
                }
              }
              locationsForDistanceNeeded.sort((a, b) => {
                const latDiffA = Math.abs(a.lat - c.location.lat);
                const lngDiffA = Math.abs(a.lng - c.location.lng);
                const latDiffB = Math.abs(b.lat - c.location.lat);
                const lngDiffB = Math.abs(b.lng - c.location.lng);
                return (
                  Math.sqrt(Math.pow(latDiffA, 2) + Math.pow(lngDiffA, 2)) -
                  Math.sqrt(Math.pow(latDiffB, 2) + Math.pow(lngDiffB, 2))
                );
              });
              console.log(locationsForDistanceNeeded);
              locationsForDistanceNeeded = locationsForDistanceNeeded.slice(
                0,
                25
              );
              await getBatchDistances(locationsForDistanceNeeded, {
                ...c.location,
                id: c.id,
                description: c.description
              });
              const updatedDistancesOfCustomer = await db
                .collection("distances")
                .where("dest.id", "==", c.id)
                .get()
                .then(sp => {
                  const data: any[] = [];
                  sp.forEach(s => data.push(s.data()));
                  return data;
                });
              const customerRelatedLocations = allLocations.filter(l => {
                return updatedDistancesOfCustomer.find(d => {
                  return d.origin.id === l.id;
                });
              });
              return {
                group_title: {
                  title: c.description,
                  location: c.location,
                  formatted_address: [
                    c.formatted_address.split(",")[0],
                    c.formatted_address.split(",")[1]
                  ].join()
                },
                distances: await Promise.all(
                  customerRelatedLocations
                    .filter(
                      l =>
                        l.type.main !== Category.CUSTOMERS &&
                        l.domain === domain
                    )
                    .map(async l => ({
                      title: {
                        title: l.description,
                        formatted_address: [
                          l.formatted_address.split(",")[0],
                          l.formatted_address.split(",")[1]
                        ].join()
                      },
                      location: l.location,
                      distance: updatedDistancesOfCustomer.find(
                        d => d.origin.id === l.id
                      ).distance,
                      shortDistance: updatedDistancesOfCustomer.find(
                        d => d.origin.id === l.id
                      ).short_distance
                        ? updatedDistancesOfCustomer.find(
                            d => d.origin.id === l.id
                          ).short_distance
                        : await getShortDistance(
                            {
                              ...l.location,
                              description: l.description,
                              id: l.id
                            },
                            {
                              ...c.location,
                              description: c.description,
                              id: c.id
                            }
                          ),
                      color:
                        l.type.main === Category.SETTLING
                          ? "#0275d8"
                          : l.type.main === Category.COMPETITION
                          ? "#dc3545"
                          : l.type.main === Category.KUNDE
                          ? "#f0ad4e"
                          : "#f0ad4e"
                    }))
                ),
                id: c.id
              };
            })
        );
        setInfoGroups(groups);
        await Promise.all(
          customers
            .filter(c => c.domain === domain)
            .map(async c => {
              const newShortestFiveDistances = await db
                .collection("distances")
                .where("dest.id", "==", c.id)
                .get()
                .then(sp => {
                  const data: any[] = [];
                  sp.forEach(s => data.push(s.data()));

                  return data
                    .sort(
                      (a, b) =>
                        +(a.short_disatnce || a.distance) -
                        +(b.short_disatnce || b.distance)
                    )
                    .map(l => ({
                      location: { lat: l.origin.lat, lng: l.origin.lng },
                      type: allLocations.find(
                        loc => loc.description === l.origin.description
                      )?.type.main,
                      description: l.origin.description,
                      distance: l.short_distance || l.distance
                    }))
                    .filter(l => !!l.type)
                    .filter((_, idx) => idx < 5);
                });

              const subType = (c.type.sub as CustomerSubType) || {};

              const newSubType = {
                ...subType,
                settling: subType.settling.length
                  ? subType.settling.map(s =>
                      db.collection("locations").doc(s.id)
                    )
                  : []
              };

              await db
                .collection("locations")
                .doc(c.id)
                .update({
                  updated_at: new Date().toISOString(),
                  type: {
                    ...c.type,
                    sub: {
                      ...newSubType,
                      nearest_neighbours: [
                        ...newShortestFiveDistances.filter(n => !!n)
                      ]
                    }
                  }
                })
                .then(() => {
                  logAction(
                    FirestoreChangeType.UPDATE,
                    c.id,
                    c.type.main as Category
                  );
                  setAllLocations(
                    prev =>
                      prev.map(l =>
                        l.id === c.id
                          ? {
                              ...l,
                              type: {
                                ...l.type,
                                sub: {
                                  ...(l.type.sub as CustomerSubType),
                                  nearest_neighbours: newShortestFiveDistances.filter(
                                    n => !!n
                                  )
                                }
                              }
                            }
                          : l
                      ) as Location[]
                  );
                });
            })
        );
      };
      fetchDistances();
    } else {
      setInfoGroups([]);
    }
  }, [selectedCustomerId, distService, getBatchDistances]);

  const handleSetActiveCategory = useCallback(
    (category: Category) => {
      setActiveCategory(category);
    },
    [setActiveCategory]
  );

  const handleInputChange = useCallback(
    (value: string) => {
      setInputQuery(value);
    },
    [setInputQuery]
  );

  const handleDeleteLocation = async (id: string) => {
    if (!allLocations.find(l => l.id === id)) return;
    const itemRef = await db.collection("locations").doc(id).get();
    const data = itemRef.data();
    if (!data) return;
    // mp.track("API CALL", {
    //   User: user?.email,
    //   Api_Type: "firebase-locations",
    //   Date: new Date(),
    //   Month_Year: new Intl.DateTimeFormat("en-GB", {
    //     month: "numeric",
    //     year: "numeric"
    //   }).format(new Date()),
    //   Data_Source: "api"
    // });
    let allowDelete = true;
    if (data.type.main !== Category.CUSTOMERS) {
      if (
        allLocations
          .filter(l => l.type.main === Category.CUSTOMERS)
          .some(l => {
            const subType = l.type.sub as CustomerSubType;
            return (
              (subType.kunde && subType.kunde.id === id) ||
              (subType.winner_first_level &&
                subType.winner_first_level.id === id) ||
              (subType.winner_second_level &&
                subType.winner_second_level.id === id) ||
              (subType.settling && subType.settling.some(s => s.id === id))
            );
          })
      ) {
        allowDelete = false;
      }
    }
    if (!allowDelete) {
      let modalBeingShow = true;
      showModal({
        title: "Item kann nicht entfernt werden",
        message:
          "Dieses Item ist mit einer oder mehreren Baustellen verbunden und kann daher nicht entfernt werden. Wollen Sie diesen Standort diaktivieren?",
        confirmText: "Diaktivieren",
        cancelAction: () => {
          modalBeingShow = false;
        },
        confirmAction: async () => {
          const location = allLocations.find(l => l.id === id);
          await db.collection("locations").doc(id).update({
            updated_at: new Date().toISOString(),
            diactivated: true
          });
          logAction(
            FirestoreChangeType.DELETE,
            id,
            (location?.type.main as Category) || Category.COMPETITION
          );
          setAllLocations(prev =>
            prev.map(l => (l.id === id ? { ...l, diactivated: true } : l))
          );
          hideModal();
          modalBeingShow = false;
        }
      });
      while (modalBeingShow) {
        console.log("awaiting");
        await new Promise(resolve => setTimeout(resolve, 500));
      }
      return;
    }
    const coords = data?.location;
    if (coords) {
      const query1 = db
        .collection("distances")
        .where("origin.id", "==", itemRef.id);
      const query2 = db
        .collection("distances")
        .where("dest.id", "==", itemRef.id);
      await Promise.all(
        [query1, query2].map(async query => {
          const snapshot = await query.get();
          if (!snapshot.empty) {
            snapshot.forEach(async doc => {
              await doc.ref.delete();
            });
          }
        })
      );
    }
    await db.collection("locations").doc(id).delete();
    logAction(FirestoreChangeType.DELETE, id, data.type.main as Category);
    if (user && data)
      mp.track("ACTION", {
        Project_Name: projectName,
        User: user.email,
        Action_Type: "delete",
        Customer: data.description,
        Customer_Type: data.type.main,
        Date: new Date(),
        Month_Year: new Intl.DateTimeFormat("en-GB", {
          month: "numeric",
          year: "numeric"
        }).format(new Date())
      });
    setAllLocations(prev => prev.filter(l => l.id !== id));
  };

  // useEffect(() => {
  //   const customers = allLocations.filter(
  //     l => l.type.main === Category.CUSTOMERS
  //   );
  //   console.log(
  //     customers
  //       .filter(l => {
  //         const type = l.type.sub as CustomerSubType;
  //         return (
  //           type.nearest_neighbours.length &&
  //           type.nearest_neighbours.some(n =>
  //             customers.find(c => c.description === n.description)
  //           )
  //         );
  //       })
  //       .map(l => ({
  //         description: l.description,
  //         nearest_neighbours: (l.type.sub as CustomerSubType)
  //           .nearest_neighbours,
  //         "nearest_neighbours-cusomters": (l.type
  //           .sub as CustomerSubType).nearest_neighbours.filter(n =>
  //           customers.find(c => c.description === n.description)
  //         )
  //       }))
  //   );
  // }, [allLocations]);

  const handleSaveLocation = async (
    description: string,
    type: {
      main: string;
      sub:
        | CompetitionSubType
        | SettlingSubType
        | CustomerSubType
        | KundeSubType;
    }
  ) => {
    if (!searchedLocation || !domain) return;
    const newLocation = {
      ...searchedLocation,
      domain,
      description,
      type,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };
    const savedLocation = await db.collection("locations").add(newLocation);
    logAction(
      FirestoreChangeType.CREATE,
      savedLocation.id,
      newLocation.type.main as Category
    );
    if (user)
      mp.track("ACTION", {
        Project_Name: projectName,
        User: user.email,
        Action_Type: "save",
        Customer: newLocation.description,
        Customer_Type: newLocation.type.main,
        Date: new Date(),
        Month_Year: new Intl.DateTimeFormat("en-GB", {
          month: "numeric",
          year: "numeric"
        }).format(new Date())
      });

    const stateLocation = {
      ...newLocation,
      id: savedLocation.id,
      switched: true
    };

    setAllLocations([...allLocations, stateLocation]);
    setShowCreationForm(false);
    setSearchedLocation(null);
    setInputQuery("");
  };

  const cancelCreation = () => {
    setShowCreationForm(false);
    setSearchedLocation(null);
  };

  const handleChangeItem = async (
    itemId: string,
    newDesc: string,
    newType: {
      main: string;
      sub:
        | CompetitionSubType
        | SettlingSubType
        | CustomerSubType
        | KundeSubType;
    },
    newLocation?: Location,
    newFormattedAddress?: string
  ) => {
    try {
      const oldItem = allLocations.find(i => i.id === itemId);
      if (!oldItem) return;

      if (newType.sub && "title" in newType.sub) {
        await db.doc(`locations/${itemId}`).update({
          updated_at: new Date().toISOString(),
          type: { main: newType.main, sub: newType.sub }
        });
        if (
          "color" in newType.sub &&
          "color" in oldItem.type.sub &&
          newType.sub.color !== oldItem.type.sub.color
        ) {
          let query = db
            .collection("locations")
            .where("type.sub.title", "==", newType.sub.title);
          query = query.where("type.main", "==", newType.main);
          const snapshots = await query.get();
          if (snapshots.size > 0) {
            snapshots.forEach(item => {
              item.ref.update({
                updated_at: new Date().toISOString(),
                type: {
                  main: item.data().type.main,
                  sub: {
                    ...item.data().type.sub,
                    color: (newType.sub as
                      | SettlingSubType
                      | CompetitionSubType
                      | KundeSubType).color,
                    __typename: item.data().type.main
                  }
                }
              });
              logAction(
                FirestoreChangeType.UPDATE,
                item.id,
                item.data().type.main as Category
              );
            });
          }
        }
        // await db
        //   .collection("locations")
        //   .where("type.sub.title", "==", newType.sub.title)
        //   .get()
        //   .then(snapshots => {
        //     if (snapshots.size > 0) {
        //       snapshots.forEach(item => {
        //         item.ref.update({
        //           updated_at: new Date().toISOString(),
        //           type: {
        //             main: item.data().type.main,
        //             sub: { ...newType.sub, __typename: item.data().type.main }
        //           }
        //         });
        //         logAction(
        //           FirestoreChangeType.UPDATE,
        //           item.id,
        //           item.data().type.main as Category
        //         );
        //       });
        //     }
        //   });
        if (user)
          mp.track("ACTION", {
            Project_Name: projectName,
            User: user.email,
            Action_Type: "edit",
            Customer: newDesc,
            Customer_Type: newType.main,
            Date: new Date(),
            Month_Year: new Intl.DateTimeFormat("en-GB", {
              month: "numeric",
              year: "numeric"
            }).format(new Date())
          });
      }

      if (newLocation && newFormattedAddress) {
        const { location: oldLocation } = oldItem;
        if (
          oldLocation.lat !== newLocation.location.lat ||
          oldLocation.lng !== newLocation.location.lng
        ) {
          const resp_origin = await db
            .collection("distances")
            .where("origin.id", "==", itemId)
            .get();
          const resp_dest = await db
            .collection("distances")
            .where("dest.id", "==", itemId)
            .get();
          await Promise.all(resp_origin.docs.map(doc => doc.ref.delete()));
          await Promise.all(resp_dest.docs.map(doc => doc.ref.delete()));
          if (newLocation.type.main === Category.CUSTOMERS) {
            const allDistancesOfCustomer = await db
              .collection("distances")
              .where("dest.id", "==", newLocation.id)
              .get()
              .then(sp => {
                const data: any[] = [];
                sp.forEach(s => data.push(s.data()));
                return data;
              });

            let locationsForDistanceNeeded = [];
            for (let i = 0; i < allLocations.length; i++) {
              const l = allLocations[i];
              if (
                l.type.main !== Category.CUSTOMERS &&
                l.type.main !== Category.KUNDE &&
                l.domain === domain
              ) {
                const location = {
                  ...l.location,
                  id: l.id,
                  description: l.description
                };
                if (
                  allDistancesOfCustomer.length < 25 &&
                  !allDistancesOfCustomer.find(
                    d =>
                      d.dest.lng === newLocation.location.lng &&
                      d.dest.lat === newLocation.location.lat &&
                      d.origin.lng === location.lng &&
                      d.origin.lat === location.lat
                  )
                ) {
                  locationsForDistanceNeeded.push(location);
                }
              }
            }
            locationsForDistanceNeeded.sort((a, b) => {
              const latDiffA = Math.abs(a.lat - newLocation.location.lat);
              const lngDiffA = Math.abs(a.lng - newLocation.location.lng);
              const latDiffB = Math.abs(b.lat - newLocation.location.lat);
              const lngDiffB = Math.abs(b.lng - newLocation.location.lng);
              return (
                Math.sqrt(Math.pow(latDiffA, 2) + Math.pow(lngDiffA, 2)) -
                Math.sqrt(Math.pow(latDiffB, 2) + Math.pow(lngDiffB, 2))
              );
            });
            console.log(locationsForDistanceNeeded);
            locationsForDistanceNeeded = locationsForDistanceNeeded.slice(
              0,
              25
            );
            await getBatchDistances(locationsForDistanceNeeded, {
              ...newLocation.location,
              id: newLocation.id,
              description: newLocation.description
            });
            const updatedDistancesOfCustomer = await db
              .collection("distances")
              .where("dest.id", "==", newLocation.id)
              .get()
              .then(sp => {
                const data: any[] = [];
                sp.forEach(s => data.push(s.data()));
                return data;
              });
            const customerRelatedLocations = allLocations.filter(l => {
              return updatedDistancesOfCustomer.find(d => {
                return d.origin.id === l.id;
              });
            });
            const newGroup = {
              group_title: {
                title: newLocation.description,
                location: newLocation.location,
                formatted_address: [
                  newLocation.formatted_address.split(",")[0],
                  newLocation.formatted_address.split(",")[1]
                ].join()
              },
              distances: await Promise.all(
                customerRelatedLocations
                  .filter(
                    l =>
                      l.type.main !== Category.CUSTOMERS && l.domain === domain
                  )
                  .map(async l => ({
                    title: {
                      title: l.description,
                      formatted_address: [
                        l.formatted_address.split(",")[0],
                        l.formatted_address.split(",")[1]
                      ].join()
                    },
                    location: l.location,
                    distance: updatedDistancesOfCustomer.find(
                      d => d.origin.id === l.id
                    ).distance,
                    shortDistance: updatedDistancesOfCustomer.find(
                      d => d.origin.id === l.id
                    ).short_distance
                      ? updatedDistancesOfCustomer.find(
                          d => d.origin.id === l.id
                        ).short_distance
                      : await getShortDistance(
                          {
                            ...l.location,
                            description: l.description,
                            id: l.id
                          },
                          {
                            ...newLocation.location,
                            description: newLocation.description,
                            id: newLocation.id
                          }
                        ),
                    color:
                      l.type.main === Category.SETTLING
                        ? "#0275d8"
                        : l.type.main === Category.COMPETITION
                        ? "#dc3545"
                        : l.type.main === Category.KUNDE
                        ? "#f0ad4e"
                        : "#f0ad4e"
                  }))
              ),
              id: newLocation.id
            };
            const newGroups = infoGroups.map(g => {
              if (g.id === newLocation.id) {
                return newGroup;
              } else {
                return g;
              }
            });
            setInfoGroups(newGroups);
          }
          // delete old distances

          // const query_origin = db
          //   .collection("distances")
          //   .where("origin.id", "==", oldItem.id);
          // const query_dest = db
          //   .collection("distances")
          //   .where("dest.id", "==", oldItem.id);
          // const snapshot_origin = await query_origin.get();
          // const snapshot_dest = await query_dest.get();
          // const dataWithIdsOrigin = snapshot_origin.docs.map(doc => ({
          //   id: doc.id,
          //   data: doc.data()
          // }));
          // for (const doc of dataWithIdsOrigin) {
          //   const { data, id } = doc;
          //   const result: any = await getDistanceMatrix(distService, {
          //     origins: [newLocation.location],
          //     destinations: [data.dest],
          //     travelMode: maps.TravelMode.DRIVING,
          //     unitSystem: maps.UnitSystem.METRIC
          //   });
          //   const request = {
          //     origin: newLocation.location,
          //     destination: data.dest,
          //     travelMode: "DRIVING",
          //     provideRouteAlternatives: true
          //   };
          //   const { shortestPath } = await getShortRoute(request);
          //   await db
          //     .collection("distances")
          //     .doc(id)
          //     .update({
          //       origin: {
          //         id: newLocation.id,
          //         description: newDesc,
          //         lat: newLocation.location.lat,
          //         lng: newLocation.location.lng
          //       },
          //       distance: (
          //         result.rows[0].elements[0].distance.value / 1000
          //       ).toFixed(1),
          //       short_distance: shortestPath
          //     });
          //   await new Promise(resolve => setTimeout(resolve, 500));
          // }
          // const dataWithIdsDest: any[] = [];
          // snapshot_dest.forEach(doc => {
          //   dataWithIdsDest.push({ id: doc.id, data: doc.data() });
          // });
          // console.log(dataWithIdsDest.map(d => ({ ...d.data.origin })));
          // getBatchDistances(
          //   dataWithIdsDest.map(d => ({ ...d.data.origin })),
          //   {
          //     id: newLocation.id,
          //     lat: newLocation.location.lat,
          //     lng: newLocation.location.lat,
          //     description: newDesc
          //   }
          // );
        }
        if (oldItem.description !== newDesc) {
          const query_origin = db
            .collection("distances")
            .where("origin.id", "==", oldItem.id);
          const query_dest = db
            .collection("distances")
            .where("dest.id", "==", oldItem.id);
          const snapshot_origin = await query_origin.get();
          const snapshot_dest = await query_dest.get();
          const {
            location: { lat, lng }
          } = oldItem;
          snapshot_origin.forEach(async doc => {
            await doc.ref.update({
              origin: { description: newDesc, lat, lng }
            });
          });
          snapshot_dest.forEach(async doc => {
            await doc.ref.update({
              dest: { description: newDesc, lat, lng }
            });
          });
        }
      }

      await db
        .collection("locations")
        .doc(itemId)
        .update({
          updated_at: new Date().toISOString(),
          description: newDesc,
          type:
            newType.main === "customers" && "settling" in newType.sub
              ? {
                  ...newType,
                  sub: {
                    ...newType.sub,
                    settling: newType.sub.settling.map(s =>
                      db.collection("locations").doc(s.id)
                    )
                  }
                }
              : newType,
          location: { ...newLocation?.location },
          formatted_address: newFormattedAddress
        });
      logAction(FirestoreChangeType.UPDATE, itemId, newType.main as Category);
      db.collection("locations")
        .doc(itemId)
        .get()
        .then(async doc => {
          const data = doc.data() as Location;
          if (data.type.main === Category.CUSTOMERS) {
            const newShortestFiveDistances = await db
              .collection("distances")
              .where("dest.id", "==", doc.id)
              .get()
              .then(sp => {
                const data: any[] = [];
                sp.forEach(s => data.push(s.data()));
                return data
                  .sort(
                    (a, b) =>
                      +(a.short_disatnce || a.distance) -
                      +(b.short_disatnce || b.distance)
                  )
                  .map(l => ({
                    location: { lat: l.origin.lat, lng: l.origin.lng },
                    type: allLocations.find(
                      loc =>
                        loc.description === l.origin.description &&
                        loc.location.lat === l.origin.lat &&
                        loc.location.lng === l.origin.lng
                    )?.type.main as Category.COMPETITION | Category.SETTLING,
                    description: l.origin.description,
                    distance: l.short_distance || l.distance
                  }))
                  .filter(t => !!t.type)
                  .filter((_, idx) => idx < 5);
              });
            const subType = (data.type.sub as CustomerSubType) || {};
            await db
              .collection("locations")
              .doc(doc.id)
              .update({
                updated_at: new Date().toISOString(),
                type: {
                  ...data.type,
                  sub: {
                    ...subType,
                    nearest_neighbours: [...newShortestFiveDistances]
                  }
                }
              });
            logAction(
              FirestoreChangeType.UPDATE,
              doc.id,
              data.type.main as Category
            );
            return formatFetchedData(
              await db.collection("locations").doc(doc.id).get()
            );
          }
        })
        .then(
          data =>
            data &&
            setAllLocations(prev =>
              prev.map(l => (l.id === data.id ? data : l))
            )
        );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchAddress = async () => {
    if (!inputQuery) return;
    try {
      const response = await geocode.fromAddress(inputQuery);
      if (response.results.length === 0) {
        alert("Could not find any location");
        return;
      }
      const primaryLocation = response.results[0];
      setSearchedLocation({
        location: {
          ...primaryLocation.geometry.location
        },
        formatted_address: primaryLocation.formatted_address
      });
      setShowCreationForm(true);
    } catch (error) {
      alert("Could not find any location");
      return;
    } finally {
      if (!user) return;
      mp.track("API CALL", {
        Project_Name: projectName,
        User: user.email,
        Api_Type: "google-geocode",
        Date: new Date(),
        Month_Year: new Intl.DateTimeFormat("en-GB", {
          month: "numeric",
          year: "numeric"
        }).format(new Date()),
        Data_Source: "api"
      });
    }
  };

  const handleSetCoordsToShowRoute = (
    newCoords: {
      origin: {
        lat: number;
        lng: number;
      };
      destination: {
        lat: number;
        lng: number;
      };
    } | null
  ) => {
    if (coordsToShowRoute && newCoords) {
      const {
        origin: currOrigin,
        destination: currDestination
      } = coordsToShowRoute;
      if (
        currOrigin.lat === newCoords.origin.lat &&
        currOrigin.lng === newCoords.origin.lng &&
        currDestination.lat === newCoords.destination.lat &&
        currDestination.lng === newCoords.destination.lng
      ) {
        setCoordsToShowRoute(null);
        return;
      }
    }
    if (newCoords === null) {
      setCoordsToShowRoute(null);
      return;
    }
    setCoordsToShowRoute({
      origin: newCoords.origin,
      destination: newCoords.destination
    });
  };

  const handleCustomerTableRowClick = useCallback(
    (rowData: RowData) => {
      if (map) {
        map.panTo(rowData.location);
        map.setZoom(12);
      }

      if (selectedCustomerId === (rowData.customer_id as string)) {
        setSelectedCustomerId("");
        setCoordsToShowRoute(null);
        map.setZoom(8);
        return;
      }
      setSelectedLocationId("");
      setSelectedCustomerId(rowData.customer_id as string);
    },
    [map, selectedCustomerId]
  );

  const handleSelectLocation = useCallback(
    (settlingId: string) => {
      const foundSettling = allLocations.find(loc => loc.id === settlingId);
      if (!foundSettling) return;
      if (map) {
        map.panTo(foundSettling.location);
        map.setZoom(9);
      }
      if (selectedLocationId === foundSettling.id) {
        setSelectedLocationId("");
        setSelectedLocationRadius(30);
        map.setZoom(8);
        return;
      }
      setSelectedCustomerId("");
      setSelectedLocationId(foundSettling.id);
    },
    [allLocations, map, selectedLocationId]
  );

  const handleSelectedLocationRadiusChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (isNaN(+e.target.value)) return;
      setSelectedLocationRadius(+e.target.value);
    },
    []
  );

  // useEffect(()=>{
  //   db.collection("locations").get().then((snap)=>{
  //     snap.forEach((doc)=>{
  //       db.collection("locations").doc(doc.id).update({
  //         domain:  "mischanlagen"
  //       })
  //     })
  //   })
  // }, [])
  useEffect(() => {
    if (selectedLocationRadius > selectedLocationRadius2) {
      setSelectedLocationRadius2(selectedLocationRadius);
    }
  }, [selectedLocationRadius2, selectedLocationRadius]);

  const handleSelectedLocationRadius2Change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (isNaN(+e.target.value)) return;
      setSelectedLocationRadius2(
        Math.max(+e.target.value, selectedLocationRadius)
      );
    },
    [selectedLocationRadius]
  );

  // const onRenderCallback = (
  //   id: any, // the "id" prop of the Profiler tree that has just committed
  //   phase: any, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  //   actualDuration: any, // time spent rendering the committed update
  //   baseDuration: any, // estimated time to render the entire subtree without memoization
  //   startTime: any, // when React began rendering this update
  //   commitTime: any, // when React committed this update
  //   interactions: any // the Set of interactions belonging to this update
  // ) => {
  //   console.log({
  //     id,
  //     phase,
  //     actualDuration,
  //     baseDuration,
  //     startTime,
  //     commitTime,
  //     interactions
  //   });
  // };

  return (
    <StyledMain>
      {redirect && <Redirect to={redirect} />}
      <Sidebar
        inputQuery={inputQuery}
        onInputChange={handleInputChange}
        onSearch={handleSearchAddress}
        activeCategory={activeCategory}
        setActiveCategory={handleSetActiveCategory}
        setShowCreationForm={setShowCreationForm}
        showCreationForm={showCreationForm}
        cancelCreation={cancelCreation}
        onSave={handleSaveLocation}
        locations={userLocationsByDomain}
        setLocations={setAllLocations}
        onDelete={handleDeleteLocation}
        onSaveChanges={handleChangeItem}
        customerRadius={customerRadius}
        settlingRadius={settlingRadius}
        selectedCustomerId={selectedCustomerId}
        setSelectedCustomerId={setSelectedCustomerId}
        selectedLocationId={selectedLocationId}
        handleSelectLocation={handleSelectLocation}
        selectedLocationRadius={selectedLocationRadius}
        selectedLocationRadius2={selectedLocationRadius2}
        handleSelectedLocationRadiusChange={handleSelectedLocationRadiusChange}
        handleSelectedLocationRadius2Change={
          handleSelectedLocationRadius2Change
        }
        selectedLocationDateFilters={selectedLocationDateFilters}
        setSelectedLocationDateFilters={setSelectedLocationDateFilters}
        infoGroups={infoGroups}
        handleSetCoordsToShowRoute={handleSetCoordsToShowRoute}
        coordsToShowRoute={coordsToShowRoute}
        editLocation={editLocation}
        setCustomersForMap={setCustomersForMap}
        setEditLocationForMap={(
          location: {
            location: {
              lat: number;
              lng: number;
            };
            formatted_address: string;
          } | null
        ) => setEditLocation(location)}
        handleCustomerTableRowClick={handleCustomerTableRowClick}
        tableView={tableView}
      />
      <MapArea
        center={{
          lat: 51.05,
          lng: 13.73
        }}
        zoom={mapZoom}
        locations={userLocationsByDomain}
        setMapZoom={setMapZoom}
        map={map}
        maps={maps}
        customerRadius={customerRadius}
        setSettlingRadius={setSettlingRadius}
        setCustomerRadius={setCustomerRadius}
        settlingRadius={settlingRadius}
        selectedCustomerId={selectedCustomerId}
        selectedLocationId={selectedLocationId}
        selectedLocationRadius={selectedLocationRadius}
        selectedLocationRadius2={selectedLocationRadius2}
        selectedLocationDateFilters={selectedLocationDateFilters}
        infoGroups={infoGroups}
        searchedLocation={searchedLocation}
        setSearchedLocation={setSearchedLocation}
        editLocation={editLocation}
        customersForMap={customersForMap}
        setEditLocationForMap={(
          location: {
            location: {
              lat: number;
              lng: number;
            };
            formatted_address: string;
          } | null
        ) => setEditLocation(location)}
        groupsToShow={groupsToShow}
        setGroupsToShow={setGroupsToShow}
        activeCategory={activeCategory}
        tableView={tableView}
        setTableView={setTableView}
      />
    </StyledMain>
  );
}

export default Main;
