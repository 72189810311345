import { faLocationDot } from "@fortawesome/pro-solid-svg-icons/faLocationDot";
import { faPen } from "@fortawesome/pro-solid-svg-icons/faPen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEvent } from "react";

type OptionsBarProps = {
  handleOpenEdit: (e: MouseEvent<SVGSVGElement>) => void;
  showSelectIcon: boolean;
  handleSelectLocation: () => void;
  inEditMode: boolean;
};

const OptionsBar: React.FC<OptionsBarProps> = ({
  handleOpenEdit,
  showSelectIcon,
  handleSelectLocation,
  inEditMode
}) => {
  return (
    <>
      {showSelectIcon && (
        <FontAwesomeIcon
          icon={faLocationDot}
          style={{
            position: "absolute",
            right: 40,
            top: "50%",
            transform: "translateY(-50%)",
            fontWeight: 500,
            fontSize: "1.35rem",
            cursor: "pointer"
          }}
          onClick={handleSelectLocation}
        />
      )}
      {inEditMode && (
        <FontAwesomeIcon
          icon={faPen}
          style={{
            position: "absolute",
            right: 10,
            top: "50%",
            transform: "translateY(-50%)",
            fontWeight: 500,
            fontSize: "1rem",
            cursor: "pointer"
          }}
          onClick={handleOpenEdit}
        />
      )}
    </>
  );
};

export default OptionsBar;
