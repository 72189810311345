import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Action } from "../constants/permissions";
import { Domain } from "../types";
import { usePermissions } from "./PermissionsContext";
import { Location, SettlingSubType } from "../types";
import { UserContext } from "./user";
import { Category } from "../Views/Main";
import { db } from "../firebaseConfig";
import { isEqual } from "lodash";

type DomainContextType = {
  domain: string | null;
  allowedDomains: string[];
  allSettlingSubTypes: Location["type"][];
  changeDomain: (domain: string) => void;
};

const DomainContext = createContext<DomainContextType>({
  domain: "",
  allowedDomains: [],
  allSettlingSubTypes: [],
  changeDomain: (domain: string) => {
    return;
  }
});

export const useDomainContext = () => React.useContext(DomainContext);

// create DomainContextProvider
export const DomainContextProvider: React.FC = ({ children }) => {
  const { getUserPermissionsByScope } = usePermissions();
  const [allowedDomains, setAllowedDomains] = useState<string[]>([]);

  const [domain, setDomain] = useState<string | null>(null);
  console.log(domain);
  const changeDomain = useCallback(
    (next: string) => {
      if (domain === next) return;
      setDomain(next);
    },
    [domain]
  );

  const user = useContext(UserContext);
  const [allSettlingSubTypes, setAllSettlingSubTypes] = useState<
    Location["type"][]
  >([]);

  useEffect(() => {
    const perms = getUserPermissionsByScope(
      "Nutzer:innen / Domain",
      Action.READ
    ).map(permission => permission.name);

    if (isEqual(perms, allowedDomains)) return;
    setAllowedDomains(perms);
    setDomain(
      perms.find(p => p === Domain.MISCHANLAGEN)
        ? Domain.MISCHANLAGEN
        : perms.length
        ? perms[0]
        : null
    );
  }, [allowedDomains, getUserPermissionsByScope]);

  useEffect(() => {
    if (!user || !domain) return;
    const types: Location["type"][] = [];
    let query = db.collection("locations").where("domain", "==", domain);
    query = query.where("type.main", "==", Category.SETTLING);
    query
      .get()
      .then(sp =>
        sp.docs
          .map(doc => {
            return (doc.data() as Location).type;
          })
          .forEach(type => {
            const subType = type.sub as SettlingSubType;

            if (
              !types.find(t => {
                return (
                  subType.title === (t.sub as SettlingSubType).title &&
                  type.main === t.main
                );
              })
            ) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              types.push(type);
            }
          })
      )
      .then(() => {
        setAllSettlingSubTypes(
          types
            .filter(t => t.main === Category.SETTLING)
            .filter(type => (type.sub as SettlingSubType).title !== "")
        );
      });
  }, [user, domain]);

  return (
    <DomainContext.Provider
      value={{ domain, changeDomain, allowedDomains, allSettlingSubTypes }}
    >
      {children}
    </DomainContext.Provider>
  );
};
