import firebase from "firebase";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyCe2gnQFuHYd1luZ1JoZaVglZa8s1BTZcU",
  authDomain: "mapdistancekemnaqa.firebaseapp.com",
  projectId: "mapdistancekemnaqa",
  storageBucket: "mapdistancekemnaqa.appspot.com",
  messagingSenderId: "637568721297",
  appId: "1:637568721297:web:2cdf4c637a6cfa8faffa30"
};

firebase.initializeApp(config);

const db = firebase.firestore();
const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();

// if (
//   location.hostname === "localhost" &&
//   process.env.NODE_ENV === "development"
// ) {
//   console.log(true);
//   db.useEmulator("localhost", 8080);
//   auth.useEmulator("http://localhost:9099");
// }

export default firebase;

export { db, auth };
