import { faScrewdriverWrench } from "@fortawesome/pro-regular-svg-icons";
import {
  faBars,
  faCog,
  faLock,
  faPlus
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Action } from "../../constants/permissions";
import { useDomainContext } from "../../context/DomainContext";
import { usePermissions } from "../../context/PermissionsContext";
import { UserContext } from "../../context/user";
import { Domain, SettingsView } from "../../types";
import { Category, TableSize } from "../../Views/Main";
import Select from "react-select";
import { logOut } from "../../services/auth";
import { useModal } from "../../context/ModalContext";

type HeaderProps = {
  companyName: string;
  activeCategory: string;
  tableView: TableSize;
  handleSetActiveSettingsView: (value: SettingsView | null) => void;
};

function upperCaseFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const DomainSelect = () => {
  const { domain, changeDomain, allowedDomains } = useDomainContext();
  if (!domain) return null;

  return (
    <Select
      options={[
        { label: "Mischanlagen", value: Domain.MISCHANLAGEN },
        { label: "Baubetriebe", value: Domain.BAUBETRIEBE },
        { label: "Rohstoffbetriebe", value: Domain.ROHSTOFFBETRIEBE }
      ].filter(option => allowedDomains.includes(option.value))}
      value={{
        label: upperCaseFirstLetter(domain),
        value: domain
      }}
      isSearchable={false}
      onChange={option => (option ? changeDomain(option.value) : null)}
      components={{ IndicatorSeparator: () => null }}
      styles={{
        container: (provided, state) => ({
          ...provided,
          width: "200px"
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: "#EBEBEB"
        }),
        control: (provided, state) => ({
          ...provided,
          backgroundColor: "transparent",
          fontSize: "1.2rem",
          border: "none"
        }),
        input: (provided, state) => ({
          ...provided,
          color: "#EBEBEB",
          fontSize: "1.2rem",
          border: "none"
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "white",
          fontSize: "1.2rem",
          border: "none"
        }),
        placeholder: (provided, state) => ({
          ...provided,
          color: "white",
          fontSize: "1.2rem",
          border: "none"
        })
      }}
    />
  );
};

const Header = ({
  companyName,
  activeCategory,
  tableView,
  handleSetActiveSettingsView
}: HeaderProps) => {
  const [_, setShowMenu] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const { showModal } = useModal();

  const [allowedSettings, setAllowedSettings] = useState([
    { label: "Berechtigungen", value: SettingsView.PERMISSIONS, icon: faLock },
    {
      label: "Materialen",
      value: SettingsView.MATERIALS,
      icon: faScrewdriverWrench
    }
  ]);
  const {
    getUserPermissionsByScope,
    getUserPermission,
    sitePermissions
  } = usePermissions();

  const allowCreateCustomer = getUserPermission(
    "Nutzer:innen / Module",
    "Baustellen",
    Action.WRITE
  );

  useEffect(() => {
    if (!sitePermissions.length) return;

    const permissionsViewSettings = getUserPermission(
      "Nutzer:innen / Module",
      "Berechtigung",
      Action.READ
    );
    setAllowedSettings(prev =>
      prev.filter(view =>
        view.label === "Berechtigungen" ? permissionsViewSettings : true
      )
    );
  }, [getUserPermission, getUserPermissionsByScope, sitePermissions]);

  const handleShowPermissions = () => {
    setShowMenu(false);
    setShowSettings(!showSettings);
  };

  return (
    <div
      className="position-relative bg-secondary d-flex align-items-center justify-content-stretch px-3"
      style={{
        height: 65,
        width: "100vw",
        zIndex: 10000,
        gap: 20
      }}
    >
      <div className="d-flex align-items-end position-relative">
        <span
          className="text-light mr-1"
          style={{
            lineHeight: 0.8,
            fontSize: "calc(1.5rem + .5vw)",
            userSelect: "none"
          }}
        >
          {companyName}
        </span>
        <span
          className="text-light"
          style={{ fontSize: "0.7rem", lineHeight: 0.8 }}
        >
          powered by solongo
        </span>
      </div>
      <div className="d-flex align-items-center ml-5">
        <DomainSelect />
      </div>
      <div
        className="d-flex"
        style={{ marginBottom: -20, flex: 1, justifyContent: "flex-end" }}
      >
        {tableView === TableSize.SMALL ||
        activeCategory !== Category.CUSTOMERS ? (
          <div
            className="btn btn-dark btn-sm p-0 d-flex justify-content-center align-items-center shadow"
            style={{
              height: 30,
              width: 30
            }}
            title="Berechtigungen verwalten"
            onClick={handleShowPermissions}
          >
            <FontAwesomeIcon icon={faCog} />
          </div>
        ) : (
          <div className="d-flex">
            <button
              className="btn btn-dark btn-sm shadow"
              onClick={handleShowPermissions}
            >
              Einstellungen
            </button>
            <button
              className="btn btn-danger btn-sm shadow ml-2"
              onClick={() => {
                logOut().then(() => {
                  showModal({
                    message: "Du bist jetzt abgemeldet",
                    type: "warning"
                  });
                });
              }}
            >
              Logout
            </button>
          </div>
        )}
      </div>
      {showSettings && (
        <ul
          className="position-absolute bg-light"
          style={{
            top: "100%",
            right: 0,
            zIndex: 3000,
            maxHeight: 180,
            overflowY: "scroll",
            border: "1px solid #00000066"
          }}
        >
          {allowedSettings.map(({ label, value, icon }, idx) => {
            return (
              <li
                key={idx}
                style={{
                  cursor: "pointer",
                  fontSize: "1.1rem"
                }}
                className="list-group-item list-group-item-action py-1 px-3 d-flex border-bottom align-items-center"
                onClick={() => {
                  handleSetActiveSettingsView(value);
                  setShowSettings(false);
                }}
              >
                <FontAwesomeIcon icon={icon} />
                <span className="ml-2">{label}</span>
              </li>
            );
          })}
          <li
            style={{
              cursor: "pointer",
              fontSize: "1.1rem"
            }}
            className="list-group-item list-group-item-action py-1 px-3 d-flex border-bottom align-items-center"
          >
            <a href="https://mapdistanceapi.web.app/" target="__blank">
              Api Explorer
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
