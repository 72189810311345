import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { DropdownItem } from ".";
import {
  CompetitionSubType,
  CustomerSubType,
  KundeSubType,
  SettlingSubType
} from "../../types";
import { Category } from "../../Views/Main";
import Select from "react-select";
import { Material, useMaterials } from "../../context/MaterialsContext";
import chroma from "chroma-js";
import { db } from "../../firebaseConfig";

type CreationFormProps = {
  apiReady: boolean;
  description: string;
  setDescription: (value: string) => void;
  type: string;
  setType: (value: string) => void;
  handleSetType: (type: Category) => void;
  companyName: string;
  subType:
    | CompetitionSubType
    | SettlingSubType
    | CustomerSubType
    | KundeSubType
    | null;
  setSubType: (
    value: React.SetStateAction<
      | CompetitionSubType
      | SettlingSubType
      | CustomerSubType
      | KundeSubType
      | null
    >
  ) => void;
  allSubTypes: {
    main: string;
    sub:
      | {
          title: string;
          color: string;
        }
      | string;
  }[];
  settlingSubTypes: {
    main: string;
    sub:
      | {
          title: string;
          color: string;
        }
      | string;
  }[];
  competitionSubTypes: {
    main: string;
    sub:
      | {
          title: string;
          color: string;
        }
      | string;
  }[];
  kundenSubTypes: {
    main: string;
    sub: KundeSubType;
  }[];
  allowColorSelect: boolean;
  cancelCreation: () => void;
  handleSave: () => void;
};

export const getSettlingType2DependingOnType1 = (type1: string): string[] => {
  switch (type1) {
    case "Rohstoffbetrieb":
      return ["Split", "Mineralgemisch", "Kieswerk"];
    default:
      return [];
  }
};

const CreationForm: React.FC<CreationFormProps> = ({
  apiReady,
  description,
  setDescription,
  type,
  setType,
  subType,
  setSubType,
  allSubTypes,
  settlingSubTypes,
  competitionSubTypes,
  kundenSubTypes,
  allowColorSelect,
  cancelCreation,
  handleSave
}) => {
  const [showTypeDropDown, setShowTypeDropDown] = useState(false);
  const [showType1DropDown, setShowType1DropDown] = useState(false);
  const [showType2DropDown, setShowType2DropDown] = useState(false);

  const { materials } = useMaterials();

  useEffect(() => {
    console.log({
      apiReady,
      description,
      setDescription,
      type,
      setType,
      subType,
      setSubType,
      allSubTypes,
      settlingSubTypes,
      competitionSubTypes,
      kundenSubTypes,
      allowColorSelect,
      cancelCreation,
      handleSave
    });
  }, []);

  const filteredSubtypes = useMemo(
    () =>
      allSubTypes.filter(t => {
        if (subType && typeof t.sub === "string" && "title" in subType) {
          return t.sub.includes(subType.title);
        } else if (
          subType &&
          typeof t.sub === "object" &&
          "title" in subType &&
          "title" in t.sub
        ) {
          return t.sub.title.includes(subType.title);
        }
      }),
    [allSubTypes, subType]
  );

  useEffect(() => {
    if (
      subType &&
      "title" in subType &&
      subType.title &&
      filteredSubtypes.length
    ) {
      setShowTypeDropDown(true);
    } else setShowTypeDropDown(false);
  }, [filteredSubtypes.length, subType]);

  const filteredCompetitionSubTypes = useMemo(
    () =>
      competitionSubTypes.filter(t => {
        if (subType && typeof t.sub === "string" && "title" in subType) {
          return t.sub.includes(subType.title);
        } else if (
          subType &&
          typeof t.sub === "object" &&
          "title" in subType &&
          "title" in t.sub
        ) {
          return t.sub.title.includes(subType.title);
        }
      }),
    [competitionSubTypes, subType]
  );

  const filteredSettlingSubTypes = useMemo(
    () =>
      settlingSubTypes.filter(t => {
        if (subType && typeof t.sub === "string" && "title" in subType) {
          return t.sub.includes(subType.title);
        } else if (
          subType &&
          typeof t.sub === "object" &&
          "title" in subType &&
          "title" in t.sub
        ) {
          return t.sub.title.includes(subType.title);
        }
      }),
    [settlingSubTypes, subType]
  );

  const filteredKundenSubTypes = useMemo(
    () =>
      kundenSubTypes.filter(t => {
        console.log({ t, subType });
        if (
          subType &&
          typeof t.sub === "object" &&
          "title" in subType &&
          "title" in t.sub
        ) {
          return t.sub.title
            .toLowerCase()
            .includes(subType.title.toLowerCase());
        }
      }),
    [kundenSubTypes, subType]
  );

  return (
    <div className="w-100 mt-2">
      <div style={{ height: 35 }}>
        <input
          className="form-control form-control-sm w-100 h-100 pr-5"
          type="text"
          disabled={!apiReady}
          placeholder="Bezeichnung eingeben"
          value={description}
          onChange={e => setDescription(e.target.value)}
          aria-label="Search"
        />
      </div>
      <div className="mt-2 d-flex flex-column">
        {!!(
          type === Category.SETTLING &&
          subType &&
          "title" in subType &&
          "color" in subType &&
          "type1" in subType
        ) && (
          <>
            <div className="w-75 position-relative">
              <input
                className="w-100 form-control"
                style={{ height: 35 }}
                placeholder="Kategorie"
                type="text"
                value={subType.title}
                onChange={e =>
                  setSubType({
                    ...subType,
                    title: e.target.value,
                    color: ""
                  })
                }
              />
              <FontAwesomeIcon
                icon={showTypeDropDown ? faChevronUp : faChevronDown}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer"
                }}
                onClick={() => setShowTypeDropDown(!showTypeDropDown)}
              />
              {showTypeDropDown && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: 100,
                    maxHeight: 300,
                    overflowY: "scroll",
                    left: 0,
                    right: 0
                  }}
                >
                  {filteredSettlingSubTypes.map((it, index) =>
                    it.main === Category.SETTLING && it.sub ? (
                      <DropdownItem
                        key={index}
                        className="p-1 bg-white border-bottom border-right border-left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (typeof it.sub === "string") {
                            const title = it.sub;
                            setSubType({
                              ...subType,
                              title: title,
                              color: "#dc3545"
                            });
                          } else {
                            const item = it.sub as {
                              title: string;
                              color: string;
                            };
                            setSubType({
                              ...subType,
                              title: item.title,
                              color: item.color
                            });
                          }
                          setImmediate(() => setShowTypeDropDown(false));
                        }}
                      >
                        {typeof it.sub === "string" ? it.sub : it.sub.title}
                      </DropdownItem>
                    ) : null
                  )}
                </div>
              )}
            </div>
            {allowColorSelect && (
              <div className="mt-1 w-75 position-relative">
                <input
                  type="text"
                  className="w-100 form-control"
                  value={subType.color}
                  placeholder="Farbe wählen"
                  style={{ height: 35 }}
                />
                <input
                  type="color"
                  className="position-absolute"
                  placeholder="Choose color"
                  value={subType.color}
                  style={{
                    height: 35,
                    width: 35,
                    right: 0,
                    top: 0,
                    cursor: "pointer"
                  }}
                  onChange={e =>
                    setSubType({
                      ...subType,
                      title: subType.title,
                      color: e.target.value
                    })
                  }
                />
              </div>
            )}
            <div className="w-100 d-flex mt-1" style={{ gap: 10 }}>
              <div className="col-5 position-relative p-0">
                <input
                  className="form-control"
                  style={{ height: 35 }}
                  placeholder="Art1"
                  type="text"
                  value={subType.type1}
                  readOnly
                />
                <FontAwesomeIcon
                  icon={showType1DropDown ? faChevronUp : faChevronDown}
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    cursor: "pointer"
                  }}
                  onClick={() => setShowType1DropDown(!showType1DropDown)}
                />
                {showType1DropDown && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: 100,
                      maxHeight: 300,
                      overflowY: "scroll",
                      left: 0,
                      right: 0
                    }}
                  >
                    {["Mischwerk", "Rohstoffbetrieb", "Baubetrieb"].map(
                      (it, index) => (
                        <DropdownItem
                          key={index}
                          className="p-1 bg-white border-bottom border-right border-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const type1 = it;
                            let type2 = subType.type2;
                            if (type1 !== subType.type1) {
                              type2 = "";
                            }
                            setSubType({ ...subType, type1, type2 });
                            setImmediate(() => setShowType1DropDown(false));
                          }}
                        >
                          {it}
                        </DropdownItem>
                      )
                    )}
                  </div>
                )}
              </div>

              <div className="col-5 position-relative p-0">
                <input
                  className="form-control"
                  style={{ height: 35 }}
                  placeholder="Art2"
                  type="text"
                  value={subType.type2}
                  readOnly
                />
                <FontAwesomeIcon
                  icon={showType2DropDown ? faChevronUp : faChevronDown}
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    cursor: "pointer"
                  }}
                  onClick={() => setShowType2DropDown(!showType2DropDown)}
                />
                {showType2DropDown && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: 100,
                      maxHeight: 300,
                      overflowY: "scroll",
                      left: 0,
                      right: 0
                    }}
                  >
                    {getSettlingType2DependingOnType1(subType.type1).map(
                      (it, index) => (
                        <DropdownItem
                          key={index}
                          className="p-1 bg-white border-bottom border-right border-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const type2 = it;

                            setSubType({ ...subType, type2 });
                            setImmediate(() => setShowType2DropDown(false));
                          }}
                        >
                          {it}
                        </DropdownItem>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="mt-1 w-50 position-relative">
              <label>Kifa-ID</label>
              <input
                type="text"
                className="w-100 form-control"
                value={subType.kifaId || ""}
                onChange={e => {
                  setSubType({ ...subType, kifaId: e.target.value });
                }}
                placeholder="Kifa-ID"
                style={{ height: 35 }}
              />
            </div>
          </>
        )}
        {!!(
          type === Category.KUNDE &&
          subType &&
          "tonnage" in subType &&
          "title" in subType &&
          "color" in subType
        ) && (
          <>
            <div className="w-75 position-relative mb-2">
              <label>
                Tonnage
                <input
                  className="w-100 form-control"
                  style={{ height: 35 }}
                  placeholder="Tonnage"
                  type="number"
                  step={1}
                  value={subType.tonnage}
                  onChange={e =>
                    setSubType({
                      ...subType,
                      tonnage: +e.target.value
                    })
                  }
                />
              </label>
            </div>
            <div className="w-75 position-relative">
              <input
                className="w-100 form-control"
                style={{ height: 35 }}
                placeholder="Kategorie"
                type="text"
                value={subType.title}
                onChange={e =>
                  setSubType({
                    ...subType,
                    title: e.target.value
                  })
                }
              />
              <FontAwesomeIcon
                icon={showTypeDropDown ? faChevronUp : faChevronDown}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer"
                }}
                onClick={() => setShowTypeDropDown(!showTypeDropDown)}
              />
              {showTypeDropDown && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: 100,
                    maxHeight: 300,
                    overflowY: "scroll",
                    left: 0,
                    right: 0
                  }}
                >
                  {filteredKundenSubTypes.map((it, index) =>
                    it.main === Category.KUNDE && it.sub ? (
                      <DropdownItem
                        key={index}
                        className="p-1 bg-white border-bottom border-right border-left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (typeof it.sub === "string") {
                            const title = it.sub;
                            setSubType({
                              ...subType,
                              title: title
                            });
                          } else {
                            const item = it.sub;
                            setSubType({
                              ...subType,
                              title: item.title
                            });
                          }
                          setImmediate(() => setShowTypeDropDown(false));
                        }}
                      >
                        {typeof it.sub === "string" ? it.sub : it.sub.title}
                      </DropdownItem>
                    ) : null
                  )}
                </div>
              )}
            </div>
            {allowColorSelect && (
              <div className="mt-1 w-75 position-relative">
                <input
                  type="text"
                  className="w-100 form-control"
                  value={subType.color}
                  placeholder="Farbe wählen"
                  style={{ height: 35 }}
                />
                <input
                  type="color"
                  className="position-absolute"
                  placeholder="Choose color"
                  value={subType.color}
                  style={{
                    height: 35,
                    width: 35,
                    right: 0,
                    top: 0,
                    cursor: "pointer"
                  }}
                  onChange={e =>
                    setSubType({
                      ...subType,
                      title: subType.title,
                      color: e.target.value
                    })
                  }
                />
              </div>
            )}
          </>
        )}
        {!!(
          type === Category.COMPETITION &&
          subType &&
          "title" in subType &&
          "color" in subType &&
          "kifaId" in subType
        ) && (
          <>
            <div className="w-75 position-relative">
              <input
                className="w-100 form-control"
                style={{ height: 35 }}
                placeholder="Kategorie"
                type="text"
                value={subType.title}
                onChange={e =>
                  setSubType({
                    ...subType,
                    title: e.target.value,
                    color: ""
                  })
                }
              />
              <FontAwesomeIcon
                icon={showTypeDropDown ? faChevronUp : faChevronDown}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  cursor: "pointer"
                }}
                onClick={() => setShowTypeDropDown(!showTypeDropDown)}
              />
              {showTypeDropDown && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    zIndex: 100,
                    maxHeight: 300,
                    overflowY: "scroll",
                    left: 0,
                    right: 0
                  }}
                >
                  {filteredCompetitionSubTypes.map((it, index) =>
                    it.main === Category.COMPETITION && it.sub ? (
                      <DropdownItem
                        key={index}
                        className="p-1 bg-white border-bottom border-right border-left"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (typeof it.sub === "string") {
                            const title = it.sub;
                            setSubType({
                              ...subType,
                              title: title,
                              color: "#dc3545"
                            });
                          } else {
                            const item = it.sub as {
                              title: string;
                              color: string;
                            };
                            setSubType({
                              ...subType,
                              title: item.title,
                              color: item.color
                            });
                          }
                          setImmediate(() => setShowTypeDropDown(false));
                        }}
                      >
                        {typeof it.sub === "string" ? it.sub : it.sub.title}
                      </DropdownItem>
                    ) : null
                  )}
                </div>
              )}
            </div>
            {allowColorSelect && (
              <div className="mt-1 w-75 position-relative">
                <input
                  type="text"
                  className="w-100 form-control"
                  value={subType.color}
                  placeholder="Farbe wählen"
                  style={{ height: 35 }}
                />
                <input
                  type="color"
                  className="position-absolute"
                  placeholder="Choose color"
                  value={subType.color}
                  style={{
                    height: 35,
                    width: 35,
                    right: 0,
                    top: 0,
                    cursor: "pointer"
                  }}
                  onChange={e =>
                    setSubType({
                      ...subType,
                      title: subType.title,
                      color: e.target.value
                    })
                  }
                />
              </div>
            )}
            <div className="mt-1 w-50 position-relative">
              <label>Kifa-ID</label>
              <input
                type="text"
                className="w-100 form-control"
                value={subType.kifaId || ""}
                onChange={e => {
                  setSubType({ ...subType, kifaId: e.target.value });
                }}
                placeholder="Kifa-ID"
                style={{ height: 35 }}
              />
            </div>
          </>
        )}
      </div>
      <div className="form-group mb-0">
        <label htmlFor="exampleInputEmail1">Materialen</label>
        {!!(
          subType &&
          (type === Category.COMPETITION ||
            type === Category.SETTLING ||
            type === Category.KUNDE)
        ) && (
          <Select
            isMulti
            name="materials"
            options={materials.map(val => ({
              value: val,
              label: val.name
            }))}
            defaultValue={(subType as
              | CompetitionSubType
              | SettlingSubType).materials
              .map(val => materials.find(it => it.id === val.id))
              .map(val => ({
                value: val as Material,
                label: val?.name
              }))}
            maxMenuHeight={100}
            styles={{
              menu: styles => ({ ...styles, zIndex: 100 }),
              multiValue: (styles, { data }) => ({
                ...styles,
                backgroundColor: chroma(data.value.color).alpha(0.1).css()
              }),
              multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: data.value.color
              })
            }}
            onChange={val => {
              setSubType({
                ...(subType as CompetitionSubType | SettlingSubType),
                materials: val.map(it =>
                  db.collection("materials").doc(it.value.id)
                )
              });
            }}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        )}
      </div>

      <div className="d-flex">
        <button
          className="btn btn-outline-danger w-50 mt-2 mr-1"
          onClick={() => {
            setDescription("");
            setSubType({
              title: "",
              color: "",
              __typename: Category.SETTLING,
              materials: [],
              kifaId: null,
              type1: "",
              type2: ""
            });
            setType(Category.SETTLING);
            cancelCreation();
          }}
        >
          Abbrechen
        </button>
        <button
          className="btn btn-outline-primary w-50 mt-2 ml-1"
          onClick={handleSave}
        >
          Speichern
        </button>
      </div>
    </div>
  );
};

export default CreationForm;
