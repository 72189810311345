import { throttle } from "lodash";
import React, { useRef, useState, CSSProperties } from "react";

type Position = {
  x: number;
  y: number;
};

type InitialPostion = {
  x: number | string;
  y: number | string;
};

type DraggableProps = {
  initialPosition: InitialPostion;
  bounds?: {
    minX: string | number;
    maxX: string | number;
    minY: string | number;
    maxY: string | number;
  };
};

const getValWithUnit = (value: number | string) => {
  if (typeof value === "number") {
    return value + "px";
  }
  return value;
};

const Draggable: React.FC<DraggableProps> = ({
  initialPosition,
  bounds,
  children
}) => {
  const [dragging, setDragging] = useState(false);
  const [startCursorPos, setStartCursorPos] = useState<Position>({
    x: 0,
    y: 0
  });
  const [elemPos, setElemPos] = useState<Position | InitialPostion>(
    initialPosition || { x: 0, y: 0 }
  );
  const dragRef = useRef<any>(null);

  const handleDragStart = (e: React.MouseEvent<HTMLElement>) => {
    setDragging(true);
    setElemPos({
      x: dragRef.current?.offsetLeft || 0,
      y: dragRef.current?.offsetTop || 0
    });
    setStartCursorPos({ x: e.clientX, y: e.clientY });
  };

  const handleDrag = throttle((e: React.MouseEvent<HTMLElement>) => {
    if (!dragging || !dragRef.current) return;
    e.preventDefault();
    setStartCursorPos({ x: e.clientX, y: e.clientY });
    const newCursor = {
      x: startCursorPos.x - e.clientX,
      y: startCursorPos.y - e.clientY
    };
    setElemPos({
      x: dragRef.current.offsetLeft - newCursor.x,
      y: dragRef.current.offsetTop - newCursor.y
    });
  }, 100);

  const handleDragEnd = (e: React.MouseEvent<HTMLElement>) => {
    setDragging(false);
  };

  const elemStyle: CSSProperties = {
    position: "absolute",
    zIndex: 10000,
    top: bounds
      ? `clamp(${bounds.minY}, ${getValWithUnit(elemPos.y)}, ${bounds.maxY})`
      : getValWithUnit(elemPos.y),
    left: bounds
      ? `clamp(${bounds.minX}, ${getValWithUnit(elemPos.x)}, ${bounds.maxX})`
      : getValWithUnit(elemPos.x),
    cursor: "move"
  };

  return (
    <div
      onMouseDown={handleDragStart}
      onMouseMove={handleDrag}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
      ref={dragRef}
      style={elemStyle}
    >
      {children}
    </div>
  );
};

export default Draggable;
