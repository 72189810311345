/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import Autocomplete from "react-google-autocomplete";

import "./autocomplete.css";

type AutocompleteProps = JSX.IntrinsicElements["input"] & {
  onPlaceSelected: (place: any) => void;
};

const AutocompleteWrapper: React.FC<AutocompleteProps> = ({
  onPlaceSelected,
  className,
  style,
  ...rest
}) => {
  return (
    <Autocomplete
      apiKey={"AIzaSyAM8Ciwgzts5Dp9I8_DEYJN3oTWjAsz3rE"}
      style={style}
      className={className}
      onPlaceSelected={place => {
        onPlaceSelected(place);
      }}
      options={{
        types: ["geocode"],
        componentRestrictions: { country: "de" },
        fields: ["formatted_address", "geometry"]
      }}
      {...rest}
    />
  );
};

export default AutocompleteWrapper;
