import {
  faRedo,
  faCheck,
  faTimes,
  faChevronRight,
  faClose
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { throttle } from "lodash";
import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import { Location } from "../../types";
import { Category } from "../../Views/Main";

type LocationRadiusSelectionProps = {
  item: Location;
  handleSelectLocation?: (locationId: string) => void;
  selectedLocationRadius?: number;
  selectedLocationRadius2?: number;
  selectedLocationDates?: {
    from?: string;
    to?: string;
  };
  updateSelectedLocationDates?: (nextFilters?: {
    from?: string;
    to?: string;
  }) => void;
  handleSelectedLocationRadiusChange?: (
    e: ChangeEvent<HTMLInputElement>
  ) => void;
  handleSelectedLocationRadius2Change?: (
    e: ChangeEvent<HTMLInputElement>
  ) => void;
};

const LocationRadiusSelection = ({
  item,
  selectedLocationDates,
  selectedLocationRadius2,
  updateSelectedLocationDates,
  handleSelectLocation,
  selectedLocationRadius,
  handleSelectedLocationRadiusChange,
  handleSelectedLocationRadius2Change
}: LocationRadiusSelectionProps) => {
  const [localLocationradius, setLocalLocationRadius] = useState(
    selectedLocationRadius
  );
  const [localLocationradius2, setLocalLocationRadius2] = useState(
    selectedLocationRadius2
  );
  const [selectedLocationDateFilter, setSelectedLocationDateFilter] = useState(
    selectedLocationDates
  );
  const fromRef = useRef<HTMLInputElement>(null);
  const toRef = useRef<HTMLInputElement>(null);
  const resetFilters = () => {
    if (updateSelectedLocationDates) {
      updateSelectedLocationDates(undefined);
    }
    setSelectedLocationDateFilter(undefined);
    if (toRef.current && fromRef.current) {
      toRef.current.value = "";
      fromRef.current.value = "";
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLocationRadiusChange = useCallback(
    throttle((e: React.ChangeEvent<HTMLInputElement>) => {
      if (handleSelectedLocationRadiusChange) {
        handleSelectedLocationRadiusChange(e);
      }
    }, 100),
    [handleSelectedLocationRadiusChange]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLocationRadius2Change = useCallback(
    throttle((e: React.ChangeEvent<HTMLInputElement>) => {
      if (handleSelectedLocationRadius2Change) {
        handleSelectedLocationRadius2Change(e);
      }
    }, 100),
    [handleSelectedLocationRadius2Change]
  );

  const isNotCustomer =
    item.type.main === Category.SETTLING ||
    item.type.main === Category.COMPETITION ||
    item.type.main == Category.KUNDE;

  const onLocationSelect = useCallback(() => {
    if (!isNotCustomer || !handleSelectLocation) return;
    handleSelectLocation(item.id);
  }, [handleSelectLocation, isNotCustomer, item.id]);
  return (
    <div className="w-100">
      <div
        className="w-100 d-flex p-2 align-items-center position-relative"
        style={{
          gap: 20,
          borderTop: "1px solid #999999",
          borderBottom: "1px solid #999999"
        }}
      >
        <FontAwesomeIcon
          icon={faClose}
          className="position-absolute"
          onClick={onLocationSelect}
          style={{
            top: "50%",
            right: 10,
            transform: "translateY(-50%)",
            fontSize: "1.2rem",
            cursor: "pointer"
          }}
        />
        <h5 className="mb-0">{item.description}</h5>
        <FontAwesomeIcon icon={faChevronRight} />
        <span style={{ fontSize: "1.1rem" }}>Umkreise</span>
      </div>
      <div className="d-flex p-2" style={{ gap: 25 }}>
        <div style={{ flex: 1 }}>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Start</label>
            <input
              type="date"
              onClick={e => {
                e.preventDefault();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                e.nativeEvent.target?.showPicker();
              }}
              className="form-control form-control form-control-sm"
              ref={fromRef}
              value={selectedLocationDateFilter?.from}
              onChange={e => {
                setSelectedLocationDateFilter(prev => ({
                  ...prev,
                  from: e.target.value
                }));
                updateSelectedLocationDates?.({
                  to: selectedLocationDates?.to,
                  from: e.target.value
                });
              }}
            />
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Ende</label>
            <input
              type="date"
              onClick={e => {
                e.preventDefault();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                e.nativeEvent.target?.showPicker();
              }}
              className="form-control form-control form-control-sm"
              ref={toRef}
              value={selectedLocationDateFilter?.to}
              onChange={e => {
                setSelectedLocationDateFilter(prev => ({
                  ...prev,
                  to: e.target.value
                }));
                updateSelectedLocationDates?.({
                  from: selectedLocationDates?.from,
                  to: e.target.value
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="p-2">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div style={{ fontSize: "1.1rem", lineHeight: 0.8 }}>Umkreis 1</div>
            <span style={{ fontSize: "0.8rem" }}>
              Baustellen um die Niederlassung
            </span>
          </div>
          <div className="w-25">
            <div className="form-group">
              <label
                htmlFor="exampleFormControlInput1"
                style={{ fontSize: ".9rem" }}
              >
                Radius (Km)
              </label>
              <input
                type="number"
                style={{ width: 60, padding: "0 5px" }}
                min={0}
                step={5}
                className="form-control form-control-sm"
                placeholder="Umkreis Niederlassung"
                value={localLocationradius}
                onChange={e => {
                  e.persist();
                  setLocalLocationRadius(+e.target.value);
                  handleLocationRadiusChange(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div style={{ fontSize: "1.1rem", lineHeight: 0.8 }}>Umkreis 2</div>
            <span style={{ fontSize: "0.8rem" }}>
              Konkurrenz und weitere Niederlassungen
            </span>
          </div>
          <div className="w-25">
            <div className="form-group">
              <label
                htmlFor="exampleFormControlInput1"
                style={{ fontSize: ".9rem" }}
              >
                Radius (Km)
              </label>
              <input
                type="number"
                style={{ width: 60, padding: "0 5px" }}
                min={0}
                step={5}
                className="form-control form-control-sm"
                placeholder="Umkreis Niederlassung"
                value={localLocationradius2}
                onChange={e => {
                  e.persist();
                  setLocalLocationRadius2(+e.target.value);
                  handleLocationRadius2Change(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <button
        className="btn btn-sm btn-outline-secondary mr-2 p-0"
        onClick={resetFilters}
        style={{
          width: 28,
          fontSize: "1.1rem",
          flexShrink: 0
        }}
      >
        <FontAwesomeIcon
          icon={faRedo}
          style={{ transform: "scale(-0.9,0.9)" }}
        />
      </button>
      <div className="d-flex" style={{ flex: 1 }}>
        <input
          type="date"
          onClick={e => {
            e.preventDefault();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            e.nativeEvent.target?.showPicker();
          }}
          className="w-75"
          ref={fromRef}
          value={selectedLocationDateFilter?.from}
          onChange={e =>
            setSelectedLocationDateFilter(prev => ({
              ...prev,
              from: e.target.value
            }))
          }
        />
        {selectedLocationDates?.from !== selectedLocationDateFilter?.from && (
          <button
            className="btn btn-sm btn-success ml-1 p-0"
            onClick={() =>
              updateSelectedLocationDates?.({
                from: selectedLocationDateFilter?.from,
                to: selectedLocationDates?.to
              })
            }
            style={{
              width: 28,
              fontSize: "1.1rem"
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        )}
      </div>
      <div className="d-flex" style={{ flex: 1 }}>
        <input
          type="date"
          onClick={e => {
            e.preventDefault();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            e.nativeEvent.target?.showPicker();
          }}
          className="w-75"
          ref={toRef}
          value={selectedLocationDateFilter?.to}
          onChange={e =>
            setSelectedLocationDateFilter(prev => ({
              ...prev,
              to: e.target.value
            }))
          }
        />
        {selectedLocationDates?.to !== selectedLocationDateFilter?.to && (
          <button
            className="btn btn-sm btn-success ml-1 p-0"
            style={{
              width: 28,
              fontSize: "1.1rem"
            }}
            onClick={() =>
              updateSelectedLocationDates?.({
                from: selectedLocationDates?.from,
                to: selectedLocationDateFilter?.to
              })
            }
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        )}
      </div>
      {handleSelectedLocationRadiusChange && (
        <div className="d-flex" style={{ flexShrink: 0 }}>
          <input
            type="number"
            style={{ width: 60, padding: "0 5px" }}
            min={0}
            step={5}
            placeholder="Umkreis Niederlassung"
            value={localLocationradius}
            onChange={e => {
              e.persist();
              setLocalLocationRadius(+e.target.value);
              handleLocationRadiusChange(e);
            }}
          />
          <button
            className="btn btn-sm btn-outline-secondary mx-1 p-0"
            onClick={onLocationSelect}
            style={{
              width: 28,
              fontSize: "1.1rem"
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )} */}
    </div>
  );
};

export default LocationRadiusSelection;
