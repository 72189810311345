import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import Geocode from "react-geocode";

type MapsContextType = {
  maps: any;
  map: any;
  apiReady: boolean;
  geocode: typeof Geocode;
  handleApiReady: (maps: any, map: any, apiReady: boolean) => void;
};

const MapsContext = createContext<MapsContextType>({
  maps: null,
  map: null,
  apiReady: false,
  handleApiReady: (maps: any, map: any, apiReady: boolean) => {
    return;
  },
  geocode: Geocode
});

export const useMapsContext = () => useContext(MapsContext);

const MapsContextProvider: React.FC = ({ children }) => {
  const [maps, setMaps] = useState<any>(null);
  const [map, setMap] = useState<any>(null);
  const [apiReady, setApiReady] = useState<any>(null);
  const [geocode, setGeocode] = useState<any>(null);

  useEffect(() => {
    if (!apiReady) return;
    Geocode.setApiKey("AIzaSyAM8Ciwgzts5Dp9I8_DEYJN3oTWjAsz3rE");
    Geocode.setRegion("de");
    Geocode.enableDebug();
    setGeocode(Geocode);
  }, [apiReady]);

  const handleApiReady = useCallback(
    (maps: any, map: any, apiReady: boolean) => {
      setMaps(maps);
      setMap(map);
      setApiReady(apiReady);
    },
    []
  );

  return (
    <MapsContext.Provider
      value={{ map, maps, apiReady, handleApiReady, geocode }}
    >
      {children}
    </MapsContext.Provider>
  );
};

export default MapsContextProvider;
