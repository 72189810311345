import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  CompetitionSubType,
  CustomerSubType,
  KundeSubType,
  SettlingSubType
} from "../../types";
import { drawCircle } from "../../util/map";
import { Category, MarkerMode } from "../../Views/Main";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import {
  faSquare,
  faCircle,
  faTriangle
} from "@fortawesome/pro-regular-svg-icons";
import { Location } from "../../types";
import { useMaterials } from "../../context/MaterialsContext";
import { useRenderCounter } from "../../hooks/useRenderCounter";

type SearchMarkerProps = {
  maps: any;
  map: any;
  settlingRadius: number;
  markerMode: MarkerMode;
  item?: Location;
  lat: number;
  lng: number;
  hiddenMaterials: string[];
  badgeData?: string[] | null;
  selectedLocationRadius?: number;
  selectedLocationRadius2?: number;
  withCircles?: boolean;
  onMarkerClicked?: () => void;
  dehighlightMarker?: boolean;
};

enum Shape {
  SQUARE,
  CIRCLE,
  TRIANGLE,
  MARKER
}

function createShape(shape: Shape, colors: string[]): string {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", "30");
  svg.setAttribute("height", "30");
  svg.setAttribute("viewBox", "0 0 30 30");

  const gradient = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "linearGradient"
  );
  let stop;

  // Set up the gradient
  gradient.id = "gradient";
  gradient.setAttribute("x1", "0%");
  gradient.setAttribute("y1", "0%");
  gradient.setAttribute("x2", "100%");
  gradient.setAttribute("y2", "0%");
  for (let i = 0; i < colors.length; i++) {
    stop = document.createElementNS("http://www.w3.org/2000/svg", "stop");
    stop.setAttribute("offset", (i / (colors.length - 1)) * 100 + "%");
    stop.setAttribute("stop-color", colors[i]);
    gradient.appendChild(stop);
  }
  svg.appendChild(gradient);

  // Create the shape
  let shapeElement: SVGElement;
  switch (shape) {
    case Shape.SQUARE: {
      shapeElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "rect"
      );
      shapeElement.setAttribute("x", "5");
      shapeElement.setAttribute("y", "5");
      shapeElement.setAttribute("width", "20");
      shapeElement.setAttribute("height", "20");
      break;
    }
    case Shape.CIRCLE: {
      shapeElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "circle"
      );
      shapeElement.setAttribute("cx", "15");
      shapeElement.setAttribute("cy", "15");
      shapeElement.setAttribute("r", "12");
      break;
    }
    case Shape.TRIANGLE: {
      shapeElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "polygon"
      );
      shapeElement.setAttribute("points", "15,5 25,25 5,25");
      break;
    }
    case Shape.MARKER: {
      shapeElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      shapeElement.setAttribute(
        "d",
        "M15,0 C9.5,0 5,4.5 5,10 C5,16 15,30 15,30 C15,30 25,16 25,10 C25,4.5 20.5,0 15,0 Z M15,14 C12.2,14 10,11.8 10,9 C10,6.2 12.2,4 15,4 C17.8,4 20,6.2 20,9 C20,11.8 17.8,14 15,14 Z"
      );
      break;
    }
  }
  shapeElement.setAttribute("fill", "url(#gradient)");
  svg.appendChild(shapeElement);

  const xml = new XMLSerializer().serializeToString(svg);
  const dataUrl =
    "data:image/svg+xml;base64," + Buffer.from(xml).toString("base64");
  return dataUrl;
}
const getIconByType = (type: string | undefined) => {
  if (!type) return faMapMarkerAlt;
  switch (type.toLocaleLowerCase()) {
    case "customers": {
      return faMapMarkerAlt;
    }
    case "competition": {
      return faTriangle;
    }
    case "settling": {
      return faSquare;
    }
    case "kunde": {
      return faCircle;
    }
    default:
      return faMapMarkerAlt;
  }
};

const tonnageToSize = {
  0: 25,
  500: 30,
  2000: 38,
  7500: 42,
  12500: 48,
  22500: 50
};

const getColor = (item: Location | undefined) =>
  item?.type
    ? item.type.sub.__typename === Category.SETTLING ||
      item.type.sub.__typename === Category.COMPETITION
      ? { hex: item.type.sub.color }
      : { hex: "#28a745" }
    : { hex: "#ff0015" };

const SearchMarker: React.FC<SearchMarkerProps> = ({
  maps,
  map,
  settlingRadius,
  lat,
  lng,
  badgeData,
  markerMode,
  item,
  selectedLocationRadius,
  selectedLocationRadius2,
  withCircles = true,
  onMarkerClicked,
  dehighlightMarker,
  hiddenMaterials
}) => {
  const { materials } = useMaterials();
  const getIconSize = useCallback((subType: CustomerSubType): [
    number,
    number
  ] => {
    const totalTonnage = subType.materials.reduce(
      (acc, curr) => acc + curr.tonnage,
      0
    );
    const matchedSizeKey = Object.keys(tonnageToSize).reduce(
      (acc, curr) => (totalTonnage > +curr ? +curr : acc),
      0
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return [tonnageToSize[matchedSizeKey], tonnageToSize[matchedSizeKey]];
  }, []);

  useEffect(() => {
    if (!maps) return;

    const color = getColor(item);

    let newMarker: any = null;
    let newCircle: any = null;

    if (
      withCircles &&
      !(selectedLocationRadius || selectedLocationRadius2) &&
      (item?.type.main === Category.SETTLING ||
        item?.type.main === Category.COMPETITION ||
        !item) &&
      !dehighlightMarker
    ) {
      if (markerMode === MarkerMode.DEFAULT) {
        newCircle = new maps.Circle({
          strokeColor: color.hex,
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: color.hex,
          fillOpacity: 0.1,
          map,
          center: { lat: lat, lng: lng },
          radius: settlingRadius * 1000
        });
      } else {
        newCircle = new maps.Circle({
          strokeColor: "#000000",
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: "#000000",
          fillOpacity: 0.02,
          map,
          center: { lat: lat, lng: lng },
          radius: settlingRadius * 1000
        });
      }
    }

    if (markerMode === MarkerMode.DEFAULT) {
      const iconSize = item?.type
        ? item?.type.sub.__typename === Category.CUSTOMERS
          ? getIconSize(item?.type.sub)
          : [30, 30]
        : [30, 30];

      const iconByType = getIconByType(item?.type?.main);

      const svgMarker = {
        path: iconByType.icon[4] as string,
        fillColor: color.hex,
        fillOpacity: 0.95,
        strokeWeight: 0.5,
        rotation: 0,
        scale: iconSize[0] / 600,
        anchor: new maps.Point(iconByType.icon[0] / 2, iconByType.icon[1])
      };
      newMarker = new maps.Marker({
        opacity: dehighlightMarker ? 0.2 : 1,
        position: {
          lat: lat,
          lng: lng
        },
        map,
        icon: svgMarker
      });
    } else {
      if (newCircle) {
        newCircle.setMap(null);
      }
      const shape =
        item?.type?.main === Category.SETTLING
          ? Shape.SQUARE
          : item?.type.main === Category.COMPETITION
          ? Shape.CIRCLE
          : item?.type.main === Category.KUNDE
          ? Shape.TRIANGLE
          : Shape.MARKER;

      let colors: string[] = [];

      if (item?.type?.main === Category.CUSTOMERS) {
        colors = (item?.type?.sub as CustomerSubType)?.materials
          .map(val => materials.find(v => v.id === val.category.id))
          .filter(val => !!val && !hiddenMaterials.includes(val.id))
          .map(v => v?.color)
          .filter(v => !!v) as string[];
      } else {
        colors = (item?.type?.sub as
          | SettlingSubType
          | CompetitionSubType
          | KundeSubType)?.materials
          .map(val => materials.find(v => v.id === val.id))
          .filter(val => !!val && !hiddenMaterials.includes(val.id))
          .map(v => v?.color)
          .filter(v => !!v) as string[];
      }

      const shapeIcon = createShape(shape, colors || ["#000000"]);
      newMarker = new maps.Marker({
        opacity: dehighlightMarker ? 0.2 : 1,
        position: {
          lat: lat,
          lng: lng
        },
        map,
        icon: {
          url: shapeIcon,
          anchor: new maps.Point(15, 15)
        }
      });
    }
    let clickHandler: any = null;
    if (onMarkerClicked && maps && newMarker && item) {
      clickHandler = maps.event.addListener(
        newMarker,
        "click",
        onMarkerClicked
      );
    }

    return () => {
      if (newMarker) newMarker.setMap(null);
      if (newCircle) newCircle.setMap(null);
      if (maps && clickHandler) {
        maps.event.removeListener(clickHandler);
      }
    };
  }, [
    dehighlightMarker,
    getIconSize,
    hiddenMaterials,
    item,
    lat,
    lng,
    map,
    maps,
    markerMode,
    materials,
    onMarkerClicked,
    selectedLocationRadius,
    selectedLocationRadius2,
    settlingRadius,
    withCircles
  ]);

  useEffect(() => {
    if (!maps) return;
    let circle: any = null;
    let circle2: any = null;
    let marker: any = null;

    if (!selectedLocationRadius && !selectedLocationRadius2) return;

    if (selectedLocationRadius) {
      const lineSymbol = {
        path: maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        scale: 2
      };
      circle = new maps.Polyline({
        path: drawCircle({ lat, lng }, selectedLocationRadius, 1, maps),
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "10px"
          }
        ],
        strokeWeight: 2,
        strokeColor: "red",
        fillColor: "#000000",
        fillOpacity: 0,
        map: map
      });

      marker = new maps.Marker({
        position: {
          lat,
          lng
        },
        map,
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
        }
      });
    }

    if (selectedLocationRadius2) {
      const lineSymbol = {
        path: maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        scale: 2
      };
      circle2 = new maps.Polyline({
        path: drawCircle({ lat, lng }, selectedLocationRadius2, 1, maps),
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "10px"
          }
        ],
        strokeWeight: 2,
        strokeColor: "blue",
        fillColor: "#000000",
        fillOpacity: 0,
        map: map
      });
    }

    return () => {
      if (marker) marker.setMap(null);
      if (circle) circle.setMap(null);
      if (circle2) circle2.setMap(null);
    };
  }, [lat, lng, map, maps, selectedLocationRadius, selectedLocationRadius2]);

  return (
    <div className="position-relative" style={{ height: 30, width: 30 }}>
      {badgeData && (
        <div
          className="position-absolute bg-light text-dark d-flex flex-column px-2 py-1 rounded shadow"
          style={{ border: "2px solid #aaaaaa", top: -100, left: 20 }}
        >
          {badgeData.map((text, key) => (
            <span
              key={key}
              style={{
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
                fontWeight: "bold"
              }}
            >
              {text}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchMarker;
