import React, {
  useState,
  createContext,
  PropsWithChildren,
  useContext
} from "react";

type ConfirmationModalContextType = {
  showModal: (props: ConfirmationModalModalProps) => void;
  hideModal: () => void;
  isModalOpen: boolean;
};

// Create a new context
export const ConfirmationModalContext = createContext(
  (null as unknown) as ConfirmationModalContextType
);

type ConfirmationModalModalProps = {
  title: string;
  message: string;
  confirmText: string;
  confirmAction: () => void;
  cancelAction?: () => void;
};

export const useConfirmationModal = () => useContext(ConfirmationModalContext);

// Create a provider for the context
export const ConfirmationModalProvider: React.FC<PropsWithChildren<
  unknown
>> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    modalContent,
    setModalContent
  ] = useState<ConfirmationModalModalProps | null>(null);

  // Function to show the confirmation modal with the given content
  const showModal = (props: ConfirmationModalModalProps) => {
    setIsModalOpen(true);
    setModalContent(props);
  };

  // Function to hide the confirmation modal
  const hideModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <ConfirmationModalContext.Provider
      value={{ showModal, hideModal, isModalOpen }}
    >
      {children}
      {/* Modal component */}
      {!!(isModalOpen && modalContent) && (
        <div
          className="modal d-flex align-items-center justify-content-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">{modalContent.title}</h2>
                <button type="button" className="close" onClick={hideModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{modalContent.message}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    modalContent.cancelAction?.();
                    hideModal();
                  }}
                >
                  Abbrechen
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={modalContent.confirmAction}
                >
                  {modalContent.confirmText}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </ConfirmationModalContext.Provider>
  );
};
