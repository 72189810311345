import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



input[type=checkbox], input[type=radio]{
  cursor: pointer;
}



ul {
  list-style: none !important;
  list-style-type: none;
}

h1 
    { 
    font-size: 2.2em; 
} 
     
h2 
    { 
    font-size: 2.0em;     
} 

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
     
h3 
    { 
    font-size: 1.8em; 
} 
     
h4 
    { 
    font-size: 1.6em;     
} 
     
h5 
    { 
    font-size: 1.4em;     
} 
     
p 
    { 
    font-size: 1.2em; 
    margin-bottom: 0 !important;    
}

label {
  margin-bottom: 0 !important;    
}

table td, table th {
  vertical-align: unset !important;
}

`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
