/* eslint-disable @typescript-eslint/ban-ts-comment */
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import React, {
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  useMemo,
  useContext
} from "react";
import { Category, TableSize } from "../../Views/Main";
import DistanceList from "./DistanceList";
import { logOut } from "../../services/auth";
import { useModal } from "../../context/ModalContext";
import { isEqual } from "lodash";
import {
  CompetitionSubType,
  CustomerSubType,
  FirestoreChangeType,
  InfoGroup,
  KundeSubType,
  Location,
  Owner,
  RowData,
  SettingsView,
  SettlingSubType
} from "../../types";
import CustomersTable from "./CustomersTable";
import { v4 } from "uuid";
import CustomerEditForm from "./CustomerEditForm";
import { db } from "../../firebaseConfig";
import { UserContext } from "../../context/user";
import { useMixPanel } from "../../util/Mixpanel";
import CreationForm from "./CreationForm";
import GroupItem from "./GroupItem";
import Header from "./Header";
import Draggable from "./Draggable";
import { useMapsContext } from "../../context/MapsContext";
import PermissionsTable from "./PermissionsTable";
import AutocompleteWrapper from "../Shared/autocomplete";
import { usePermissions } from "../../context/PermissionsContext";
import { Action } from "../../constants/permissions";
import EditLocationForm from "./EditLocationForm";
import LocationRadiusSelection from "./LocationRadiusSelection";
import {
  faSquare,
  faTriangle,
  faCircle,
  faLocationPin
} from "@fortawesome/pro-regular-svg-icons";
import MaterialsTable from "./MaterialsTable";
import { getRandomColor } from "../../util/getRandomColor";
import { useDomainContext } from "../../context/DomainContext";
import { useFirestoreLogger } from "../../context/FirestoreLogger";

type SidebarProps = {
  inputQuery: string;
  onInputChange: (value: string) => void;
  onSearch: () => Promise<void>;
  activeCategory: Category;
  setActiveCategory: (category: Category) => void;
  setShowCreationForm: React.Dispatch<React.SetStateAction<boolean>>;
  showCreationForm: boolean;
  onSave: (
    description: string,
    type: {
      main: string;
      sub:
        | CompetitionSubType
        | SettlingSubType
        | CustomerSubType
        | KundeSubType;
    }
  ) => Promise<void>;
  locations: Location[];
  setLocations: React.Dispatch<React.SetStateAction<Location[]>>;
  onDelete: (id: string) => Promise<void>;
  onSaveChanges: (
    itemId: string,
    newDesc: string,
    newType: {
      main: string;
      sub:
        | CompetitionSubType
        | SettlingSubType
        | CustomerSubType
        | KundeSubType;
    },
    newLocation?: Location,
    newFormattedAddress?: string
  ) => Promise<void>;
  customerRadius: number;
  settlingRadius: number;
  selectedCustomerId: string;
  setSelectedCustomerId: React.Dispatch<React.SetStateAction<string>>;
  selectedLocationId: string;
  handleSelectLocation: (settlingId: string) => void;
  selectedLocationRadius: number;
  selectedLocationRadius2: number;
  handleSelectedLocationRadiusChange: (
    e: ChangeEvent<HTMLInputElement>
  ) => void;
  handleSelectedLocationRadius2Change: (
    e: ChangeEvent<HTMLInputElement>
  ) => void;
  selectedLocationDateFilters:
    | {
        from?: string;
        to?: string;
      }
    | undefined;
  setSelectedLocationDateFilters: (nextFilter?: {
    from?: string;
    to?: string;
  }) => void;
  infoGroups: InfoGroup[];
  cancelCreation: () => void;
  setCustomersForMap: (value: Location[] | null) => void;
  handleSetCoordsToShowRoute: (
    newCoords: {
      origin: {
        lat: number;
        lng: number;
      };
      destination: {
        lat: number;
        lng: number;
      };
    } | null
  ) => void;
  coordsToShowRoute: {
    origin: {
      lat: number;
      lng: number;
    };
    destination: {
      lat: number;
      lng: number;
    };
  } | null;
  editLocation: {
    location: {
      lat: number;
      lng: number;
    };
    formatted_address: string;
  } | null;
  setEditLocationForMap: (
    location: {
      location: {
        lat: number;
        lng: number;
      };
      formatted_address: string;
    } | null
  ) => void;
  handleCustomerTableRowClick: (rowData: RowData) => void;
  tableView: TableSize;
};

const StyledSidebar = styled.div<{
  isTableView: boolean;
  width: string;
}>`
  position: relative;
  min-width: 600px;
  width: ${({ width }) => width};
  max-width: ${({ isTableView }) => (!isTableView ? "800px" : "auto")};
  transition: width 0.2s cubic-bezier(0.57, 0.23, 0.76, 0.73);
`;

const StyledList = styled.div`
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const DropdownItem = styled.div`
  ${({ onClick }) =>
    !!onClick
      ? css`
          &:hover {
            background-color: #c0c0c0 !important;
          }
        `
      : css`
          background-color: #e0e0e0 !important;
        `}
`;

const Sidebar = ({
  inputQuery,
  onInputChange,
  onSearch,
  activeCategory,
  setActiveCategory,
  setShowCreationForm,
  showCreationForm,
  onSave,
  locations,
  setLocations,
  setCustomersForMap,
  onDelete,
  onSaveChanges,
  customerRadius,
  settlingRadius,
  selectedCustomerId,
  setSelectedCustomerId,
  handleSelectLocation,
  handleSelectedLocationRadiusChange,
  handleSelectedLocationRadius2Change,
  selectedLocationId,
  selectedLocationRadius,
  selectedLocationRadius2,
  selectedLocationDateFilters,
  setSelectedLocationDateFilters,
  infoGroups,
  cancelCreation,
  handleSetCoordsToShowRoute,
  coordsToShowRoute,
  editLocation,
  setEditLocationForMap,
  handleCustomerTableRowClick,
  tableView
}: SidebarProps) => {
  const [description, setDescription] = useState("");
  const [type, setType] = useState<string>(Category.SETTLING);
  const [subType, setSubType] = useState<
    CustomerSubType | SettlingSubType | CompetitionSubType | KundeSubType | null
  >(null);
  const [showEditTypeDropDown, setShowEditTypeDropDown] = useState(false);
  const [
    materialEditIdToShowDropdown,
    setMaterialEditIdToShowDropdown
  ] = useState("");
  const [showEditOwnerDropDown, setShowEditOwnerDropDown] = useState(false);
  const [showDistList, setShowDistList] = useState(false);
  const [itemIdToEdit, setItemIdToEdit] = useState<string | null>(null);
  const [changedItemLocation, setChangedItemLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [
    changedItemFormattedAddress,
    setChangedItemFormattedAddress
  ] = useState<string | null>(null);
  const [changedItemDesc, setChangedItemDesc] = useState<string | null>(null);
  const [changedItemType, setChangedItemType] = useState<string | null>(null);
  const [changedItemSubType, setChangedItemSubType] = useState<
    CustomerSubType | SettlingSubType | CompetitionSubType | KundeSubType | null
  >(null);
  const [allowColorSelect, setAllowColorSelect] = useState(false);
  const [allSettlingsSelected, setAllSettlingsSelected] = useState(true);
  const { domain } = useDomainContext();

  const [allCompetitionsSelected, setAllCompetitionsSelected] = useState(true);
  const [allKundenSelected, setAllKundenSelected] = useState(true);
  const [
    selectedCompetitionCategories,
    setSelectedCompetitionCategories
  ] = useState<string[]>([]);
  const [selectedSettlingCategories, setSelectedSettlingCategories] = useState<
    string[]
  >([]);
  const [selectedKundeCategories, setSelectedKundeCategories] = useState<
    string[]
  >([]);
  const [editModalOpening, setEditModalOpening] = useState(false);
  const [customerOwnerEditFilter, setCustomerOwnerEditFilter] = useState("");

  const [allowedViews, setAllowedViews] = useState([
    {
      type: Category.SETTLING,
      title: "Niederlassung",
      icon: faSquare
    },
    { type: Category.COMPETITION, title: "Konkurrenz", icon: faTriangle },
    {
      type: Category.KUNDE,
      title: "Kunden",
      icon: faCircle
    },
    { type: Category.CUSTOMERS, title: "Baustellen", icon: faLocationPin }
  ]);
  const [inEditMode, setInEditMode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [allowEditNonCustomers, setAllowEditNonCustomers] = useState(false);

  const companyName = process.env.REACT_APP_COMPANY_NAME || "Niederlassung";
  const user = useContext(UserContext);
  const { mp } = useMixPanel();
  const { logAction } = useFirestoreLogger();

  const { showModal } = useModal();
  const [
    activeSettingsView,
    setActiveSettingsView
  ] = useState<SettingsView | null>(null);

  const { apiReady } = useMapsContext();
  const {
    getUserPermissionsByScope,
    getUserPermission,
    sitePermissions
  } = usePermissions();

  useEffect(() => {
    if (!sitePermissions.length) return;
    const modulePermissions = getUserPermissionsByScope(
      "Nutzer:innen / Module",
      Action.READ
    ).map(p => p.name);

    setAllowedViews(prev =>
      prev.filter(view => modulePermissions.includes(view.title))
    );
  }, [getUserPermission, getUserPermissionsByScope, sitePermissions]);

  useEffect(() => {
    const allowEditNonCustomers = getUserPermissionsByScope(
      "Nutzer:innen / Module",
      Action.WRITE
    );
    setAllowEditNonCustomers(
      allowEditNonCustomers.some(p => {
        console.log(p.name);
        console.log(activeCategory);
        switch (activeCategory) {
          case Category.SETTLING:
            return p.name === "Niederlassung";
          case Category.COMPETITION:
            return p.name === "Konkurrenz";
          case Category.KUNDE:
            return p.name === "Kunden";
        }
      })
    );
  }, [activeCategory, getUserPermissionsByScope]);

  const filteredList = useMemo(() => {
    return locations
      ? locations.filter(i => i.type.main === activeCategory)
      : null;
  }, [activeCategory, locations]);

  useEffect(() => {
    if (itemIdToEdit && filteredList) {
      const itemToEdit = filteredList.find(i => i.id === itemIdToEdit);
      if (itemToEdit) {
        console.log(itemToEdit);
        setChangedItemLocation(itemToEdit.location);
        setChangedItemFormattedAddress(itemToEdit.formatted_address);
        setChangedItemDesc(itemToEdit.description);
        setChangedItemType(itemToEdit.type.main);
        if (itemToEdit.type.main === Category.COMPETITION) {
          if (!itemToEdit.type.sub) {
            setChangedItemSubType({
              title: "",
              color: "",
              materials: [],
              kifaId: null,

              __typename: itemToEdit.type.main
            });
          } else if (typeof itemToEdit.type.sub === "string") {
            setChangedItemSubType({
              title: itemToEdit.type.sub,
              color: "",
              materials: [],
              kifaId: null,

              __typename: itemToEdit.type.main
            });
          } else {
            setChangedItemSubType(itemToEdit.type.sub);
          }
        } else if (itemToEdit.type.main === Category.SETTLING) {
          if (!itemToEdit.type.sub) {
            setChangedItemSubType({
              title: "",
              color: "",
              type1: "",
              type2: "",
              kifaId: null,
              materials: [],
              __typename: itemToEdit.type.main
            });
          } else if (typeof itemToEdit.type.sub === "string") {
            setChangedItemSubType({
              title: itemToEdit.type.sub,
              color: "",
              type1: "",
              type2: "",
              kifaId: null,

              materials: [],
              __typename: itemToEdit.type.main
            });
          } else {
            setChangedItemSubType(itemToEdit.type.sub);
          }
        } else if (itemToEdit.type.main === Category.CUSTOMERS) {
          if (!itemToEdit.type.sub) {
            setChangedItemSubType({
              materials: [],
              winner_first_level: null,
              winner_second_level: null,
              kunde: null,
              construction_time: {
                from: "",
                to: ""
              },
              settling: [],
              submiss_date: "",
              object_number: null,
              nearest_neighbours: [],
              __typename: Category.CUSTOMERS
            });
          } else {
            setChangedItemSubType(itemToEdit.type.sub as CustomerSubType);
            (itemToEdit.type.sub as CustomerSubType).winner_second_level
              ?.get()
              .then(val =>
                setCustomerOwnerEditFilter(val.data()?.description || "")
              );
          }
        } else if (itemToEdit.type.main === Category.KUNDE) {
          if (!itemToEdit.type.sub) {
            setChangedItemSubType({
              materials: [],
              title: "",
              color: "",
              tonnage: 0,
              __typename: Category.KUNDE
            });
          } else {
            setChangedItemSubType(itemToEdit.type.sub as KundeSubType);
          }
        }
      }
    }
  }, [filteredList, itemIdToEdit]);

  useEffect(() => {
    if (
      subType &&
      "title" in subType &&
      (allSubTypes.find(
        type =>
          type.sub &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (type.sub.title === subType.title || type.sub === subType.title)
      ) ||
        !subType.title)
    )
      setAllowColorSelect(false);
    else setAllowColorSelect(true);
  }, [subType]);

  useEffect(() => {
    setLocations(prev =>
      prev.map(l =>
        l.type.main === Category.SETTLING
          ? { ...l, switched: allSettlingsSelected }
          : l
      )
    );
  }, [allSettlingsSelected, setLocations]);

  useEffect(() => {
    setLocations(prev =>
      prev.map(l =>
        l.type.main === Category.COMPETITION
          ? { ...l, switched: allCompetitionsSelected }
          : l
      )
    );
  }, [allCompetitionsSelected, setLocations]);

  useEffect(() => {
    setLocations(prev =>
      prev.map(l =>
        l.type.main === Category.KUNDE
          ? { ...l, switched: allKundenSelected }
          : l
      )
    );
  }, [allKundenSelected, setLocations]);

  useEffect(() => {
    handleSetType(activeCategory);
  }, [activeCategory]);

  const allSubTypes = useMemo(() => {
    const types: {
      main: string;
      sub:
        | {
            title: string;
            color: string;
          }
        | string;
    }[] = [];
    const locationTypes = locations.map(i => i.type);
    for (let i = 0; i < locationTypes.length; i++) {
      const type = locationTypes[i];
      if (type.sub !== undefined && type.main !== Category.CUSTOMERS) {
        const subType = type.sub as
          | CompetitionSubType
          | SettlingSubType
          | KundeSubType;
        let found = false;
        for (let j = 0; j < types.length; j++) {
          const t = types[j];
          if (
            subType.title ===
              (t.sub as CompetitionSubType | SettlingSubType | KundeSubType)
                .title &&
            type.main === t.main
          ) {
            found = true;
            break;
          }
        }
        if (!found) {
          // @ts-ignore
          types.push(type);
        }
      }
    }
    return types;
  }, [locations]);

  const settlingSubTypes = useMemo(() => {
    const types: {
      main: string;
      sub: SettlingSubType | string;
    }[] = [];
    locations
      .map(i => i.type)
      .filter(t => t.sub !== undefined && t.main === Category.SETTLING)
      .forEach(type => {
        const subType = type.sub as SettlingSubType;
        if (
          !types.find(t => {
            return (
              subType.title ===
                (t.sub as {
                  title: string;
                  color: string;
                }).title && type.main === t.main
            );
          })
        ) {
          // @ts-ignore
          types.push(type);
        }
      });
    return types.filter(type => (type.sub as any).title !== "");
  }, [locations]);

  const competitionSubTypes = (() => {
    const types: {
      main: string;
      sub: CompetitionSubType | string;
    }[] = [];
    const locationTypes = locations.map(i => i.type);
    for (let i = 0; i < locationTypes.length; i++) {
      const type = locationTypes[i];
      if (type.sub !== undefined && type.main === Category.COMPETITION) {
        const subType = type.sub as CompetitionSubType;
        let found = false;
        for (let j = 0; j < types.length; j++) {
          const t = types[j];
          if (
            subType.title ===
              (t.sub as {
                title: string;
                color: string;
              }).title &&
            type.main === t.main
          ) {
            found = true;
            break;
          }
        }
        if (!found) {
          // @ts-ignore
          types.push(type);
        }
      }
    }
    return types;
  })();

  const kundeSubTypes = (() => {
    const types: {
      main: string;
      sub: KundeSubType;
    }[] = [];
    const locationTypes = locations.map(i => i.type);
    for (let i = 0; i < locationTypes.length; i++) {
      const type = locationTypes[i];
      if (type.sub !== undefined && type.main === Category.KUNDE) {
        const subType = type.sub as KundeSubType;
        let found = false;
        for (let j = 0; j < types.length; j++) {
          const t = types[j];
          if (
            subType.title === (t.sub as KundeSubType).title &&
            type.main === t.main
          ) {
            found = true;
            break;
          }
        }
        if (!found) {
          // @ts-ignore
          types.push(type);
        }
      }
    }
    return types;
  })();

  useEffect(() => {
    setSelectedCompetitionCategories(
      allSubTypes
        .filter(st => st.main === Category.COMPETITION)
        .map(st => (typeof st.sub === "object" ? st.sub.title : st.sub))
    );
    setSelectedSettlingCategories(
      allSubTypes
        .filter(st => st.main === Category.SETTLING)
        .map(st => (typeof st.sub === "object" ? st.sub.title : st.sub))
    );
    setSelectedKundeCategories(
      allSubTypes
        .filter(st => st.main === Category.KUNDE)
        .map(st => (typeof st.sub === "object" ? st.sub.title : st.sub))
    );
  }, [allSubTypes]);

  useEffect(() => {
    if (!changedItemLocation || !changedItemFormattedAddress) {
      setEditLocationForMap(null);
      return;
    }
    if (
      !isEqual(editLocation, {
        location: changedItemLocation,
        formatted_address: changedItemFormattedAddress
      })
    )
      setEditLocationForMap({
        location: changedItemLocation,
        formatted_address: changedItemFormattedAddress
      });
  }, [changedItemLocation]);

  useEffect(() => {
    handleCancelEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    if (!editLocation) return;
    setChangedItemLocation({
      lat: editLocation.location.lat,
      lng: editLocation.location.lng
    });
    setChangedItemFormattedAddress(editLocation.formatted_address);
  }, [editLocation]);

  useEffect(() => {
    setSelectedCustomerId("");
  }, [activeCategory, setSelectedCustomerId]);

  const handleToggleEditMode = (e: ChangeEvent<HTMLInputElement>) => {
    setInEditMode(e.target.checked);
  };

  const handleSetType = (type: Category) => {
    setType(type);
    if (type === Category.COMPETITION) {
      const typeName = Category.COMPETITION;
      setSubType({
        title: "",
        color: "",
        materials: [],
        kifaId: null,
        __typename: typeName
      });
    } else if (type === Category.SETTLING) {
      const typeName = Category.SETTLING;
      setSubType({
        title: "",
        color: "",
        type1: "",
        type2: "",
        kifaId: null,

        materials: [],
        __typename: typeName
      });
    } else if (type === Category.CUSTOMERS) {
      setSubType({
        materials: [],
        winner_first_level: null,
        winner_second_level: null,
        kunde: null,
        submiss_date: "",
        settling: [],
        object_number: null,
        construction_time: {
          from: "",
          to: ""
        },
        nearest_neighbours: [],
        __typename: Category.CUSTOMERS
      });
    } else if (type === Category.KUNDE) {
      setSubType({
        materials: [],
        title: "",
        color: "",
        tonnage: 0,
        __typename: Category.KUNDE
      });
    }
  };

  const handleSetChangedType = (value: string) => {
    setChangedItemType(value);
    if (value === Category.COMPETITION) {
      setChangedItemSubType({
        title: "",
        color: "",
        kifaId: null,
        materials: [],
        __typename: Category.COMPETITION
      });
    } else if (value === Category.SETTLING) {
      setChangedItemSubType({
        title: "",
        color: "",
        type1: "",
        type2: "",
        kifaId: null,
        materials: [],
        __typename: Category.SETTLING
      });
    } else if (value === Category.CUSTOMERS) {
      setChangedItemSubType({
        materials: [],
        winner_first_level: null,
        winner_second_level: null,
        kunde: null,
        submiss_date: "",
        object_number: null,
        settling: [],
        construction_time: {
          from: "",
          to: ""
        },
        nearest_neighbours: [],
        __typename: Category.CUSTOMERS
      });
    }
  };

  const handleSave = async () => {
    if (!description) return;

    if (subType && subType.__typename === Category.SETTLING) {
      if (!subType.color) {
        const randomColor = getRandomColor();

        await onSave(description, {
          main: type,
          sub: {
            title: subType.title,
            color: randomColor,
            type1: subType.type1,
            type2: subType.type2,
            kifaId: subType.kifaId,
            materials: subType.materials,
            __typename: subType.__typename
          }
        });
      } else {
        await onSave(description, {
          main: type,
          sub: {
            title: subType.title,
            color: subType.color,
            type1: subType.type1,
            type2: subType.type2,
            kifaId: subType.kifaId,
            materials: subType.materials,
            __typename: subType.__typename
          }
        });
      }
    } else if (subType && subType.__typename === Category.COMPETITION) {
      if (!subType.color) {
        const randomColor = getRandomColor();
        await onSave(description, {
          main: type,
          sub: {
            title: subType.title,
            color: randomColor,
            kifaId: subType.kifaId,
            materials: subType.materials,
            __typename: subType.__typename
          }
        });
      } else {
        await onSave(description, {
          main: type,
          sub: {
            title: subType.title,
            color: subType.color,
            kifaId: subType.kifaId,
            materials: subType.materials,
            __typename: subType.__typename
          }
        });
      }
    } else if (subType && subType.__typename === Category.KUNDE) {
      await onSave(description, {
        main: type,
        sub: {
          title: subType.title,
          color: subType.color,
          tonnage: subType.tonnage,
          materials: subType.materials,
          __typename: subType.__typename
        }
      });
    } else if (subType && subType.__typename === Category.CUSTOMERS) {
      await onSave(description, { main: type, sub: subType });
    }
    setDescription("");
    setType(Category.SETTLING);
    handleSetType(activeCategory);
  };

  const handleItemSwitch = (description: string) => {
    setLocations(prev =>
      prev.map(l =>
        l.description === description ? { ...l, switched: !l.switched } : l
      )
    );
  };

  const handleSwitchAllOfSettlingSubType = (subtype: string) => {
    if (selectedSettlingCategories.includes(subtype)) {
      setSelectedSettlingCategories(
        selectedSettlingCategories.filter(cat => cat !== subtype)
      );
      setLocations(prev =>
        prev.map(loc => {
          if (
            loc.type.sub.__typename === Category.SETTLING &&
            loc.type.sub.title === subtype
          ) {
            return { ...loc, switched: false };
          } else return loc;
        })
      );
    } else {
      setSelectedSettlingCategories(selectedSettlingCategories.concat(subtype));
      setLocations(prev =>
        prev.map(loc => {
          if (
            loc.type.sub.__typename === Category.SETTLING &&
            loc.type.sub.title === subtype
          ) {
            return { ...loc, switched: true };
          } else return loc;
        })
      );
    }
  };

  const handleSwitchAllOfCompetitionSubType = (subtype: string) => {
    if (selectedCompetitionCategories.includes(subtype)) {
      setSelectedCompetitionCategories(
        selectedCompetitionCategories.filter(cat => cat !== subtype)
      );
      setLocations(prev =>
        prev.map(loc => {
          if (
            loc.type.sub.__typename === Category.COMPETITION &&
            loc.type.sub.title === subtype
          ) {
            return { ...loc, switched: false };
          } else return loc;
        })
      );
    } else {
      setSelectedCompetitionCategories(
        selectedCompetitionCategories.concat(subtype)
      );
      setLocations(prev =>
        prev.map(loc => {
          if (
            loc.type.sub.__typename === Category.COMPETITION &&
            loc.type.sub.title === subtype
          ) {
            return { ...loc, switched: true };
          } else return loc;
        })
      );
    }
  };

  const handleOpenEdit = (id: string) => {
    setItemIdToEdit(id);
  };

  const handleCancelEdit = () => {
    setItemIdToEdit(null);
    setChangedItemDesc(null);
    setChangedItemType(null);
    setChangedItemLocation(null);
    setChangedItemFormattedAddress(null);
    setEditLocationForMap(null);
    setCustomerOwnerEditFilter("");
    setChangedItemSubType(null);
  };

  const handleSaveChanges = async () => {
    if (
      !itemIdToEdit ||
      !changedItemDesc ||
      !changedItemType ||
      !changedItemFormattedAddress ||
      !changedItemLocation ||
      !changedItemSubType
    ) {
      return;
    } else {
      const location = locations.find(l => l.id === itemIdToEdit);
      if (!location) return;

      await onSaveChanges(
        itemIdToEdit,
        changedItemDesc,
        {
          main: changedItemType,
          sub: changedItemSubType
        },
        {
          ...location,
          description: changedItemDesc,
          type: {
            main: changedItemType,
            sub: changedItemSubType
          },
          location: changedItemLocation,
          formatted_address: changedItemFormattedAddress
        },
        changedItemFormattedAddress
      );
      handleCancelEdit();
    }
  };

  const groupedCompetitionItems = useMemo(() => {
    if (!locations) return [];
    const withNoCat: {
      subtype: string;
      items: Location[];
    } = {
      subtype: "Keine Kategorie",
      items: []
    };
    return allSubTypes
      .map(s => ({
        subtype: s.sub,
        items: locations.filter(loc => {
          if (loc.type.main !== Category.COMPETITION) return false;
          const subType = loc.type.sub as CompetitionSubType;
          if (!subType || !subType.title) {
            if (!withNoCat.items.find(it => it.id === loc.id))
              withNoCat.items.push(loc);
            return false;
          }
          if (typeof subType === "string" && typeof s.sub === "string") {
            return subType === s.sub;
          }
          // @ts-ignore
          else return subType.title === s.sub.title && s.main === loc.type.main;
        })
      }))
      .concat(withNoCat)
      .filter(i => i.items.length);
  }, [locations, allSubTypes]);

  const groupedSettlingItems = useMemo(() => {
    if (!locations) return [];
    const withNoCat: {
      subtype: string;
      items: Location[];
    } = {
      subtype: "Keine Kategorie",
      items: []
    };
    return allSubTypes
      .map(s => ({
        subtype: s.sub,
        items: locations.filter(loc => {
          if (loc.type.main !== Category.SETTLING) return false;
          const subType = loc.type.sub as SettlingSubType;
          if (!subType || !subType.title) {
            if (!withNoCat.items.find(it => it.id === loc.id))
              withNoCat.items.push(loc);
            return false;
          }
          if (typeof subType === "string" && typeof s.sub === "string") {
            return subType === s.sub;
          }
          // @ts-ignore
          else return subType.title === s.sub.title && loc.type.main === s.main;
        })
      }))
      .concat(withNoCat)
      .filter(i => i.items.length);
  }, [locations, allSubTypes]);

  const groupedKundeItems = useMemo(() => {
    if (!locations) return [];
    const withNoCat: {
      subtype: string;
      items: Location[];
    } = {
      subtype: "Keine Kategorie",
      items: []
    };
    return allSubTypes
      .map(s => ({
        subtype: s.sub,
        items: locations.filter(loc => {
          if (loc.type.main !== Category.KUNDE) return false;
          const subType = loc.type.sub as KundeSubType;
          if (!subType || !subType.title) {
            if (!withNoCat.items.find(it => it.id === loc.id))
              withNoCat.items.push(loc);
            return false;
          }
          if (typeof subType === "string" && typeof s.sub === "string") {
            return subType === s.sub;
          }
          // @ts-ignore
          else return subType.title === s.sub.title && loc.type.main === s.main;
        })
      }))
      .concat(withNoCat)
      .filter(i => i.items.length);
  }, [locations, allSubTypes]);

  const filteredEditSubtypes = useMemo(
    () =>
      allSubTypes.filter(t => {
        if (
          changedItemSubType &&
          typeof t.sub === "string" &&
          "title" in changedItemSubType
        ) {
          return t.sub.includes(changedItemSubType.title);
        } else if (
          changedItemSubType &&
          typeof t.sub === "object" &&
          "title" in changedItemSubType &&
          "title" in t.sub
        ) {
          return t.sub.title.includes(changedItemSubType.title);
        }
      }),
    [allSubTypes, changedItemSubType]
  );

  const filteredEditCompetitionSubtypes = useMemo(
    () =>
      competitionSubTypes.filter(t => {
        if (
          changedItemSubType &&
          typeof t.sub === "string" &&
          "title" in changedItemSubType
        ) {
          return t.sub.includes(changedItemSubType.title);
        } else if (
          changedItemSubType &&
          typeof t.sub === "object" &&
          "title" in changedItemSubType &&
          "title" in t.sub
        ) {
          return t.sub.title.includes(changedItemSubType.title);
        }
      }),
    [competitionSubTypes, changedItemSubType]
  );

  const filteredEditKundeSubtypes = useMemo(
    () =>
      kundeSubTypes.filter(t => {
        if (
          changedItemSubType &&
          typeof t.sub === "object" &&
          "title" in changedItemSubType &&
          "title" in t.sub
        ) {
          return t.sub.title.includes(changedItemSubType.title);
        }
      }),
    [kundeSubTypes, changedItemSubType]
  );

  const filteredEditSettlingSubtypes = useMemo(
    () =>
      settlingSubTypes.filter(t => {
        if (
          changedItemSubType &&
          typeof t.sub === "string" &&
          "title" in changedItemSubType
        ) {
          return t.sub.includes(changedItemSubType.title);
        } else if (
          changedItemSubType &&
          typeof t.sub === "object" &&
          "title" in changedItemSubType &&
          "title" in t.sub
        ) {
          return t.sub.title.includes(changedItemSubType.title);
        }
      }),
    [settlingSubTypes, changedItemSubType]
  );

  const locationsWithoutCustomers: Owner[] = locations
    .filter(l => {
      return (
        l.type.main === Category.SETTLING ||
        l.type.main === Category.COMPETITION
      );
    })
    .map(l => ({
      location: l.location,
      formatted_address: l.formatted_address,
      description: l.description,
      type: l.type.main as "competition" | "settling",
      id: l.id
    }));

  const filteredEditWithoutCustomers: Owner[] = locationsWithoutCustomers.filter(
    l => l.description.includes(customerOwnerEditFilter)
  );

  const handleStartCustomerCreation = () => {
    setChangedItemDesc("");
    setChangedItemFormattedAddress("");
    setChangedItemSubType({
      materials: [],
      winner_first_level: null,
      winner_second_level: null,
      kunde: null,
      submiss_date: "",
      object_number: null,
      settling: [],
      construction_time: {
        from: "",
        to: ""
      },
      nearest_neighbours: [],
      __typename: Category.CUSTOMERS
    });
    setItemIdToEdit("");
    setShowCreationForm(true);
  };

  const handleSaveCustomer = async () => {
    if (
      !changedItemFormattedAddress ||
      !changedItemDesc ||
      !changedItemLocation
    )
      return;
    const newCustomer = {
      description: changedItemDesc,
      formatted_address: changedItemFormattedAddress,
      domain,
      location: changedItemLocation,
      type: {
        main: Category.CUSTOMERS,
        sub: {
          ...changedItemSubType,
          settling: (changedItemSubType as CustomerSubType).settling.map(v =>
            db.collection("locations").doc(v.id)
          )
        }
      },
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString()
    };

    const savedLocation = await db.collection("locations").add(newCustomer);
    logAction(FirestoreChangeType.CREATE, savedLocation.id, Category.CUSTOMERS);
    const projectName = process.env.REACT_APP_PROJECT_NAME || "";

    if (user)
      mp.track("ACTION", {
        Project_Name: projectName,
        User: user.email,
        Action_Type: "save",
        Customer: newCustomer.description,
        Customer_Type: newCustomer.type.main,
        Date: new Date(),
        Month_Year: new Intl.DateTimeFormat("en-GB", {
          month: "numeric",
          year: "numeric"
        }).format(new Date())
      });

    const stateCustomer = {
      ...newCustomer,
      id: savedLocation.id,
      switched: true
    };

    setShowCreationForm(false);
    handleCancelEdit();
    setSelectedCustomerId(stateCustomer.id);
  };

  useEffect(() => {
    if (
      changedItemSubType &&
      "title" in changedItemSubType &&
      changedItemSubType.title &&
      filteredEditSubtypes.length &&
      !editModalOpening &&
      !showEditTypeDropDown
    ) {
      setShowEditTypeDropDown(true);
    } else setShowEditTypeDropDown(false);
    // @ts-ignore
  }, [changedItemSubType?.title]);

  useEffect(() => {
    if (coordsToShowRoute) {
      cancelCreation();
    }
  }, [coordsToShowRoute]);

  useEffect(() => {
    if (showCreationForm) {
      handleSetCoordsToShowRoute(null);
    }
  }, [showCreationForm]);

  const sideBarWidth =
    activeCategory === Category.CUSTOMERS
      ? tableView === TableSize.FULL
        ? "75vw"
        : tableView === TableSize.REDUCED
        ? "50vw"
        : "27.5vw"
      : "27.5vw";

  const handleSetActiveSettingsView = useCallback(
    (value: SettingsView | null) => {
      if (activeSettingsView === value) {
        setActiveSettingsView(null);
      } else {
        setActiveSettingsView(value);
      }
    },
    [activeSettingsView]
  );

  // useEffect(() => {
  // db.collection("locations")
  //   .where("type.main", "==", Category.CUSTOMERS)
  //   .onSnapshot(snapshot => {
  //     const customers = snapshot.docs.map(doc => {
  //       doc.ref.update({
  //         type: {
  //           main: Category.CUSTOMERS,
  //           sub: {
  //             ...doc.data().type.sub,
  //             winner_first_level: null,
  //             winner_second_level: null,
  //             kunde: null
  //           }
  //         }
  //       });
  //     });
  //   });
  // }, []);
  const handleCloseSettings = useCallback(() => {
    setActiveSettingsView(null);
  }, []);

  return (
    <StyledSidebar
      className="shadow-lg position-relative"
      width={sideBarWidth}
      isTableView={activeCategory === Category.CUSTOMERS}
    >
      <Header
        activeCategory={activeCategory}
        companyName={companyName}
        tableView={tableView}
        handleSetActiveSettingsView={handleSetActiveSettingsView}
      />
      <div>
        <ul
          className=" px-2 pt-2 nav nav-tabs w-100 position-relative"
          style={{ backgroundColor: "#f3f3f3" }}
        >
          {allowedViews.map((cat, key) => (
            <li
              key={key}
              className="nav-item"
              style={{
                marginTop: activeCategory === cat.type ? -1 : -2,
                cursor: "pointer"
              }}
            >
              <a
                className={`nav-link ${
                  activeCategory === cat.type ? "active" : ""
                }`}
                style={{
                  color: "unset"
                }}
                onClick={() => setActiveCategory(cat.type)}
              >
                <FontAwesomeIcon icon={cat.icon} className="mr-2" />
                {cat.title[0].toUpperCase() + cat.title.slice(1)}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div
        className={`${activeCategory === Category.CUSTOMERS ? "p-0" : "p-2"}`}
      >
        {/* {activeCategory !== Category.CUSTOMERS && (
          <>
            <div
              className="w-100 position-relative"
              style={{
                height: 35,
                overflow: "hidden"
              }}
            >
              <AutocompleteWrapper
                className="form-control form-control-sm w-100 h-100 pr-5 position-relative"
                type="text"
                disabled={!apiReady}
                placeholder="Adresse eingeben"
                aria-label="Search"
                defaultValue={inputQuery}
                onChange={e => onInputChange(e.target.value)}
                onPlaceSelected={place => {
                  const query = place.formatted_address;
                  onInputChange(query);
                }}
              />
              <SearchButton className="border-left">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{
                    right: 15,
                    cursor: "pointer"
                  }}
                  onClick={onSearch}
                />
              </SearchButton>
            </div>
            {showCreationForm && (
              <CreationForm
                allowColorSelect={allowColorSelect}
                apiReady={apiReady}
                allSubTypes={allSubTypes}
                competitionSubTypes={competitionSubTypes}
                settlingSubTypes={settlingSubTypes}
                cancelCreation={cancelCreation}
                companyName={companyName}
                description={description}
                setDescription={setDescription}
                handleSave={handleSave}
                handleSetType={handleSetType}
                setSubType={setSubType}
                setType={setType}
                subType={subType}
                type={type}
              />
            )}
          </>
        )} */}

        {filteredList && activeCategory !== Category.CUSTOMERS && (
          <StyledList
            className="mt-2 w-100"
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: `calc(100vh - ${
                allowEditNonCustomers ? "200px" : "135px"
              } ${selectedLocationId ? "- 290px" : ""} ${
                inEditMode ? "- 86px" : ""
              } ${itemIdToEdit ? " - 390px" : ""} ${
                showCreationForm ? "- 295px" : ""
              })`
            }}
          >
            {
              <div className="position-relative w-100 d-flex align-items-center">
                <input
                  type="checkbox"
                  id={activeCategory + "-select"}
                  className="mr-2"
                  checked={
                    activeCategory === Category.SETTLING
                      ? allSettlingsSelected
                      : activeCategory === Category.COMPETITION
                      ? allCompetitionsSelected
                      : allKundenSelected
                  }
                  style={{ cursor: "pointer" }}
                  onChange={() => {
                    if (activeCategory === Category.SETTLING) {
                      if (!allSettlingsSelected) {
                        setSelectedSettlingCategories(
                          allSubTypes
                            .filter(st => st.main === Category.SETTLING)
                            .map(st =>
                              typeof st.sub === "object" ? st.sub.title : st.sub
                            )
                        );
                      } else {
                        setSelectedSettlingCategories([]);
                      }
                      setAllSettlingsSelected(!allSettlingsSelected);
                    } else if (activeCategory === Category.COMPETITION) {
                      if (!allCompetitionsSelected) {
                        setSelectedCompetitionCategories(
                          allSubTypes
                            .filter(st => st.main === Category.COMPETITION)
                            .map(st =>
                              typeof st.sub === "object" ? st.sub.title : st.sub
                            )
                        );
                      } else {
                        setSelectedCompetitionCategories([]);
                      }
                      setAllCompetitionsSelected(!allCompetitionsSelected);
                    } else if (activeCategory === Category.KUNDE) {
                      if (!allKundenSelected) {
                        console.log("allKundenSelected", allKundenSelected);
                        setSelectedKundeCategories(
                          allSubTypes
                            .filter(st => st.main === Category.KUNDE)
                            .map(st =>
                              typeof st.sub === "object" ? st.sub.title : st.sub
                            )
                        );
                      } else {
                        setSelectedKundeCategories([]);
                      }
                      setAllKundenSelected(!allKundenSelected);
                    }
                  }}
                />
                <div
                  style={{
                    fontWeight: 700,
                    fontSize: "0.9rem"
                  }}
                >
                  alle auswählen
                </div>
              </div>
            }
            {activeCategory === Category.KUNDE &&
              groupedKundeItems.map((it, idx) => (
                <GroupItem
                  key={idx}
                  groupItem={it}
                  inEditMode={inEditMode}
                  itemIdToEdit={itemIdToEdit}
                  selectedCategories={selectedKundeCategories}
                  handleSwitchAllOfSubType={handleSwitchAllOfSettlingSubType}
                  allSubTypes={allSubTypes}
                  changedItemDesc={changedItemDesc as string}
                  changedItemFormattedAddress={changedItemFormattedAddress}
                  changedItemSubType={changedItemSubType}
                  changedItemType={changedItemType as string}
                  customerOwnerEditFilter={customerOwnerEditFilter}
                  competitionSubTypes={competitionSubTypes}
                  settlingSubTypes={settlingSubTypes}
                  kundeSubTypes={kundeSubTypes}
                  filteredEditSubtypes={filteredEditSubtypes}
                  filteredEditCompetitionSubtypes={
                    filteredEditCompetitionSubtypes
                  }
                  filteredEditKundeSubtypes={filteredEditKundeSubtypes}
                  filteredEditSettlingSubtypes={filteredEditSettlingSubtypes}
                  filteredEditWithoutCustomers={filteredEditWithoutCustomers}
                  handleCancelEdit={handleCancelEdit}
                  handleSaveChanges={handleSaveChanges}
                  handleSetChangedType={handleSetChangedType}
                  materialEditIdToShowDropdown={materialEditIdToShowDropdown}
                  setChangedItemDesc={setChangedItemDesc}
                  setChangedItemFormattedAddress={
                    setChangedItemFormattedAddress
                  }
                  setChangedItemLocation={setChangedItemLocation}
                  setChangedItemSubType={setChangedItemSubType}
                  setCustomerOwnerEditFilter={setCustomerOwnerEditFilter}
                  setMaterialEditIdToShowDropdown={
                    setMaterialEditIdToShowDropdown
                  }
                  setShowEditOwnerDropDown={setShowEditOwnerDropDown}
                  setShowEditTypeDropDown={setShowEditTypeDropDown}
                  showEditOwnerDropDown={showEditOwnerDropDown}
                  showEditTypeDropDown={showEditTypeDropDown}
                  handleItemSwitch={handleItemSwitch}
                  handleOpenEdit={id => {
                    if (selectedLocationId) {
                      handleCancelEdit();
                      handleSelectLocation(selectedLocationId);
                    }
                    handleOpenEdit(id);
                  }}
                  setEditModalOpening={setEditModalOpening}
                  handleSelectLocation={id => {
                    handleCancelEdit();
                    handleSelectLocation(id);
                  }}
                  handleSelectedLocationRadiusChange={
                    handleSelectedLocationRadiusChange
                  }
                  handleSelectedLocationRadius2Change={
                    handleSelectedLocationRadius2Change
                  }
                  selectedLocationId={selectedLocationId}
                  selectedLocationRadius={selectedLocationRadius}
                  selectedLocationRadius2={selectedLocationRadius2}
                  selectedLocationDates={selectedLocationDateFilters}
                  updateSelectedLocationDates={setSelectedLocationDateFilters}
                />
              ))}
            {activeCategory === Category.SETTLING &&
              groupedSettlingItems.map((it, idx) => (
                <GroupItem
                  key={idx}
                  groupItem={it}
                  inEditMode={inEditMode}
                  itemIdToEdit={itemIdToEdit}
                  selectedCategories={selectedSettlingCategories}
                  handleSwitchAllOfSubType={handleSwitchAllOfSettlingSubType}
                  allSubTypes={allSubTypes}
                  changedItemDesc={changedItemDesc as string}
                  changedItemFormattedAddress={changedItemFormattedAddress}
                  changedItemSubType={changedItemSubType}
                  changedItemType={changedItemType as string}
                  customerOwnerEditFilter={customerOwnerEditFilter}
                  competitionSubTypes={competitionSubTypes}
                  settlingSubTypes={settlingSubTypes}
                  kundeSubTypes={kundeSubTypes}
                  filteredEditKundeSubtypes={filteredEditKundeSubtypes}
                  filteredEditSubtypes={filteredEditSubtypes}
                  filteredEditCompetitionSubtypes={
                    filteredEditCompetitionSubtypes
                  }
                  filteredEditSettlingSubtypes={filteredEditSettlingSubtypes}
                  filteredEditWithoutCustomers={filteredEditWithoutCustomers}
                  handleCancelEdit={handleCancelEdit}
                  handleSaveChanges={handleSaveChanges}
                  handleSetChangedType={handleSetChangedType}
                  materialEditIdToShowDropdown={materialEditIdToShowDropdown}
                  setChangedItemDesc={setChangedItemDesc}
                  setChangedItemFormattedAddress={
                    setChangedItemFormattedAddress
                  }
                  setChangedItemLocation={setChangedItemLocation}
                  setChangedItemSubType={setChangedItemSubType}
                  setCustomerOwnerEditFilter={setCustomerOwnerEditFilter}
                  setMaterialEditIdToShowDropdown={
                    setMaterialEditIdToShowDropdown
                  }
                  setShowEditOwnerDropDown={setShowEditOwnerDropDown}
                  setShowEditTypeDropDown={setShowEditTypeDropDown}
                  showEditOwnerDropDown={showEditOwnerDropDown}
                  showEditTypeDropDown={showEditTypeDropDown}
                  handleItemSwitch={handleItemSwitch}
                  handleOpenEdit={id => {
                    if (selectedLocationId) {
                      handleCancelEdit();
                      handleSelectLocation(selectedLocationId);
                    }
                    handleOpenEdit(id);
                  }}
                  setEditModalOpening={setEditModalOpening}
                  handleSelectLocation={id => {
                    handleCancelEdit();
                    handleSelectLocation(id);
                  }}
                  handleSelectedLocationRadiusChange={
                    handleSelectedLocationRadiusChange
                  }
                  handleSelectedLocationRadius2Change={
                    handleSelectedLocationRadius2Change
                  }
                  selectedLocationId={selectedLocationId}
                  selectedLocationRadius={selectedLocationRadius}
                  selectedLocationRadius2={selectedLocationRadius2}
                  selectedLocationDates={selectedLocationDateFilters}
                  updateSelectedLocationDates={setSelectedLocationDateFilters}
                />
              ))}
            {activeCategory === Category.COMPETITION &&
              groupedCompetitionItems.map((it, idx) => (
                <GroupItem
                  key={idx}
                  inEditMode={inEditMode}
                  groupItem={it}
                  itemIdToEdit={itemIdToEdit}
                  selectedCategories={selectedCompetitionCategories}
                  handleSwitchAllOfSubType={handleSwitchAllOfCompetitionSubType}
                  allSubTypes={allSubTypes}
                  competitionSubTypes={competitionSubTypes}
                  settlingSubTypes={settlingSubTypes}
                  changedItemDesc={changedItemDesc as string}
                  changedItemFormattedAddress={changedItemFormattedAddress}
                  changedItemSubType={changedItemSubType}
                  changedItemType={changedItemType as string}
                  customerOwnerEditFilter={customerOwnerEditFilter}
                  kundeSubTypes={kundeSubTypes}
                  filteredEditKundeSubtypes={filteredEditKundeSubtypes}
                  filteredEditSubtypes={filteredEditSubtypes}
                  filteredEditCompetitionSubtypes={
                    filteredEditCompetitionSubtypes
                  }
                  filteredEditSettlingSubtypes={filteredEditSettlingSubtypes}
                  filteredEditWithoutCustomers={filteredEditWithoutCustomers}
                  handleCancelEdit={handleCancelEdit}
                  handleSaveChanges={handleSaveChanges}
                  handleSetChangedType={handleSetChangedType}
                  materialEditIdToShowDropdown={materialEditIdToShowDropdown}
                  setChangedItemDesc={setChangedItemDesc}
                  setChangedItemFormattedAddress={
                    setChangedItemFormattedAddress
                  }
                  setChangedItemLocation={setChangedItemLocation}
                  setChangedItemSubType={setChangedItemSubType}
                  setCustomerOwnerEditFilter={setCustomerOwnerEditFilter}
                  setMaterialEditIdToShowDropdown={
                    setMaterialEditIdToShowDropdown
                  }
                  setShowEditOwnerDropDown={setShowEditOwnerDropDown}
                  setShowEditTypeDropDown={setShowEditTypeDropDown}
                  showEditOwnerDropDown={showEditOwnerDropDown}
                  showEditTypeDropDown={showEditTypeDropDown}
                  handleItemSwitch={handleItemSwitch}
                  handleOpenEdit={id => {
                    if (selectedLocationId) {
                      handleCancelEdit();
                      handleSelectLocation(selectedLocationId);
                    }
                    handleOpenEdit(id);
                  }}
                  handleSelectLocation={id => {
                    handleCancelEdit();
                    handleSelectLocation(id);
                  }}
                  handleSelectedLocationRadiusChange={
                    handleSelectedLocationRadiusChange
                  }
                  handleSelectedLocationRadius2Change={
                    handleSelectedLocationRadius2Change
                  }
                  selectedLocationId={selectedLocationId}
                  selectedLocationRadius={selectedLocationRadius}
                  selectedLocationRadius2={selectedLocationRadius2}
                  selectedLocationDates={selectedLocationDateFilters}
                  updateSelectedLocationDates={setSelectedLocationDateFilters}
                  setEditModalOpening={setEditModalOpening}
                />
              ))}
          </StyledList>
        )}
        {activeCategory === Category.CUSTOMERS && (
          <div
            className="position-relative w-100"
            style={{
              opacity: showCreationForm ? "0.5" : "1",
              pointerEvents: showCreationForm ? "none" : "all"
            }}
          >
            <CustomersTable
              searchText={searchText}
              setSearchText={setSearchText}
              locations={locations}
              onChange={onSaveChanges}
              handleSetCoordsToShowRoute={handleSetCoordsToShowRoute}
              coordsToShowRoute={coordsToShowRoute}
              handleRowClick={handleCustomerTableRowClick}
              handleStartCustomerCreation={handleStartCustomerCreation}
              selectedCustomerId={selectedCustomerId}
              itemIdToEdit={itemIdToEdit}
              setItemIdToEdit={setItemIdToEdit}
              showDistList={showDistList}
              setShowDistList={setShowDistList}
              setCustomersForMap={setCustomersForMap}
              tableView={tableView}
              settlingSubTypes={settlingSubTypes}
            />
          </div>
        )}

        {activeCategory === Category.CUSTOMERS && showCreationForm && (
          <Draggable
            initialPosition={{ x: `10px`, y: "7vh" }}
            bounds={{
              minX: `10px`,
              maxX: "100vw",
              minY: "10px",
              maxY: "100vh"
            }}
          >
            <CustomerEditForm
              locations={locations}
              itemIdToEdit={itemIdToEdit || ""}
              changedItemDesc={changedItemDesc || ""}
              changedItemFormattedAddress={changedItemFormattedAddress || ""}
              changedItemSubType={changedItemSubType as CustomerSubType}
              setChangedItemLocation={setChangedItemLocation}
              setChangedItemFormattedAddress={setChangedItemFormattedAddress}
              setChangedItemDesc={setChangedItemDesc}
              setChangedItemSubType={setChangedItemSubType}
              settlingSubTypes={settlingSubTypes}
              filteredEditWithoutCustomers={filteredEditWithoutCustomers}
              kundeSubTypes={kundeSubTypes}
              cancelEdit={() => {
                setShowCreationForm(false);
                handleCancelEdit();
              }}
              saveChanges={async () => {
                try {
                  const search = changedItemDesc || "";
                  await handleSaveCustomer();
                  setSearchText(search);
                } catch (err) {
                  console.error(err);
                }
              }}
            />
          </Draggable>
        )}
        {itemIdToEdit && activeCategory === Category.CUSTOMERS && (
          <Draggable
            initialPosition={{ x: `calc(${sideBarWidth} + 70px)`, y: "8vh" }}
            bounds={{
              minX: `calc(${sideBarWidth} + 10px)`,
              maxX: "100vw",
              minY: "10px",
              maxY: "100vh"
            }}
          >
            <CustomerEditForm
              itemIdToEdit={itemIdToEdit}
              locations={locations}
              changedItemDesc={changedItemDesc}
              changedItemFormattedAddress={changedItemFormattedAddress}
              changedItemSubType={changedItemSubType as CustomerSubType}
              setChangedItemLocation={setChangedItemLocation}
              setChangedItemFormattedAddress={setChangedItemFormattedAddress}
              setChangedItemDesc={setChangedItemDesc}
              setChangedItemSubType={setChangedItemSubType}
              settlingSubTypes={settlingSubTypes}
              kundeSubTypes={kundeSubTypes}
              filteredEditWithoutCustomers={locationsWithoutCustomers}
              deleteCustomer={onDelete}
              cancelEdit={handleCancelEdit}
              saveChanges={handleSaveChanges}
            />
          </Draggable>
        )}

        {showDistList && (
          <Draggable
            initialPosition={{ x: `calc(${sideBarWidth} + 10px)`, y: "15vh" }}
            bounds={{
              minX: `calc(${sideBarWidth} + 10px)`,
              maxX: "100vw",
              minY: "10px",
              maxY: "100vh"
            }}
          >
            <DistanceList
              customerRadius={customerRadius}
              settlingRadius={settlingRadius}
              infoGroups={infoGroups}
              locations={locations}
              handleSetCoordsToShowRoute={handleSetCoordsToShowRoute}
              coordsToShowRoute={coordsToShowRoute}
              setShowDistList={setShowDistList}
            />
          </Draggable>
        )}
        {activeSettingsView === SettingsView.PERMISSIONS && (
          <PermissionsTable close={handleCloseSettings} />
        )}
        {activeSettingsView === SettingsView.MATERIALS && (
          <MaterialsTable close={handleCloseSettings} />
        )}
      </div>

      <>
        <div
          className="position-absolute bg-light"
          style={{ bottom: 70, left: 0, width: "100%" }}
        >
          {!!(
            itemIdToEdit &&
            changedItemDesc &&
            changedItemSubType &&
            changedItemType &&
            changedItemType !== Category.CUSTOMERS
          ) &&
            allowEditNonCustomers && (
              <EditLocationForm
                key={itemIdToEdit}
                itemIdToEdit={itemIdToEdit}
                changedItemDesc={changedItemDesc}
                setChangedItemDesc={setChangedItemDesc}
                changedItemFormattedAddress={changedItemFormattedAddress}
                setChangedItemFormattedAddress={setChangedItemFormattedAddress}
                setChangedItemLocation={setChangedItemLocation}
                changedItemType={changedItemType}
                handleSetChangedType={handleSetChangedType}
                changedItemSubType={changedItemSubType}
                filteredEditKundeSubtypes={filteredEditKundeSubtypes}
                kundeSubTypes={kundeSubTypes}
                materialEditIdToShowDropdown={materialEditIdToShowDropdown}
                setChangedItemSubType={setChangedItemSubType}
                setMaterialEditIdToShowDropdown={
                  setMaterialEditIdToShowDropdown
                }
                customerOwnerEditFilter={customerOwnerEditFilter}
                setShowEditOwnerDropDown={setShowEditOwnerDropDown}
                showEditOwnerDropDown={showEditOwnerDropDown}
                setCustomerOwnerEditFilter={setCustomerOwnerEditFilter}
                filteredEditWithoutCustomers={filteredEditWithoutCustomers}
                onDelete={(id: string) =>
                  onDelete(id).then(() => handleCancelEdit())
                }
                competitionSubTypes={competitionSubTypes}
                settlingSubTypes={settlingSubTypes}
                filteredEditCompetitionSubtypes={
                  filteredEditCompetitionSubtypes
                }
                filteredEditSettlingSubtypes={filteredEditSettlingSubtypes}
                setShowEditTypeDropDown={setShowEditTypeDropDown}
                showEditTypeDropDown={showEditTypeDropDown}
                handleCancelEdit={handleCancelEdit}
                handleSaveChanges={handleSaveChanges}
              />
            )}
          {!!selectedLocationId && (
            <LocationRadiusSelection
              item={
                locations.find(l => l.id === selectedLocationId) as Location
              }
              handleSelectLocation={handleSelectLocation}
              handleSelectedLocationRadiusChange={
                handleSelectedLocationRadiusChange
              }
              handleSelectedLocationRadius2Change={
                handleSelectedLocationRadius2Change
              }
              selectedLocationRadius={selectedLocationRadius}
              selectedLocationRadius2={selectedLocationRadius2}
              selectedLocationDates={selectedLocationDateFilters}
              updateSelectedLocationDates={setSelectedLocationDateFilters}
            />
          )}
          {!!(
            allowEditNonCustomers &&
            !itemIdToEdit &&
            activeCategory !== Category.CUSTOMERS
          ) && (
            <div className="p-2  py-4 border-top">
              <div
                className="w-100 position-relative"
                style={{
                  height: 35,
                  overflow: "hidden"
                }}
              >
                <AutocompleteWrapper
                  className="form-control form-control-sm w-100 h-100 pr-5 position-relative"
                  type="text"
                  disabled={!apiReady}
                  placeholder="Adresse eingeben"
                  aria-label="Search"
                  value={inputQuery}
                  onChange={e => onInputChange(e.target.value)}
                  onPlaceSelected={place => {
                    const query = place.formatted_address;
                    onInputChange(query);
                  }}
                />
                <SearchButton className="border-left">
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{
                      right: 15,
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      onSearch();
                    }}
                  />
                </SearchButton>
              </div>
            </div>
          )}
          {!!(
            allowEditNonCustomers &&
            showCreationForm &&
            type != Category.CUSTOMERS
          ) && (
            <div className="p-2 bg-light border-top">
              <CreationForm
                allowColorSelect={allowColorSelect}
                apiReady={apiReady}
                allSubTypes={allSubTypes}
                competitionSubTypes={competitionSubTypes}
                settlingSubTypes={settlingSubTypes}
                kundenSubTypes={kundeSubTypes}
                cancelCreation={cancelCreation}
                companyName={companyName}
                description={description}
                setDescription={setDescription}
                handleSave={handleSave}
                handleSetType={handleSetType}
                setSubType={setSubType}
                setType={setType}
                subType={subType}
                type={type}
              />
            </div>
          )}
        </div>
        {allowEditNonCustomers && type != Category.CUSTOMERS && (
          <div
            className="position-absolute px-2 d-flex justify-content-between align-items-center bg-light"
            style={{ bottom: 0, left: 0, width: "100%", height: 70 }}
          >
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={handleToggleEditMode}
                id="editToggle"
              />
              <label className="custom-control-label" htmlFor="editToggle">
                bearbeiten
              </label>
            </div>
          </div>
        )}
      </>
    </StyledSidebar>
  );
};

export default Sidebar;
