import React, { useState, useEffect, createContext } from "react";
import { Action, permissions, permissionsNew } from "../constants/permissions";
import { auth, db } from "../firebaseConfig";
import { logOut } from "../services/auth";
import { useModal } from "./ModalContext";
export const UserContext = createContext<{
  displayName: string | null;
  email: any;
  firebaseId: string;
} | null>(null);

const UserProvider: React.FC = props => {
  const [user, setUser] = useState<{
    displayName: string | null;
    email: any;
    firebaseId: string;
  } | null>(null);
  const { showModal } = useModal();

  useEffect(() => {
    auth.onAuthStateChanged(async fbUser => {
      if (fbUser === null) {
        setUser(null);
        return;
      }
      const ref = db.collection("profile");
      const dbUser = await ref.doc(fbUser.uid).get();
      if (!dbUser.exists) {
        console.log("no permission");
        showModal({
          message:
            "Sie haben sich erfolgreich angemeldet. Bitten Sie einen Administrator ihr Profil freizuschalten",
          type: "succes"
        });
        await ref.doc(fbUser.uid).set({
          email: fbUser.email,
          access: false,
          first_name: fbUser.displayName?.split(" ")[0] || "",
          last_name: fbUser.displayName?.split(" ")[1] || "",
          permissions: permissionsNew.map(permObj => {
            return {
              scope: permObj.scope,
              permissions: permObj.permissions.map(perm => {
                return {
                  name: perm.name,
                  read: perm.actions.includes(Action.READ) ? true : false,
                  write: perm.actions.includes(Action.WRITE) ? true : false
                };
              })
            };
          })
        });
        return;
      }
      const userData = dbUser.data();
      if (!userData?.first_name || !userData?.last_name) {
        await ref.doc(fbUser.uid).update({
          email: fbUser.email,
          access: userData?.access,
          first_name: fbUser.displayName?.split(" ")[0] || "",
          last_name: fbUser.displayName?.split(" ")[1] || ""
        });
      }

      if (dbUser.exists && !dbUser.data()?.access) {
        console.log("no permission");
        showModal({
          message:
            "Du darfst diese Seite nicht besuchen, wende dich bitte an Administrator",
          type: "alert"
        });
        return;
      } else {
        setUser({
          displayName: fbUser.displayName,
          email: fbUser.email,
          firebaseId: dbUser.id
        });
      }

      if (auth.currentUser) {
        console.warn(
          "Your token for http Requests",
          await auth.currentUser.getIdToken()
        );
      }
      showModal({ message: "Du bist jetzt eingeloggt", type: "succes" });
    });
  }, []);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
