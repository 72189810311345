import { faCheck, faRedo, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { throttle } from "lodash";
import React, { ChangeEvent, useCallback, useState, useRef } from "react";
import { Location } from "../../types";
import { Category } from "../../Views/Main";
import LocationRadiusSelection from "./LocationRadiusSelection";
import OptionsBar from "./OptionsBar";
export type DataRowProps = {
  item: Location;
  handleItemSwitch: (description: string) => void;
  setEditModalOpening: (value: React.SetStateAction<boolean>) => void;
  handleOpenEdit: (id: string) => void;
  hideColorBar?: boolean;
  selectedLocationId?: string;
  handleSelectLocation?: (locationId: string) => void;
  selectedLocationRadius?: number;
  selectedLocationRadius2?: number;
  selectedLocationDates?: {
    from?: string;
    to?: string;
  };
  inEditMode: boolean;
  updateSelectedLocationDates?: (nextFilters?: {
    from?: string;
    to?: string;
  }) => void;
  handleSelectedLocationRadiusChange?: (
    e: ChangeEvent<HTMLInputElement>
  ) => void;
  handleSelectedLocationRadius2Change?: (
    e: ChangeEvent<HTMLInputElement>
  ) => void;
};

const DataRow = ({
  item,
  handleItemSwitch,
  handleOpenEdit,
  setEditModalOpening,
  hideColorBar,
  selectedLocationId,
  handleSelectLocation,
  inEditMode
}: DataRowProps) => {
  const [showOptions, setShowOptions] = useState(false);

  const isNotCustomer =
    item.type.main === Category.SETTLING ||
    item.type.main === Category.COMPETITION ||
    item.type.main === Category.KUNDE;
  const isSelectedLocation = item.id === selectedLocationId;

  const onLocationSelect = useCallback(() => {
    if (!isNotCustomer || !handleSelectLocation) return;
    handleSelectLocation(item.id);
  }, [handleSelectLocation, isNotCustomer, item.id]);
  return (
    <div
      className={`position-relative w-100   border-bottom`}
      style={{
        backgroundColor: isSelectedLocation ? "#017BFE55" : "",
        height: 40,
        fontSize: "1.1rem",
        opacity: item.diactivated ? 0.5 : 1,
        pointerEvents: item.diactivated ? "none" : "auto"
      }}
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
    >
      <div className="pl-4 d-flex align-items-center h-100">
        <input
          type="checkbox"
          name={item.id}
          id={item.description}
          value={item.description}
          checked={item.switched}
          className="mr-3"
          style={{ cursor: "pointer", transform: "scale(1.15)" }}
          onChange={() => handleItemSwitch(item.description)}
        />
        <div style={{ fontSize: "1rem" }}>{item.description}</div>
        {!!(showOptions && !isSelectedLocation) && (
          <OptionsBar
            showSelectIcon={isNotCustomer}
            inEditMode={inEditMode}
            handleSelectLocation={onLocationSelect}
            handleOpenEdit={(e: any) => {
              e.stopPropagation();
              setEditModalOpening(true);
              setImmediate(() => {
                setEditModalOpening(false);
              });
              handleOpenEdit(item.id);
            }}
          />
        )}
        {!!(
          item.type.sub &&
          typeof item.type.sub !== "string" &&
          "color" in item.type.sub &&
          item.type.sub.color &&
          !hideColorBar
        ) && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              width: 5,
              backgroundColor: item.type.sub.color
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DataRow;
