import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Location } from "../../types";
import DataRow, { DataRowProps } from "./DataRow";
import EditLocationForm, { EditLocationFormProps } from "./EditLocationForm";

type GroupItemProps = {
  groupItem: {
    subtype:
      | string
      | {
          title: string;
          color: string;
        };
    items: Location[];
  };
  allSubTypes: {
    main: string;
    sub:
      | {
          title: string;
          color: string;
        }
      | string;
  }[];
  inEditMode: boolean;
  filteredEditSubtypes: {
    main: string;
    sub:
      | {
          title: string;
          color: string;
        }
      | string;
  }[];
  itemIdToEdit: string | null;
  selectedCategories: string[];
  handleSwitchAllOfSubType: (subtype: string) => void;
} & Omit<EditLocationFormProps, "item" | "onDelete"> &
  Omit<DataRowProps, "item" | "onDelete">;

const GroupItem = ({ groupItem, ...rest }: GroupItemProps) => {
  const [expanded, setExpanded] = useState(true);

  const handleClick = () => {
    setExpanded(!expanded);
  };
  return (
    <div>
      <div
        className="position-relative w-100 mt-2 group-header d-flex  border-bottom align-items-center"
        style={{ height: 40 }}
      >
        <span style={{ flex: 1, fontWeight: 700, fontSize: "1.1rem" }}>
          {typeof groupItem.subtype === "string"
            ? groupItem.subtype
            : groupItem.subtype.title}
        </span>
        <FontAwesomeIcon
          icon={expanded ? faChevronUp : faChevronDown}
          onClick={handleClick}
          style={{ cursor: "pointer", marginRight: 15 }}
        />

        {typeof groupItem.subtype === "object" && (
          <span
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              width: 5,
              backgroundColor: groupItem.subtype.color
            }}
          />
        )}
      </div>
      {expanded && (
        <div className="group-items">
          {groupItem.items.map((item: Location) => (
            <DataRow key={item.id} {...rest} item={item} hideColorBar={true} />
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupItem;
