import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

import { db } from "../firebaseConfig";
import { UserContext } from "./user";

export type Material = {
  id: string;
  name: string;
  color: string;
};

type MaterialsContextType = {
  materials: Material[];
  createMaterial: (name: string, color: string) => Promise<void>;
  deleteMaterial: (id: string) => Promise<void>;
  updateMaterial: (id: string, name: string, color: string) => Promise<void>;
};

const MaterialsContext = createContext(
  (null as unknown) as MaterialsContextType
);

export const useMaterials = () => useContext(MaterialsContext);

const MaterialsContextProvider: React.FC = ({ children }) => {
  const [materials, setMaterials] = useState<Material[]>([]);
  const user = useContext(UserContext);

  useEffect(() => {
    if (!user) return;
    const unsub = db.collection("materials").onSnapshot(snapshot => {
      const materials: Material[] = [];
      snapshot.forEach(doc => {
        const data = doc.data();
        materials.push({
          id: data.id,
          name: data.name,
          color: data.color
        });
      });
      setMaterials(materials);
    });
    return () => unsub();
  }, [user]);

  const createMaterial = useCallback(async (name, color) => {
    const id = await db.collection("materials").doc().id;
    await db.collection("materials").doc(id).set({ name, color, id });
  }, []);

  const updateMaterial = useCallback(async (id, name, color) => {
    await db.collection("materials").doc(id).update({ name, color });
  }, []);

  const deleteMaterial = useCallback(async id => {
    await db.collection("materials").doc(id).delete();
  }, []);

  return (
    <MaterialsContext.Provider
      value={{
        materials,
        createMaterial,
        updateMaterial,
        deleteMaterial
      }}
    >
      {children}
    </MaterialsContext.Provider>
  );
};

export default MaterialsContextProvider;
