import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback
} from "react";
import { Category } from "../Views/Main";
import { ActionSource, FirestoreChangeType, LogAction } from "../types";
import { db } from "../firebaseConfig";

type FirestoreLoggerContextType = {
  logAction: (
    action: FirestoreChangeType,
    itemId: string,
    itemType: Category
  ) => Promise<void>;
};

const FirestoreLoggerContext = createContext<FirestoreLoggerContextType>(
  (null as unknown) as FirestoreLoggerContextType
);
export const useFirestoreLogger = () =>
  React.useContext(FirestoreLoggerContext);
export const FirestoreLoggerContextProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const logAction = useCallback(
    async (action: FirestoreChangeType, itemId: string, itemType: Category) => {
      const toDay = new Date().toISOString().slice(0, 10);
      const logsResponse = await db.collection("logs").doc(toDay).get();
      if (!logsResponse.exists) {
        await db
          .collection("logs")
          .doc(toDay)
          .set({
            actions: [
              {
                itemId,
                action,
                actionSource: ActionSource.MAIN,
                itemType: itemType,
                timestamp: new Date().toISOString()
              }
            ]
          });
      } else {
        const logs = (logsResponse.data()?.actions || []) as LogAction[];
        await db
          .collection("logs")
          .doc(toDay)
          .update({
            actions: [
              ...logs,
              {
                itemId,
                action,
                actionSource: ActionSource.MAIN,
                itemType: itemType,
                timestamp: new Date().toISOString()
              }
            ]
          });
      }
    },
    []
  );

  return (
    <FirestoreLoggerContext.Provider value={{ logAction }}>
      {children}
    </FirestoreLoggerContext.Provider>
  );
};
