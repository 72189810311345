/* eslint-disable @typescript-eslint/ban-ts-comment */
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useCallback, useState } from "react";
import { DropdownItem } from ".";
import { useMapsContext } from "../../context/MapsContext";
import {
  CompetitionSubType,
  CustomerSubType,
  KundeSubType,
  Location,
  Owner,
  SettlingSubType
} from "../../types";
import { Category } from "../../Views/Main";
import AutocompleteWrapper from "../Shared/autocomplete";
import { getSettlingType2DependingOnType1 } from "./CreationForm";
import Select from "react-select";
import { Material, useMaterials } from "../../context/MaterialsContext";
import chroma from "chroma-js";
import { db } from "../../firebaseConfig";

export type EditLocationFormProps = {
  changedItemFormattedAddress: string | null;
  setChangedItemFormattedAddress: (value: string | null) => void;
  setChangedItemLocation: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    } | null>
  >;
  filteredEditKundeSubtypes: {
    main: string;
    sub: KundeSubType;
  }[];
  changedItemDesc: string;
  setChangedItemDesc: (value: string | null) => void;
  changedItemType: string;
  handleSetChangedType: (value: string) => void;
  changedItemSubType:
    | CompetitionSubType
    | SettlingSubType
    | CustomerSubType
    | KundeSubType
    | null;
  setChangedItemSubType: (
    value:
      | CompetitionSubType
      | SettlingSubType
      | CustomerSubType
      | KundeSubType
      | null
  ) => void;
  materialEditIdToShowDropdown: string;
  setMaterialEditIdToShowDropdown: (value: string) => void;
  customerOwnerEditFilter: string;
  setCustomerOwnerEditFilter: (value: string) => void;
  setShowEditOwnerDropDown: (value: React.SetStateAction<boolean>) => void;
  showEditOwnerDropDown: boolean;
  filteredEditWithoutCustomers: Owner[];
  showEditTypeDropDown: boolean;
  setShowEditTypeDropDown: (value: boolean) => void;
  settlingSubTypes: {
    main: string;
    sub: SettlingSubType | string;
  }[];
  kundeSubTypes: {
    main: string;
    sub: KundeSubType;
  }[];
  competitionSubTypes: {
    main: string;
    sub: CompetitionSubType | string;
  }[];
  filteredEditCompetitionSubtypes: {
    main: string;
    sub: CompetitionSubType | string;
  }[];
  onDelete: (id: string) => Promise<void>;
  filteredEditSettlingSubtypes: {
    main: string;
    sub: SettlingSubType | string;
  }[];
  handleCancelEdit: () => void;
  itemIdToEdit: string | null;
  handleSaveChanges: () => Promise<void>;
};

const EditLocationForm = ({
  changedItemDesc,
  setChangedItemDesc,
  changedItemFormattedAddress,
  setChangedItemFormattedAddress,
  setChangedItemLocation,
  changedItemType,
  changedItemSubType,
  materialEditIdToShowDropdown,
  setChangedItemSubType,
  setMaterialEditIdToShowDropdown,
  customerOwnerEditFilter,
  setShowEditOwnerDropDown,
  showEditOwnerDropDown,
  setCustomerOwnerEditFilter,
  filteredEditWithoutCustomers,
  onDelete,
  itemIdToEdit,
  competitionSubTypes,
  settlingSubTypes,
  filteredEditCompetitionSubtypes,
  filteredEditSettlingSubtypes,
  setShowEditTypeDropDown,
  showEditTypeDropDown,
  handleCancelEdit,
  handleSaveChanges,
  kundeSubTypes,
  filteredEditKundeSubtypes
}: EditLocationFormProps) => {
  const { apiReady, maps } = useMapsContext();
  const [showType1DropDown, setShowType1DropDown] = useState(false);
  const [showType2DropDown, setShowType2DropDown] = useState(false);
  const { materials } = useMaterials();

  console.log(changedItemSubType);

  const handlePlaceSelected = useCallback(
    (place: any) => {
      const formattedAddress = place.formatted_address;
      const location = place.geometry.location;
      setChangedItemFormattedAddress(formattedAddress);
      setChangedItemLocation({
        lat: location.lat() as number,
        lng: location.lng() as number
      });
    },
    [setChangedItemFormattedAddress, setChangedItemLocation]
  );
  return (
    <div
      className="position-relative  w-100  border-bottom"
      style={{
        borderTop: "1px solid #999999"
      }}
    >
      <div
        className="w-100 d-flex p-2 align-items-center position-relative"
        style={{
          gap: 20,
          borderTop: "1px solid #999999",
          borderBottom: "1px solid #999999"
        }}
      >
        <h5 className="mb-0">{changedItemDesc}</h5>
      </div>
      <div className="w-100 p-2">
        <div className="mb-1 ">
          <AutocompleteWrapper
            type="text"
            className="form-control w-75 position-relative"
            style={{ fontSize: ".9rem" }}
            disabled={!apiReady}
            placeholder="Adresse eingeben"
            value={changedItemFormattedAddress || ""}
            aria-label="Search"
            onPlaceSelected={handlePlaceSelected}
          />
        </div>

        <input
          type="text"
          className="form-control w-75"
          style={{ fontSize: ".9rem" }}
          value={changedItemDesc}
          onChange={e => setChangedItemDesc(e.target.value)}
        />

        <div className="mt-2 pl-1 d-flex flex-column">
          {!!(
            changedItemType === Category.SETTLING &&
            changedItemSubType &&
            "title" in changedItemSubType &&
            "color" in changedItemSubType &&
            "kifaId" in changedItemSubType
          ) && (
            <>
              <div className="w-75 position-relative">
                <input
                  className="w-100 form-control"
                  style={{ height: 35 }}
                  placeholder="Kategorie"
                  type="text"
                  value={changedItemSubType.title}
                  onChange={e => {
                    const exists = settlingSubTypes.find(s =>
                      typeof s.sub === "string"
                        ? s.sub === e.target.value
                        : s.sub.title === e.target.value
                    );
                    if (exists) {
                      setChangedItemSubType({
                        ...changedItemSubType,
                        title:
                          typeof exists.sub === "string"
                            ? exists.sub
                            : exists.sub.title,
                        color:
                          typeof exists.sub === "string" ? "" : exists.sub.color
                      });
                    } else {
                      setChangedItemSubType({
                        ...changedItemSubType,
                        title: e.target.value,
                        color: ""
                      });
                    }
                  }}
                />
                <FontAwesomeIcon
                  icon={showEditTypeDropDown ? faChevronUp : faChevronDown}
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    setShowEditTypeDropDown(!showEditTypeDropDown);
                  }}
                />
                {!!(settlingSubTypes.length && showEditTypeDropDown) && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: 100,
                      maxHeight: 300,
                      overflowY: "scroll",
                      left: 0,
                      right: 0
                    }}
                  >
                    {filteredEditSettlingSubtypes.map((i, index) =>
                      i.sub ? (
                        <DropdownItem
                          key={index}
                          className="p-1 bg-white border-bottom border-right border-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (typeof i.sub === "string") {
                              const title = i.sub;
                              setChangedItemSubType({
                                __typename: Category.SETTLING,
                                title: title,
                                color: "#dc3545",
                                materials: [],
                                kifaId: null,
                                type1: "",
                                type2: ""
                              });
                            } else {
                              const item = i.sub;
                              const newSubType: SettlingSubType = {
                                __typename: Category.SETTLING,
                                title: item.title,
                                color: item.color,
                                kifaId: item.kifaId,
                                materials: item.materials,
                                type1: "",
                                type2: ""
                              };
                              setChangedItemSubType(newSubType);
                            }
                            setImmediate(() => {
                              setShowEditTypeDropDown(false);
                            });
                          }}
                        >
                          {typeof i.sub === "string" ? i.sub : i.sub.title}
                        </DropdownItem>
                      ) : null
                    )}
                  </div>
                )}
              </div>
              <div className="mt-1 w-75 position-relative">
                <input
                  type="text"
                  className="w-100 form-control"
                  value={changedItemSubType.color}
                  readOnly
                  placeholder="Farbe wählen"
                  style={{ height: 35 }}
                />
                <input
                  type="color"
                  className="position-absolute"
                  placeholder="Farbe wählen"
                  value={changedItemSubType.color}
                  style={{
                    height: 35,
                    width: 35,
                    right: 0,
                    top: 0,
                    cursor: "pointer"
                  }}
                  onChange={e =>
                    setChangedItemSubType({
                      ...changedItemSubType,
                      title: changedItemSubType.title,
                      color: e.target.value
                    })
                  }
                />
              </div>
              {changedItemSubType.__typename === Category.SETTLING &&
                "kifaId" in changedItemSubType && (
                  <>
                    <div className="w-100 d-flex mt-1" style={{ gap: 10 }}>
                      <div className="col-5 position-relative p-0">
                        <input
                          className="form-control"
                          style={{ height: 35 }}
                          placeholder="Art1"
                          type="text"
                          value={changedItemSubType.type1}
                          readOnly
                        />
                        <FontAwesomeIcon
                          icon={showType1DropDown ? faChevronUp : faChevronDown}
                          style={{
                            position: "absolute",
                            right: 10,
                            top: 10,
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            setShowType1DropDown(!showType1DropDown)
                          }
                        />
                        {showType1DropDown && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              zIndex: 100,
                              maxHeight: 300,
                              overflowY: "scroll",
                              left: 0,
                              right: 0
                            }}
                          >
                            {["Mischwerk", "Rohstoffbetrieb", "Baubetrieb"].map(
                              (it, index) => (
                                <DropdownItem
                                  key={index}
                                  className="p-1 bg-white border-bottom border-right border-left"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const type1 = it;
                                    let type2 = changedItemSubType.type2;
                                    if (type1 !== changedItemSubType.type1) {
                                      type2 = "";
                                    }
                                    setChangedItemSubType({
                                      ...changedItemSubType,
                                      type1,
                                      type2
                                    });
                                    setImmediate(() =>
                                      setShowType1DropDown(false)
                                    );
                                  }}
                                >
                                  {it}
                                </DropdownItem>
                              )
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-5 position-relative p-0">
                        <input
                          className="form-control"
                          style={{ height: 35 }}
                          placeholder="Art2"
                          type="text"
                          value={changedItemSubType.type2}
                          readOnly
                        />
                        <FontAwesomeIcon
                          icon={showType2DropDown ? faChevronUp : faChevronDown}
                          style={{
                            position: "absolute",
                            right: 10,
                            top: 10,
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            setShowType2DropDown(!showType2DropDown)
                          }
                        />
                        {showType2DropDown && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              zIndex: 100,
                              maxHeight: 300,
                              overflowY: "scroll",
                              left: 0,
                              right: 0
                            }}
                          >
                            {getSettlingType2DependingOnType1(
                              changedItemSubType.type1
                            ).map((it, index) => (
                              <DropdownItem
                                key={index}
                                className="p-1 bg-white border-bottom border-right border-left"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  const type2 = it;

                                  setChangedItemSubType({
                                    ...changedItemSubType,
                                    type2
                                  });
                                  setImmediate(() =>
                                    setShowType2DropDown(false)
                                  );
                                }}
                              >
                                {it}
                              </DropdownItem>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

              <div className="mt-1 w-50 position-relative">
                <label>Kifa-ID</label>
                <input
                  type="text"
                  className="w-100 form-control"
                  value={changedItemSubType.kifaId || ""}
                  onChange={e => {
                    setChangedItemSubType({
                      ...changedItemSubType,
                      kifaId: e.target.value
                    });
                  }}
                  placeholder="Kifa-ID"
                  style={{ height: 35 }}
                />
              </div>
              <hr />
            </>
          )}

          {!!(
            changedItemType === Category.KUNDE &&
            changedItemSubType &&
            "title" in changedItemSubType &&
            "color" in changedItemSubType &&
            "tonnage" in changedItemSubType
          ) && (
            <>
              <div className="w-75 position-relative mb-2">
                <label>
                  Tonnage
                  <input
                    className="w-100 form-control"
                    style={{ height: 35 }}
                    type="number"
                    step={1}
                    value={changedItemSubType.tonnage}
                    onChange={e =>
                      setChangedItemSubType({
                        ...changedItemSubType,
                        tonnage: +e.target.value
                      })
                    }
                  />
                </label>
              </div>
              <div className="w-75 position-relative">
                <input
                  className="w-100 form-control"
                  style={{ height: 35 }}
                  placeholder="Kategorie"
                  type="text"
                  value={changedItemSubType.title}
                  onChange={e => {
                    const exists = kundeSubTypes.find(
                      s => s.sub.title === e.target.value
                    );
                    if (exists) {
                      setChangedItemSubType({
                        ...changedItemSubType,
                        title: exists.sub.title,
                        color: exists.sub.color
                      });
                    } else {
                      setChangedItemSubType({
                        ...changedItemSubType,
                        title: e.target.value,
                        color: ""
                      });
                    }
                  }}
                />
                <FontAwesomeIcon
                  icon={showEditTypeDropDown ? faChevronUp : faChevronDown}
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    setShowEditTypeDropDown(!showEditTypeDropDown);
                  }}
                />
                {!!(kundeSubTypes.length && showEditTypeDropDown) && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: 100,
                      maxHeight: 300,
                      overflowY: "scroll",
                      left: 0,
                      right: 0
                    }}
                  >
                    {filteredEditKundeSubtypes.map((i, index) =>
                      i.sub ? (
                        <DropdownItem
                          key={index}
                          className="p-1 bg-white border-bottom border-right border-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const item = i.sub;
                            const newSubType: KundeSubType = {
                              __typename: Category.KUNDE,
                              title: item.title,
                              color: item.color,
                              materials: item.materials,
                              tonnage: item.tonnage
                            };
                            setChangedItemSubType(newSubType);

                            setImmediate(() => {
                              setShowEditTypeDropDown(false);
                            });
                          }}
                        >
                          {typeof i.sub === "string" ? i.sub : i.sub.title}
                        </DropdownItem>
                      ) : null
                    )}
                  </div>
                )}
              </div>
              <div className="mt-1 w-75 position-relative">
                <input
                  type="text"
                  className="w-100 form-control"
                  value={changedItemSubType.color}
                  readOnly
                  placeholder="Farbe wählen"
                  style={{ height: 35 }}
                />
                <input
                  type="color"
                  className="position-absolute"
                  placeholder="Farbe wählen"
                  value={changedItemSubType.color}
                  style={{
                    height: 35,
                    width: 35,
                    right: 0,
                    top: 0,
                    cursor: "pointer"
                  }}
                  onChange={e =>
                    setChangedItemSubType({
                      ...changedItemSubType,
                      title: changedItemSubType.title,
                      color: e.target.value
                    })
                  }
                />
              </div>
              <hr />
            </>
          )}

          {!!(
            changedItemType === Category.COMPETITION &&
            changedItemSubType &&
            "title" in changedItemSubType &&
            "color" in changedItemSubType
          ) && (
            <>
              <div className="w-75 position-relative">
                <input
                  className="w-100 form-control"
                  style={{ height: 35 }}
                  placeholder="Kategorie"
                  type="text"
                  value={changedItemSubType.title}
                  onChange={e => {
                    const exists = competitionSubTypes.find(s =>
                      typeof s.sub === "string"
                        ? s.sub === e.target.value
                        : s.sub.title === e.target.value
                    );
                    if (exists) {
                      setChangedItemSubType({
                        ...changedItemSubType,
                        title:
                          typeof exists.sub === "string"
                            ? exists.sub
                            : exists.sub.title,
                        color:
                          typeof exists.sub === "string" ? "" : exists.sub.color
                      });
                    } else {
                      setChangedItemSubType({
                        ...changedItemSubType,
                        title: e.target.value,
                        color: ""
                      });
                    }
                  }}
                />
                <FontAwesomeIcon
                  icon={showEditTypeDropDown ? faChevronUp : faChevronDown}
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    setShowEditTypeDropDown(!showEditTypeDropDown);
                  }}
                />
                {!!(competitionSubTypes.length && showEditTypeDropDown) && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      zIndex: 100,
                      maxHeight: 300,
                      overflowY: "scroll",
                      left: 0,
                      right: 0
                    }}
                  >
                    {filteredEditCompetitionSubtypes.map((i, index) =>
                      i.sub ? (
                        <DropdownItem
                          key={index}
                          className="p-1 bg-white border-bottom border-right border-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (typeof i.sub === "string") {
                              const title = i.sub;
                              setChangedItemSubType({
                                ...changedItemSubType,
                                title: title,
                                color: "#dc3545"
                              });
                            } else {
                              const item = i.sub;
                              setChangedItemSubType({
                                ...changedItemSubType,
                                title: item.title,
                                color: item.color
                              });
                            }
                            setImmediate(() => {
                              setShowEditTypeDropDown(false);
                            });
                          }}
                        >
                          {typeof i.sub === "string" ? i.sub : i.sub.title}
                        </DropdownItem>
                      ) : null
                    )}
                  </div>
                )}
              </div>
              <div className="mt-1 w-75 position-relative">
                <input
                  type="text"
                  className="w-100 form-control"
                  value={changedItemSubType.color}
                  readOnly
                  placeholder="Farbe wählen"
                  style={{ height: 35 }}
                />
                <input
                  type="color"
                  className="position-absolute"
                  placeholder="Farbe wählen"
                  value={changedItemSubType.color}
                  style={{
                    height: 35,
                    width: 35,
                    right: 0,
                    top: 0,
                    cursor: "pointer"
                  }}
                  onChange={e =>
                    setChangedItemSubType({
                      ...changedItemSubType,
                      title: changedItemSubType.title,
                      color: e.target.value
                    })
                  }
                />
              </div>
              {"kifaId" in changedItemSubType && (
                <div className="mt-1 w-50 position-relative">
                  <label>Kifa-ID</label>
                  <input
                    type="text"
                    className="w-100 form-control"
                    value={changedItemSubType.kifaId || ""}
                    onChange={e => {
                      setChangedItemSubType({
                        ...changedItemSubType,
                        kifaId: e.target.value
                      });
                    }}
                    placeholder="Kifa-ID"
                    style={{ height: 35 }}
                  />
                </div>
              )}
            </>
          )}
          <div className="form-group mb-0">
            <label htmlFor="exampleInputEmail1">Materialen</label>
            {!!(
              changedItemSubType &&
              (changedItemType === Category.COMPETITION ||
                changedItemType === Category.SETTLING ||
                changedItemType === Category.KUNDE)
            ) && (
              <Select
                isMulti
                name="materials"
                options={materials.map(val => ({
                  value: val,
                  label: val.name
                }))}
                value={(changedItemSubType as
                  | CompetitionSubType
                  | SettlingSubType).materials
                  .map(val => materials.find(it => it.id === val.id))
                  .map(val => ({
                    value: val as Material,
                    label: val?.name
                  }))}
                maxMenuHeight={100}
                styles={{
                  menu: styles => ({ ...styles, zIndex: 100 }),
                  multiValue: (styles, { data }) => ({
                    ...styles,
                    backgroundColor: chroma(data.value.color).alpha(0.1).css()
                  }),
                  multiValueLabel: (styles, { data }) => ({
                    ...styles,
                    color: data.value.color
                  })
                }}
                onChange={val => {
                  setChangedItemSubType({
                    ...(changedItemSubType as
                      | CompetitionSubType
                      | SettlingSubType),
                    materials: val.map(it =>
                      db.collection("materials").doc(it.value.id)
                    )
                  });
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            )}
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between mt-3">
          <button
            className="btn btn-outline-secondary"
            onClick={handleCancelEdit}
          >
            Abbrechen
          </button>
          <button
            className="btn btn-outline-danger"
            onClick={() => (itemIdToEdit ? onDelete(itemIdToEdit) : null)}
          >
            Löschen
          </button>
          <button
            className="btn btn-outline-success"
            onClick={handleSaveChanges}
          >
            Speichern
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditLocationForm;
