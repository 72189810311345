import { faAngleDoubleLeft } from "@fortawesome/pro-solid-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/pro-solid-svg-icons/faAngleDoubleRight";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faDownload } from "@fortawesome/pro-solid-svg-icons/faDownload";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleMapReact from "google-map-react";
import firebase from "firebase";
import isEqual from "lodash/isEqual";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Category, MarkerMode, TableSize } from "../../Views/Main";
import {
  CompetitionSubType,
  CustomerSubType,
  InfoGroup,
  KundeSubType,
  Location,
  SettlingSubType
} from "../../types";
import { withoutUmlauts } from "../../util/textFormat";
import { exportCSV } from "../../util/generateCsv";
import { useDomainContext } from "../../context/DomainContext";
import { useMapsContext } from "../../context/MapsContext";
import { useMaterials } from "../../context/MaterialsContext";
import { useModal } from "../../context/ModalContext";
import Marker from "./Marker";
import useSupercluster from "use-supercluster";
import "./map.css";
const MarkerWrapper: FC<any> = ({ children }) => children;

type MapAreaProps = {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  maps: any;
  map: any;
  setMapZoom: React.Dispatch<number>;
  locations: Location[];
  customerRadius: number;
  setSettlingRadius: React.Dispatch<React.SetStateAction<number>>;
  setCustomerRadius: React.Dispatch<React.SetStateAction<number>>;
  settlingRadius: number;
  selectedCustomerId: string;
  selectedLocationId: string;
  selectedLocationRadius: number;
  selectedLocationRadius2: number;
  customersForMap: Location[] | null;
  selectedLocationDateFilters?: {
    from?: string | undefined;
    to?: string | undefined;
  };
  infoGroups: InfoGroup[];
  searchedLocation: {
    location: {
      lat: number;
      lng: number;
    };
    formatted_address: string;
  } | null;
  setSearchedLocation: React.Dispatch<
    React.SetStateAction<{
      location: {
        lat: number;
        lng: number;
      };
      formatted_address: string;
    } | null>
  >;
  editLocation: {
    location: {
      lat: number;
      lng: number;
    };
    formatted_address: string;
  } | null;
  setEditLocationForMap: (
    location: {
      location: {
        lat: number;
        lng: number;
      };
      formatted_address: string;
    } | null
  ) => void;
  groupsToShow: Category[];
  setGroupsToShow: React.Dispatch<React.SetStateAction<Category[]>>;
  activeCategory: string;
  tableView: TableSize;
  setTableView: (value: TableSize) => void;
};

const StyledMapArea = styled.div`
  flex-grow: 1;
  position: relative;
`;

//function to convert degrees to radians
function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

//function to calculate distance
function getDistance(
  origin: {
    lat: number;
    lng: number;
  },
  dest: {
    lat: number;
    lng: number;
  }
) {
  // radius of the earth in kilometers
  const earthRadiusKm = 6371;

  //calculate the difference between latitudes and longitudes
  const dLat = deg2rad(dest.lat - origin.lat);
  const dLon = deg2rad(dest.lng - origin.lng);

  //use Haversine formula to calculate the distance
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(origin.lat)) *
      Math.cos(deg2rad(dest.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadiusKm * c; // Distance in km
  return distance;
}
const parseDateToGermanFormat = (date: string) => {
  if (!date) return null;
  const [year, month, day] = date.split("-");
  return `${day}.${month}.${year}`;
};

const getFormattedSubmissDate = (date: string | undefined) => {
  if (!date) return "...";

  const [year, month, day] = date.split("-");

  return `${day}.${month}.${year}`;
};

const getLocaleType = (type: string) => {
  switch (type) {
    case Category.CUSTOMERS:
      return "Baustelle";
    case Category.SETTLING:
      return "Niederlassung";
    case Category.COMPETITION:
      return "Konkurrenz";
    case Category.KUNDE:
      return "Kunde";
    default:
      return "";
  }
};

export type PopulatedCustomerSubType = {
  materials: {
    tonnage: number;
    category: firebase.firestore.DocumentReference;
    id: string;
  }[];
  settling: Location[];
  winner_first_level: Location | null;
  winner_second_level: Location | null;
  kunde: Location | null;
  object_number?: number | null;
  nearest_neighbours: {
    description: string;
    type: Category.COMPETITION | Category.SETTLING;
    location: { lat: number; lng: number };
    distance: string;
  }[];
  construction_time: {
    from: string;
    to: string;
  };
  submiss_date?: string;
  notes?: string;
  __typename: Category.CUSTOMERS;
};

export type PopulatedCustomerLocation = {
  switched?: boolean;
  location: {
    lat: number;
    lng: number;
  };
  formatted_address: string;
  description: string;
  type: {
    main: string;
    sub: PopulatedCustomerSubType;
  };
  id: string;
  created_at?: string;
};

const MapArea = ({
  center,
  zoom,
  locations,
  setMapZoom,
  maps,
  map,
  setCustomerRadius,
  setSettlingRadius,
  customerRadius,
  settlingRadius,
  selectedCustomerId,
  selectedLocationId,
  selectedLocationRadius,
  selectedLocationRadius2,
  selectedLocationDateFilters,
  infoGroups,
  searchedLocation,
  setSearchedLocation,
  customersForMap,
  editLocation,
  setEditLocationForMap,
  groupsToShow,
  setGroupsToShow,
  activeCategory,
  setTableView,
  tableView
}: MapAreaProps) => {
  const [colorMode, setColorMode] = useState<"rgb" | "bw">("rgb");
  const [clickedMarkerData, sertClickedMarkerData] = useState<Location | null>(
    null
  );
  const [populatedCustomers, setPopulatedCustomers] = useState<
    PopulatedCustomerLocation[]
  >([]);
  const [activeMarkerMode, setActiveMarkerMode] = useState<MarkerMode>(
    MarkerMode.DEFAULT
  );
  const [showMapMaterialFilter, setShowMapMaterialFilter] = useState(false);
  const [
    customerRelevantData,
    setCustomerRelevantData
  ] = useState<PopulatedCustomerSubType | null>(null);
  const { handleApiReady } = useMapsContext();
  const { showModal } = useModal();
  const { domain } = useDomainContext();
  const { materials } = useMaterials();
  const [hiddenMaterials, setHiddenMaterials] = useState<string[]>([]);
  const [bounds, setBounds] = useState<number[]>([]);

  const selectedLocation = locations.find(loc => loc.id === selectedLocationId);

  useEffect(() => {
    if (activeMarkerMode === MarkerMode.MATERIALS) {
      setShowMapMaterialFilter(true);
    } else {
      setShowMapMaterialFilter(false);
      setHiddenMaterials([]);
    }
  }, [activeMarkerMode]);

  const assignedMaterials = useMemo(() => {
    return materials.filter(
      m =>
        populatedCustomers.some(c =>
          c.type.sub.materials.some(ma => ma.category.id === m.id)
        ) ||
        locations
          .filter(l => l.type.main !== Category.CUSTOMERS)
          .some(l =>
            (l.type.sub as
              | SettlingSubType
              | CompetitionSubType
              | KundeSubType).materials.some(mm => m.id == mm.id)
          )
    );
  }, [locations, materials, populatedCustomers]);

  useEffect(() => {
    if (!locations.length) return;
    const populateCustomers = async () => {
      const populatedCustomers = await Promise.all(
        locations
          .filter(l => l.type.main === Category.CUSTOMERS)
          .map(async customer => {
            const sub = customer.type.sub as CustomerSubType;
            const winnerSecondLevel = sub.winner_second_level
              ? await sub.winner_second_level
                  .get()
                  .then((doc: any) => doc.data() as Location)
              : null;
            const winnerFirstLevel = sub.winner_first_level
              ? await sub.winner_first_level
                  .get()
                  .then((doc: any) => doc.data() as Location)
              : null;
            const kunde = sub.kunde
              ? await sub.kunde.get().then((doc: any) => doc.data() as Location)
              : null;
            return {
              ...customer,
              type: {
                main: Category.CUSTOMERS,
                sub: {
                  ...customer.type.sub,
                  winner_second_level: winnerSecondLevel,
                  winner_first_level: winnerFirstLevel,
                  kunde: kunde
                }
              }
            } as PopulatedCustomerLocation;
          })
      );
      setPopulatedCustomers(populatedCustomers);
    };
    populateCustomers();
  }, [locations]);

  useEffect(() => {
    if (clickedMarkerData && clickedMarkerData.type.main === "customers") {
      const customer = populatedCustomers.find(
        c => c.id === clickedMarkerData.id
      );
      if (customer) {
        setCustomerRelevantData(customer.type.sub);
      }
    }
  }, [clickedMarkerData, populatedCustomers]);

  useEffect(() => {
    if (!map) return;
    const style = [
      { elementType: "geometry", stylers: [{ saturation: -100 }] }
    ];
    const mapType = new maps.StyledMapType(style, { name: "Greyscale" });
    map.mapTypes.set("grey", mapType);
  }, [map, maps]);

  const markerModes = [
    { title: "Standard", text: "ST", marker: MarkerMode.DEFAULT },
    { title: "Materialen", text: "MA", marker: MarkerMode.MATERIALS }
  ];

  useEffect(() => {
    if (!map) return;
    if (searchedLocation) {
      map.panTo(
        new maps.LatLng(
          searchedLocation.location.lat,
          searchedLocation.location.lng
        )
      );
    }
    if (editLocation) {
      map.panTo(
        new maps.LatLng(editLocation.location.lat, editLocation.location.lng)
      );
    }
  }, [searchedLocation, editLocation, map, maps]);

  useEffect(() => {
    if (!map) return;
    if (colorMode === "bw") {
      map.setMapTypeId("grey");
    } else {
      map.setMapTypeId("roadmap");
    }
  }, [colorMode, map]);

  const filteredLocations = useMemo(() => {
    if (selectedLocationId && selectedLocation) {
      let filteredLocations = locations.filter(
        loc =>
          (loc.type.main === Category.CUSTOMERS ||
            loc.type.main === Category.COMPETITION ||
            loc.type.main === Category.SETTLING) &&
          loc.domain === domain &&
          getDistance(loc.location, selectedLocation.location) <=
            selectedLocationRadius
      );
      if (selectedLocationRadius2) {
        filteredLocations.push(
          ...locations.filter(
            loc =>
              !!(
                loc.type.main === Category.COMPETITION ||
                loc.type.main === Category.SETTLING
              ) &&
              getDistance(loc.location, selectedLocation.location) <=
                selectedLocationRadius2 &&
              getDistance(loc.location, selectedLocation.location) >
                selectedLocationRadius
          )
        );
      }
      if (selectedLocationDateFilters) {
        filteredLocations = filteredLocations.filter(loc => {
          if (
            loc.type.sub.__typename === Category.CUSTOMERS &&
            loc.type.sub.submiss_date
          ) {
            if (
              selectedLocationDateFilters.from &&
              new Date(loc.type.sub.submiss_date.replaceAll("-", "/")) <
                new Date(selectedLocationDateFilters.from.replaceAll("-", "/"))
            ) {
              return false;
            }
            if (
              selectedLocationDateFilters.to &&
              new Date(loc.type.sub.submiss_date.replaceAll("-", "/")) >
                new Date(selectedLocationDateFilters.to.replaceAll("-", "/"))
            ) {
              return false;
            }
            return true;
          } else return true;
        });
        if (
          Object.values(selectedLocationDateFilters).filter(Boolean).length &&
          filteredLocations.some(
            loc =>
              loc.type.main === "customers" &&
              !(loc.type.sub as CustomerSubType).submiss_date
          )
        ) {
          showModal({
            message:
              "Bei einigen Baustellen in dem gewählten Umkreis fehlt das Submissionsdatum.",
            type: "warning"
          });
        }
      }
      return filteredLocations
        .filter(l => groupsToShow.includes(l.type.main as Category))
        .filter(c => c.switched && c.domain === domain)
        .map(val =>
          val.type.main === Category.CUSTOMERS
            ? populatedCustomers.find(v => v.id === val.id)
            : val
        ) as Location[];
    }
    if (!selectedCustomerId || !infoGroups.length) {
      const data = locations.filter(l =>
        groupsToShow.includes(l.type.main as Category)
      );
      if (customersForMap) {
        return data.filter(d =>
          d.type.main === Category.CUSTOMERS && d.domain === domain
            ? !!customersForMap.find(c => c.id === d.id)
            : true
        );
      }
      return data;
    }
    const selectedCustomer = locations.find(i => i.id === selectedCustomerId);

    if (selectedCustomer && typeof selectedCustomer.type.sub === "object") {
      const subType = selectedCustomer.type.sub as CustomerSubType;
      return [
        selectedCustomer,
        ...subType.nearest_neighbours.map(
          n =>
            locations.find(
              l =>
                l.description === n.description &&
                isEqual(l.location, n.location)
            ) as Location
        ),
        ...locations
          .filter(l => l.switched && l.domain === domain)
          .filter(i => {
            if (customerRadius === 0 || customerRadius === 0) return false;
            const igItem = infoGroups[0].distances.find(
              d => d.title.title === i.description
            );
            if (!igItem) return false;
            return (
              +igItem.distance <= customerRadius &&
              !subType.nearest_neighbours.find(
                n => igItem.title.title === n.description
              ) &&
              groupsToShow.includes(i.type.main as Category)
            );
          })
      ];
    }
    return [];
  }, [
    selectedLocationId,
    selectedLocation,
    selectedCustomerId,
    infoGroups,
    locations,
    selectedLocationRadius2,
    selectedLocationDateFilters,
    domain,
    selectedLocationRadius,
    showModal,
    populatedCustomers,
    customersForMap,
    groupsToShow,
    customerRadius
  ]);

  const switchButtons = [
    {
      title: "Niederlassungen",
      text: "NL",
      category: Category.SETTLING
    },
    {
      title: "Kunden",
      text: "KD",
      category: Category.KUNDE
    },
    {
      title: "Baustellen",
      text: "BS",
      category: Category.CUSTOMERS
    },
    {
      title: "Konkurrenz",
      text: "KO",
      category: Category.COMPETITION
    }
  ];

  const getBadgeData = (location: Location) => {
    // If the location is not a customer or the customer is not the selected customer, return null
    if (
      location.type.main !== Category.CUSTOMERS ||
      !location.type.sub ||
      selectedCustomerId !== location.id
    )
      return null;
    const customer = populatedCustomers.find(c => c.id === location.id);
    const subType = customer?.type.sub;
    const secondLevel = subType?.winner_second_level;
    // If there is a first and second level winner, return the descriptions of both
    if (
      subType?.winner_first_level &&
      subType?.winner_second_level &&
      secondLevel?.description
    ) {
      return [subType.winner_first_level.description, secondLevel.description];
    }
    return null;
  };

  const kpiData = useMemo(() => {
    if (!selectedLocationId || !filteredLocations.length) return null;

    const allTonnages = filteredLocations.reduce((acc: number[], curr) => {
      if (!("materials" in curr.type.sub)) return acc;
      return [
        ...acc,
        ...((curr.type.sub as CustomerSubType)?.materials?.map(
          m => m.tonnage || 0
        ) || [])
      ];
    }, []);

    return {
      totalTonnage: {
        title: "Summe (t)",
        value: new Intl.NumberFormat("de-DE").format(
          allTonnages.reduce((acc, curr) => acc + curr, 0)
        )
      },
      maxTonnage: {
        title: "max. (t)",
        value: allTonnages.length
          ? new Intl.NumberFormat("de-DE").format(Math.max(...allTonnages))
          : 0
      },
      minTonnage: {
        title: "min. (t)",
        value: allTonnages.length
          ? new Intl.NumberFormat("de-DE").format(Math.min(...allTonnages))
          : 0
      },
      totalItems: {
        title: "Stellen",
        value: filteredLocations.length
      },
      customers: {
        title: "Baustellen",
        value: filteredLocations.filter(
          l => l.type.main === Category.CUSTOMERS && l.domain === domain
        ).length
      },
      settling: {
        title: "Niederlassungen",
        value: filteredLocations.filter(
          l => l.type.main === Category.SETTLING && l.domain === domain
        ).length
      },
      competition: {
        title: "Konkurrenz",
        value: filteredLocations.filter(
          l => l.type.main === Category.COMPETITION && l.domain === domain
        ).length
      }
    };
  }, [selectedLocationId, filteredLocations, domain]);

  const exportRelatedCustomers = async (customers: Location[]) => {
    const customersToExport = customers.filter(
      c => c.type.main === Category.CUSTOMERS && c.domain === domain
    );

    const populatedCustomers: PopulatedCustomerLocation[] = await Promise.all(
      customersToExport.map(async customer => {
        return {
          ...customer,
          type: {
            main: Category.CUSTOMERS,
            sub: customer.type.sub
          }
        } as PopulatedCustomerLocation;
      })
    );

    const initialConfig = [
      [
        "Beschreibung",
        "Adresse",
        "Objekt-Nr.",
        "Submiss",
        "Bauzeit",
        "Tonnage",
        "Kategorie",
        "Ausschreibung",
        "Mischwerk",
        "Notiz"
      ]
    ];
    if (selectedLocationDateFilters) {
      initialConfig.unshift([
        `${selectedLocationDateFilters.from || "K/A"} - ${
          selectedLocationDateFilters.to || "K/A"
        }`
      ]);
    }
    exportCSV(
      populatedCustomers
        .filter(d => Object.keys(d).length > 2)
        .map(d => {
          const {
            construction_time,
            materials: customerMaterials,
            winner_first_level,
            winner_second_level,
            object_number,
            submiss_date,
            notes
          } = d.type.sub as PopulatedCustomerSubType;
          const { description, formatted_address } = d;
          const constructionTimeJoined =
            construction_time.from && construction_time.to
              ? construction_time.from.split("-").reverse().join(".") +
                " – " +
                construction_time.to.split("-").reverse().join(".")
              : "";
          const totalTonnage = customerMaterials.reduce(
            (acc: number, curr) =>
              !isNaN(+curr.tonnage) ? acc + curr.tonnage : acc,
            0
          );
          const categoriesJoined = customerMaterials
            .reduce(
              (acc: string[], curr) => [
                ...acc,
                materials.find(m => m.id === curr.category.id)?.name || ""
              ],
              []
            )
            .join(", ");
          return [
            withoutUmlauts(description.replaceAll(",", " ")),
            withoutUmlauts(formatted_address.replaceAll(",", " ")),
            object_number || "Keine Angabe",
            submiss_date || "Keine Angabe",
            constructionTimeJoined || "Keine Angabe",
            totalTonnage || "Keine Angabe",
            withoutUmlauts(categoriesJoined) || "Keine Angabe",
            withoutUmlauts(
              winner_first_level?.description.replaceAll(",", " ") || ""
            ) || "Keine Angabe",
            withoutUmlauts(
              winner_second_level?.description.replaceAll(",", " ") || ""
            ) || "Keine Angabe",
            notes || "Keine Angabe"
          ];
        }),
      initialConfig,
      `Kemna_Baustellen_${new Date().toISOString()}.csv`
    );
  };

  const onMarkerClicked = useCallback((l: Location) => {
    setCustomerRelevantData(null);
    sertClickedMarkerData(prev => (prev?.id === l.id ? null : l));
  }, []);

  const withCircles = !selectedLocationId;

  const points = useMemo(() => {
    return filteredLocations.map(location => ({
      type: "Feature",
      properties: { cluster: false, location },
      geometry: {
        type: "Point",
        coordinates: [location.location.lng, location.location.lat]
      }
    }));
  }, [filteredLocations]);
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: zoom,
    options: {
      radius: 50, // The radius of each cluster, in pixels
      maxZoom: 16, // The maximum zoom level at which clusters are generated
      minZoom: 1, // The minimum zoom level at which clusters are generated
      minPoints: 3
    }
  });

  const getOffsetBasedOnZoom = (zoom: number) => {
    return zoom / 10;
  };

  return (
    <StyledMapArea>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyAM8Ciwgzts5Dp9I8_DEYJN3oTWjAsz3rE"
        }}
        defaultCenter={center}
        zoom={zoom}
        options={{
          backgroundColor: "#eeeeee",
          zoomControl: false,
          fullscreenControl: false
        }}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => {
          handleApiReady(maps, map, true);
        }}
        onChange={({ zoom, bounds }) => {
          setMapZoom(zoom);

          const offset = getOffsetBasedOnZoom(zoom);
          setBounds([
            bounds.sw.lng - offset,
            bounds.sw.lat - offset,
            bounds.ne.lng + offset,
            bounds.ne.lat + offset
          ]);
        }}
        onClick={e =>
          searchedLocation
            ? setSearchedLocation({
                ...searchedLocation,
                location: { lat: e.lat, lng: e.lng }
              })
            : editLocation
            ? setEditLocationForMap({
                ...editLocation,
                location: { lat: e.lat, lng: e.lng }
              })
            : null
        }
      >
        {!editLocation &&
          !searchedLocation &&
          clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, location: l } = cluster.properties;
            const pointCount = cluster.properties.point_count;
            if (isCluster) {
              return (
                <MarkerWrapper
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${20 + (pointCount / points.length) * 100}px`,
                      height: `${20 + (pointCount / points.length) * 100}px`
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      map.setZoom(expansionZoom);
                      map.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </MarkerWrapper>
              );
            }
            return (
              <Marker
                key={l.id}
                lat={l.location.lat}
                lng={l.location.lng}
                markerMode={activeMarkerMode}
                hiddenMaterials={hiddenMaterials}
                item={l}
                maps={maps}
                map={map}
                settlingRadius={settlingRadius}
                badgeData={getBadgeData(l)}
                withCircles={withCircles}
                dehighlightMarker={
                  !!clickedMarkerData && clickedMarkerData.id !== l.id
                }
                onMarkerClicked={() => {
                  onMarkerClicked(l);
                }}
              />
            );
          })}
        {searchedLocation && (
          <Marker
            maps={maps}
            map={map}
            markerMode={activeMarkerMode}
            hiddenMaterials={hiddenMaterials}
            lat={searchedLocation.location.lat}
            lng={searchedLocation.location.lng}
            settlingRadius={settlingRadius}
          />
        )}
        {editLocation && (
          <Marker
            maps={maps}
            map={map}
            markerMode={activeMarkerMode}
            hiddenMaterials={hiddenMaterials}
            lat={editLocation.location.lat}
            lng={editLocation.location.lng}
            settlingRadius={settlingRadius}
          />
        )}
        {!!(
          selectedLocation &&
          selectedLocationRadius &&
          selectedLocationRadius2
        ) && (
          <Marker
            maps={maps}
            map={map}
            item={selectedLocation}
            markerMode={activeMarkerMode}
            hiddenMaterials={hiddenMaterials}
            lat={selectedLocation.location.lat}
            lng={selectedLocation.location.lng}
            settlingRadius={settlingRadius}
            selectedLocationRadius={selectedLocationRadius}
            selectedLocationRadius2={selectedLocationRadius2}
            onMarkerClicked={() => {
              if (clickedMarkerData?.id === selectedLocation.id) {
                sertClickedMarkerData(null);
              } else sertClickedMarkerData(selectedLocation);
            }}
          />
        )}
      </GoogleMapReact>
      {activeCategory === Category.CUSTOMERS && (
        <div
          className="position-absolute d-flex"
          style={{ top: 80, left: 10, zIndex: 3000 }}
        >
          {tableView === TableSize.FULL && (
            <>
              <button
                className="btn btn-secondary shadow-sm"
                style={{ height: 35, width: 35, padding: 0 }}
                onClick={() => setTableView(TableSize.SMALL)}
              >
                <FontAwesomeIcon icon={faAngleDoubleLeft} size="lg" />
              </button>
              <button
                className="btn btn-secondary ml-2 shadow-sm"
                style={{ height: 35, width: 35, padding: 0 }}
                onClick={() => setTableView(TableSize.REDUCED)}
              >
                <FontAwesomeIcon icon={faAngleLeft} size="lg" />
              </button>
            </>
          )}
          {tableView === TableSize.REDUCED && (
            <>
              <button
                className="btn btn-secondary shadow-sm"
                style={{ height: 35, width: 35, padding: 0 }}
                onClick={() => setTableView(TableSize.SMALL)}
              >
                <FontAwesomeIcon icon={faAngleLeft} size="lg" />
              </button>
              <button
                className="btn btn-secondary ml-2 shadow-sm"
                style={{ height: 35, width: 35, padding: 0 }}
                onClick={() => setTableView(TableSize.FULL)}
              >
                <FontAwesomeIcon icon={faAngleRight} size="lg" />
              </button>
            </>
          )}
          {tableView === TableSize.SMALL && (
            <>
              <button
                className="btn btn-secondary shadow-sm"
                style={{ height: 35, width: 35, padding: 0 }}
                onClick={() => setTableView(TableSize.REDUCED)}
              >
                <FontAwesomeIcon icon={faAngleRight} size="lg" />
              </button>
              <button
                className="btn btn-secondary ml-2 shadow-sm"
                style={{ height: 35, width: 35, padding: 0 }}
                onClick={() => setTableView(TableSize.FULL)}
              >
                <FontAwesomeIcon icon={faAngleDoubleRight} size="lg" />
              </button>
            </>
          )}
        </div>
      )}
      {!selectedLocation && (
        <ul
          className="list-group position-absolute"
          style={{ bottom: 320, left: 10, zIndex: 3000 }}
        >
          <input
            type="number"
            min={0}
            step={5}
            title="Umkreis Baustelle"
            className="list-group-item text-center p-0 mb-2"
            value={customerRadius}
            onChange={e => setCustomerRadius(+e.target.value)}
            style={{
              cursor: "pointer",
              height: 47,
              width: 47
            }}
          />
          <input
            type="number"
            min={0}
            step={5}
            title="Umkreis Mischanlagen"
            className="list-group-item text-center p-0"
            value={settlingRadius}
            onChange={e => setSettlingRadius(+e.target.value)}
            style={{
              cursor: "pointer",
              height: 47,
              width: 47
            }}
          />
        </ul>
      )}
      <ul
        className="list-group position-absolute"
        style={{ bottom: 150, left: 10, zIndex: 3000 }}
      >
        {switchButtons.map(({ title, text, category }, key) => (
          <li
            key={key}
            className={`list-group-item list-group-item-action${
              groupsToShow.includes(category) ? " active" : ""
            }`}
            onClick={() => {
              groupsToShow.includes(category)
                ? setGroupsToShow(groupsToShow.filter(g => g !== category))
                : setGroupsToShow([...groupsToShow, category]);
            }}
            style={{ cursor: "pointer", padding: "6px 12px" }}
            title={title}
          >
            {text}
          </li>
        ))}
      </ul>
      <ul
        className="list-group position-absolute"
        style={{ bottom: 65, left: 10, zIndex: 3000 }}
      >
        {markerModes.map(({ title, text, marker }, key) => (
          <li
            key={key}
            className={`list-group-item list-group-item-action${
              activeMarkerMode === marker ? " active" : ""
            }`}
            onClick={() => {
              setActiveMarkerMode(marker);
            }}
            style={{ cursor: "pointer", padding: "6px 12px" }}
            title={title}
          >
            {text}
          </li>
        ))}
      </ul>
      {showMapMaterialFilter && (
        <div
          className="position-absolute bg-white shadow rounded p-2"
          style={{
            bottom: 10,
            left: 80,
            width: 200,
            zIndex: 3000,
            overflowY: "auto"
          }}
        >
          {assignedMaterials.map((material, key) => (
            <div className="form-check mb-1" key={key}>
              <input
                className="form-check-input"
                type="checkbox"
                value={material.id}
                checked={!hiddenMaterials.includes(material.id)}
                onChange={e => {
                  if (e.target.checked) {
                    setHiddenMaterials(
                      hiddenMaterials.filter(m => m !== material.id)
                    );
                  } else {
                    setHiddenMaterials([...hiddenMaterials, material.id]);
                  }
                }}
                id="flexCheckDefault"
              />
              <label
                className="form-check-label position-relative w-100"
                htmlFor="flexCheckDefault"
              >
                {material.name}
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    height: "100%",
                    width: 2,
                    backgroundColor: material.color
                  }}
                ></div>
              </label>
            </div>
          ))}
        </div>
      )}
      <button
        className={`btn btn-${
          colorMode === "bw" ? "success" : "secondary"
        } position-absolute`}
        style={{ bottom: 10, left: 10, zIndex: 3000 }}
        onClick={() => setColorMode(colorMode === "bw" ? "rgb" : "bw")}
      >
        {colorMode === "rgb" ? "SW" : "RGB"}
      </button>
      {clickedMarkerData && (
        <div
          className="position-absolute rounded-lg shadow-lg bg-light text-dark pl-3 pr-3 py-2"
          style={{ top: 80, right: 10, width: 475 }}
        >
          <h4>
            {clickedMarkerData.description} -{" "}
            {getLocaleType(clickedMarkerData.type.main)}
          </h4>
          {customerRelevantData && (
            <>
              <p className="mt-3" style={{ whiteSpace: "nowrap" }}>
                Gewinner Ausschreibung:
                <span className="float-right">
                  {customerRelevantData.winner_first_level?.description ||
                    "Keine Angaben"}
                </span>
              </p>
              <p style={{ whiteSpace: "nowrap" }}>
                Tonnage:
                <span className="float-right">
                  {customerRelevantData.materials.reduce((acc, curr) => {
                    return acc + curr.tonnage;
                  }, 0)}{" "}
                  t
                </span>
              </p>
              <p style={{ whiteSpace: "nowrap" }}>
                Submission:
                <span className="float-right">
                  {getFormattedSubmissDate(customerRelevantData.submiss_date)}
                </span>
              </p>
              <p style={{ whiteSpace: "nowrap" }}>
                Bauzeit:
                <span className="float-right">
                  {getFormattedSubmissDate(
                    customerRelevantData.construction_time.from
                  )}{" "}
                  bis{" "}
                  {getFormattedSubmissDate(
                    customerRelevantData.construction_time.to
                  )}
                </span>
              </p>
            </>
          )}
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => sertClickedMarkerData(null)}
            style={{
              fontSize: "1.2rem",
              cursor: "pointer",
              position: "absolute",
              top: 10,
              right: 10
            }}
          />
        </div>
      )}
      {!!(selectedLocationId && kpiData) && (
        <div
          className="position-absolute rounded-lg shadow-lg bg-light text-dark px-3 py-2"
          style={{ bottom: 10, right: 10, width: 275 }}
        >
          <button
            className="btn btn-sm btn-primary p-0 shadow-lg position-absolute"
            style={{
              width: 38,
              aspectRatio: "1 / 1",
              bottom: "calc(100% + 10px)",
              right: 0
            }}
            title="Aktive Baustellen als CSV exportieren"
            onClick={() => {
              exportRelatedCustomers(filteredLocations);
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
          {!!(
            selectedLocationDateFilters &&
            selectedLocationRadius.toString.length
          ) && (
            <div
              style={{ fontSize: "0.85rem" }}
              className="d-flex border-bottom"
            >
              <div
                className="d-flex justify-content-between pr-1"
                style={{ flex: 1 }}
              >
                <div className="text-center" style={{ width: 77 }}>
                  {parseDateToGermanFormat(
                    selectedLocationDateFilters.from || ""
                  ) || "..."}
                </div>
                <div>-</div>
                <div className="text-center" style={{ width: 77 }}>
                  {parseDateToGermanFormat(
                    selectedLocationDateFilters.to || ""
                  ) || "..."}
                </div>
              </div>
              <div
                className="border-left pl-2"
                style={{ fontVariantNumeric: "tabular-nums" }}
              >
                {selectedLocationRadius} km
              </div>
            </div>
          )}
          <ul>
            {Object.values(kpiData).map(({ title, value }, idx) => {
              return (
                <li key={idx} className="d-flex">
                  <span style={{ flex: 1, marginRight: 20 }}>{title}:</span>
                  <span>{value}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </StyledMapArea>
  );
};

export default React.memo(MapArea);
